import React from 'react'

function LandingPage (props) {
  const error = props.match.url.includes('/error')
  const errorCode = props.match.params.code

  return (
    <div className='LandingPage'>
      <div className='container login-container'>
        <section className='section has-text-centered'>
          <h1 className='title is-3 welcome-title pb-6 is-size-4-mobile'>
            Tervetuloa Juha palveluun
          </h1>
          {error &&
            <div className='notification is-danger is-light'>Tapahtui virhe.</div>}
          {error && errorCode && parseInt(errorCode) === 403 &&
            <div className='notification is-danger is-light'>Sinulla ei ole käyttöoikeuksia tähän palveluun.</div>}
          <span className='user-icon icon'>
            <i className='far fa-user fa-3x' />
          </span>
          <div className='pt-6'>
            <a className='button is-juha-primary-button' href='/login'>
              <p className='title is-5 is-size-6-mobile'>Kirjaudu sisään</p>
            </a>
          </div>
        </section>
      </div>
    </div>
  )
}

export default LandingPage
