import React, { useEffect, useState } from 'react'
import FixedColumn from './FixedColumn'
import AttachmentFileItem from './AttachmentFileItem'
import Tree, { moveItemOnTree } from '@atlaskit/tree'
import { v4 } from 'uuid'
import { calcOrdinals, removeMarkedForDeletion } from '../utils/productUtils'

function AttachmentFilesList (props) {
  const {
    columnData,
    title,
    className,
    openModalForEdit,
    markForDelete,
    handleOpenModal,
    setProductAttachmentsForEdit
  } = props

  const [tree, setTree] = useState({ rootId: 'root', items: {} })

  useEffect(() => {
    if (columnData) {
      const treeObject = (item, id, rest) => {
        return {
          id,
          children: [],
          hasChildren: true,
          isExpanded: true,
          isChildrenLoading: false,
          data: item,
          ...rest
        }
      }

      setTree({
        ...tree,
        items: {
          ...tree.items,
          ...calcOrdinals(removeMarkedForDeletion(columnData))?.reduce((obj, item) => {
            const id = v4()
            obj[id] = treeObject(item, id)
            obj.root = { ...obj.root, children: [...obj.root.children, id] }
            return obj
          }, {
            root: treeObject({}, 'root')
          })
        }
      })
    }
  }, [columnData])

  const fileIsAdded = columnData?.filter(item => {
    return item.state === 'added'
  })

  const renderItem = ({ item, provided, depth }) => {
    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
      >
        {item.data.deleted === false && (
          <AttachmentFileItem
            dnd={provided.dragHandleProps}
            item={item.data}
            openModalForEdit={openModalForEdit}
            markForDelete={markForDelete}
            className='column is-full data-value'
          />
        )}
      </div>
    )
  }

  const onDragEnd = (source, destination) => {
    if (!destination) return
    const updatedTree = moveItemOnTree(tree, source, destination)
    columnData.splice(destination.index, 0, columnData.splice(source.index, 1)[0])
    const reOrderedColumnData = columnData.map((item, index) => {
      return {
        ...item,
        ordinal: index + 1
      }
    })
    setProductAttachmentsForEdit(reOrderedColumnData)
    setTree({ ...tree, ...updatedTree })
  }

  return (
    <FixedColumn title={title} columnClass={className}>

      <div className='column is-full data-value'>
        <Tree
          tree={tree}
          renderItem={renderItem}
          onDragEnd={onDragEnd}
          offsetPerLevel={17}
          isNestingEnabled={false}
          isDragEnabled
        />
      </div>

      {(fileIsAdded?.length === 0 || fileIsAdded === undefined) && (
        <div className='column is-full data-value'>
          <button
            onClick={handleOpenModal}
            className='title is-6 button is-juha-secondary-button'
          >
            <span className='icon'>
              <i className='fas fa-plus' />
            </span>
            <p>Lisää tiedosto</p>
          </button>
        </div>
      )}
    </FixedColumn>
  )
}
export default AttachmentFilesList
