import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

const getStateOptions = async () => {
  const resp = await axios.get(`${API_URL}/states`)
  return resp.data
}

const getArchiveStateOptions = async () => {
  const resp = await axios.get(`${API_URL}/archiveStates`)
  return resp.data
}

const getAvailabilityOptions = async () => {
  const resp = await axios.get(`${API_URL}/availabilities`)
  return resp.data
}

const getDevelopmentStateOptions = async () => {
  const resp = await axios.get(`${API_URL}/developmentStates`)
  return resp.data
}

const getVatBaseOptions = async () => {
  const resp = await axios.get(`${API_URL}/vatBases`)
  return resp.data
}

const getPricingTypeOptions = async () => {
  const resp = await axios.get(`${API_URL}/pricingTypes`)
  return resp.data
}

const getCardTypeOptions = async () => {
  const resp = await axios.get(`${API_URL}/cardTypes`)
  return resp.data
}

const getProductTypeOptions = async () => {
  const resp = await axios.get(`${API_URL}/productTypes`)
  return resp.data
}

const getPublishTypeOptions = async () => {
  const resp = await axios.get(`${API_URL}/publishTypes`)
  return resp.data
}

const getPackageOptions = async () => {
  const resp = await axios.get(`${API_URL}/packages`)
  return resp.data
}

const getContentTeamOptions = async () => {
  const resp = await axios.get(`${API_URL}/contentTeams`)
  return resp.data
}

const getEditorOptions = async () => {
  const resp = await axios.get(`${API_URL}/editors`)
  return resp.data
}

const getLanguageOptions = async () => {
  const resp = await axios.get(`${API_URL}/languages`)
  return resp.data
}

const getReferenceTypeOptions = async () => {
  const resp = await axios.get(`${API_URL}/referenceTypes`)
  return resp.data
}

const getLiteratureHeadingOptions = async () => {
  const resp = await axios.get(`${API_URL}/literatureHeadings`)
  return resp.data
}

const getClassOptions = async () => {
  const resp = await axios.get(`${API_URL}/classes`)
  return resp.data
}

const getIndexTermOptions = async () => {
  const resp = await axios.get(`${API_URL}/indexTerms`)
  return resp.data
}

const getTagOptions = async () => {
  const resp = await axios.get(`${API_URL}/tags`)
  return resp.data
}

const getIPRightOptions = async () => {
  const resp = await axios.get(`${API_URL}/ipRights`)
  return resp.data
}

const getStakeholderOptions = async () => {
  const resp = await axios.get(`${API_URL}/stakeholders`)
  return resp.data
}

export {
  getStateOptions,
  getArchiveStateOptions,
  getAvailabilityOptions,
  getDevelopmentStateOptions,
  getVatBaseOptions,
  getPricingTypeOptions,
  getCardTypeOptions,
  getProductTypeOptions,
  getPackageOptions,
  getContentTeamOptions,
  getEditorOptions,
  getLanguageOptions,
  getReferenceTypeOptions,
  getClassOptions,
  getIndexTermOptions,
  getTagOptions,
  getIPRightOptions,
  getStakeholderOptions,
  getPublishTypeOptions,
  getLiteratureHeadingOptions
}
