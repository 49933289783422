import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

const getHeadingClasses = async () => {
  const resp = await axios.get(`${API_URL}/headingclass`)
  return resp.data
}

const getHeadingClass = (id) => {
  return axios.get(`${API_URL}/headingclass/${id}`)
}

export {
  getHeadingClasses,
  getHeadingClass
}
