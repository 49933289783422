import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '../utils/searchUtils'

function SearchInputBox (props) {
  const { initialValue } = props
  const [searchInput, setSearchInput] = useState(initialValue || '')
  const history = useHistory()
  const query = useQuery()

  const onInputKeyUp = (e) => {
    if (e.key === 'Enter') {
      applySearch()
    }
  }

  const applySearch = () => {
    if (searchInput) {
      query.set('query', searchInput)
      history.push('/search?' + decodeURIComponent(query.toString()))
    } else {
      query.delete('query')
      history.push('/search?' + decodeURIComponent(query.toString()))
    }
  }

  return (
    <div className='SearchInputBox'>
      <div className='field has-addons'>
        <div className='control input-control'>
          <input
            value={searchInput}
            onInput={e => setSearchInput(e.target.value)}
            className='input'
            type='text'
            title='Hae tuotteen nimellä, id:llä tai muulla sanalla'
            placeholder='Hae tuotteen nimellä, id:llä tai muulla sanalla'
            onKeyUp={onInputKeyUp}
          />
        </div>
        <div className='control'>
          <button className='button is-juha-primary-button' onClick={applySearch}>
            <p>Hae</p>
          </button>
        </div>
      </div>
    </div>
  )
}

export default SearchInputBox
