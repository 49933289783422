import React from 'react'
import BasicDataColumn from './BasicDataColumn'
import { inputMaxLength } from '../utils/formUtils'
import { EditSubSection } from './EditSubSection'

function PublicationEditFields (props) {
  const { register, errors } = props

  return (
    <EditSubSection title='Luokittelu'>
      <BasicDataColumn label='ISSN' error={errors.ISSN}>
        <input
          className='input'
          {...register('issn',
            {
              maxLength: inputMaxLength(15)
            })}
        />
      </BasicDataColumn>
      <BasicDataColumn label='Tekijät' error={errors.authors}>
        <input
          className='input'
          {...register('authors',
            {
              maxLength: inputMaxLength(160)
            })}
        />
      </BasicDataColumn>
      <BasicDataColumn label='Julkaisija' error={errors.publisher}>
        <input
          className='input'
          {...register('publisher',
            {
              maxLength: inputMaxLength(255)
            })}
        />
      </BasicDataColumn>
      <BasicDataColumn label='Kustantaja' error={errors.publishingCompany}>
        <input
          className='input'
          {...register('productNameEN',
            {
              maxLength: inputMaxLength(255)
            })}
        />
      </BasicDataColumn>
      <BasicDataColumn label='Tarvikenimet' error={errors.buildingProductNames}>
        <input
          className='input'
          {...register('buildingProductNames',
            {
              maxLength: inputMaxLength(255)
            })}
        />
      </BasicDataColumn>
      <BasicDataColumn label='Valmistajat' error={errors.buildingProductManufacturers}>
        <input
          className='input'
          {...register('buildingProductManufacturers',
            {
              maxLength: inputMaxLength(255)
            })}
        />
      </BasicDataColumn>
      <BasicDataColumn label='Julkaisuvuosi' error={errors.publishingYear}>
        <input
          type='number'
          className='input'
          min='0'
          {...register('publishingYear',
            {
              maxLength: inputMaxLength(4)
            })}
        />
      </BasicDataColumn>
      <BasicDataColumn label='Aakkosnimi' error={errors.sortingName}>
        <input
          className='input'
          {...register('sortingName',
            {
              maxLength: inputMaxLength(250)
            })}
        />
      </BasicDataColumn>
      <BasicDataColumn label='Lisätietoja' error={errors.notes}>
        <input
          className='input'
          {...register('notes',
            {
              maxLength: inputMaxLength(50)
            })}
        />
      </BasicDataColumn>
      <BasicDataColumn label='Painos' error={errors.edition}>
        <input
          className='input'
          {...register('edition',
            {
              maxLength: inputMaxLength(50)
            })}
        />
      </BasicDataColumn>
      <BasicDataColumn label='Ratin oma julkaisu' error={errors.rakennustietoPublication}>
        <input
          className='input'
          {...register('rakennustietoPublication')}
        />
      </BasicDataColumn>
      <BasicDataColumn label='Sarja' error={errors.series}>
        <input
          className='input'
          {...register('series',
            {
              maxLength: inputMaxLength(60)
            })}
        />
      </BasicDataColumn>
    </EditSubSection>
  )
}

export default PublicationEditFields
