import React from 'react'
import FixedColumn from './FixedColumn'
import { Link } from 'react-router-dom'
import chroma from 'chroma-js'

function FixedColumnWithGroupedItems (props) {
  const { title, columnClass, columnData, handleFocus, useCustomColors, isProductLiterature } = props

  const getCustomColorStyles = (parentElement) => {
    if (useCustomColors && parentElement.color && chroma.valid(parentElement.color)) {
      return { color: chroma(parentElement.color).css() }
    }
  }

  const formulateUrl = (elem) => {
    if (!isProductLiterature) {
      if (elem.url.startsWith('/api/files')) {
        return (<a className={elem.valueClass} href={elem.url}>{elem.name}</a>)
      } else {
        return (<Link className={elem.valueClass} to={elem.url}>{elem.name}</Link>)
      }
    } else {
      if (elem.url.startsWith('kortti:')) {
        return (<a href={elem.juhaUrl} className={elem.valueClass}>{elem.name}</a>)
      } else {
        return (<a className={elem.valueClass} href={elem.url}>{elem.name}</a>)
      }
    }
  }

  return (
    <FixedColumn title={title} columnClass={columnClass}>
      {columnData?.map((el, index) =>
        <div key={index} className='column is-full data-value'>
          <div className='label'>
            {el.itemLabel}
          </div>
          <div>
            {el.items?.map((elem, ind) =>
              <div key={ind} className='column is-full data-value' onClick={() => handleFocus && handleFocus(el, elem)}>
                {elem.url
                  ? formulateUrl(elem)
                  : <p className={elem.valueClass} style={getCustomColorStyles(el)}>{elem.name}</p>}
              </div>
            )}
          </div>
        </div>
      )}
    </FixedColumn>
  )
}

export default FixedColumnWithGroupedItems
