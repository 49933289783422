export const selectOptions = {
  categoryIdOptions: [
    { value: 'kortti', label: 'Kortti' },
    { value: 'julkaisu', label: 'Julkaisu' },
    { value: 'verkkopalvelu', label: 'Verkkopalvelu' },
    { value: 'lomake', label: 'Lomake' }
  ],
  serviceVisibilityOptions: [
    { value: 'Hidden', label: 'Hidden' },
    { value: 'Visible', label: 'Visible' }
  ]
}
