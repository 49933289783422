import React, { useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { formatSearchResult, useQuery, sortDateTime } from '../utils/searchUtils'
import { usePagination, useSortBy, useTable } from 'react-table'
import { convertToDisplayDate, convertToDisplayDateAndTime } from '../utils/formUtils'
import searchResultsLoadingData from '../utils/searchResultsLoadingData'
import { getVisibilityClass } from '../utils/styleUtils'
import { productDataModel } from '../utils/csvUtils'
import { displayVisibility } from '../utils/productUtils'

function SearchResults (props) {
  const { isLoading, searchResult, maxResults } = props
  const [showCsvModal, setShowCsvModal] = useState(false)
  const [downloadAllCsv, setDownloadAllCsv] = useState(false)
  const loadingData = searchResultsLoadingData

  const query = useQuery()
  const history = useHistory()
  const location = useLocation()

  const tableColumns = useMemo(
    () => [
      { Header: 'Tuote ID', accessor: 'productId' },
      { Header: 'Kortti/lomake-tunnus', accessor: 'cardReferenceCode' },
      { Header: 'Nimi', accessor: 'name' },
      {
        Header: 'Näkyvyys tietoväylässä',
        accessor: 'visibility',
        Cell: ({ cell: { value } }) => <>{displayVisibility(value)}</>
      },
      { Header: 'Korttityyppi', accessor: 'productTypes' },
      {
        Header: 'Julkaisupäivä',
        accessor: 'publicationDate',
        // Use custom function because default 'datetime' gives error on missing attribute, when search params are changed
        sortType: sortDateTime,
        Cell: ({ cell: { value } }) => <>{convertToDisplayDate(value)}</>
      },
      {
        Header: 'Peruutuspäivä',
        accessor: 'cancelDate',
        // Use custom function because default 'datetime' gives error on missing attribute, when search params are changed
        sortType: sortDateTime,
        Cell: ({ cell: { value } }) => <>{convertToDisplayDate(value)}</>
      }
    ],
    []
  )

  const allTableColumns = useMemo(
    () => [
      ...tableColumns,
      { Header: 'Tekijät', accessor: 'authors' },
      { Header: 'PDF', accessor: 'cardPdfFile' },
      { Header: 'Laji', accessor: 'categoryId' },
      { Header: 'Kumppani', accessor: 'contentPartner' },
      { Header: 'Sisältötiimit', accessor: 'contentTeams' },
      { Header: 'Kehitystila', accessor: 'developmentState' },
      { Header: 'Toimittajat', accessor: 'editors' },
      { Header: 'GTIN', accessor: 'gtin' },
      { Header: 'Kielet', accessor: 'languages' },
      { Header: 'Hinta (netto)', accessor: 'priceNet' },
      { Header: 'Hinta (sis. alv)', accessor: 'priceVAT' },
      { Header: 'Hintatyyppi', accessor: 'pricingType' },
      { Header: 'Sivumäärä', accessor: 'productPageCount' },
      { Header: 'Lyhytkuvaus', accessor: 'productShortDescription' },
      { Header: 'Ratin julkaisu', accessor: 'rakennustietoPublication' },
      { Header: 'Sarja', accessor: 'series' },
      { Header: 'Tila', accessor: 'state' },
      { Header: 'ALV%', accessor: 'vatPercent' }

    ],
    []
  )

  let tableData = useMemo(
    () => formatSearchResult(searchResult),
    [searchResult]
  )

  if (isLoading) {
    tableData = loadingData
  }

  const initialState = {
    pageIndex: Number(query.get('page')) || 0,
    pageSize: Number(query.get('pageSize')) || 10
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable({ columns: tableColumns, data: tableData, initialState: initialState }, useSortBy, usePagination)

  const csvAllData = productDataModel(rows.map(row => row.original))
  useEffect(() => {
    query.set('page', pageIndex)
    query.set('pageSize', pageSize)
    history.replace({
      pathname: location.pathname,
      search: '?' + decodeURIComponent(query.toString())
    })
  }, [pageIndex, pageSize])

  const csvPageData = productDataModel(page.map(row => row.original))

  const csvHeaders = tableColumns.map(el => {
    return { label: el.Header, key: el.accessor }
  })

  const csvAllHeaders = [
    ...allTableColumns.map(el => {
      return { label: el.Header, key: el.accessor }
    }),
    { label: 'Juha linkki', key: 'juhaLink' },
    { label: 'Kortistot linkki', key: 'kortistotLink' }
  ]

  const showResultLimit = () => {
    return searchResult?.data?.totalRows && searchResult?.data?.totalRows > maxResults
  }

  const handleDownloadAllCsv = (e) => {
    setDownloadAllCsv(e.target.checked)
  }

  return (
    <div className='SearchResults'>
      <div className={`container ${isLoading && 'is-loading'}`}>
        <section className='section'>
          <div className='columns is-mobile'>
            <div className='column'>
              <div className='columns'>
                <div className='column is-narrow pr-4'>
                  <p className='title is-4 is-size-5-mobile'>
                    Hakutulokset
                  </p>
                </div>
                {!isLoading &&
                  <div className='column is-flex'>
                    <p className='title is-4 is-size-5-mobile'>
                      <span className='is-color-primary pr-2'>
                        {searchResult?.data?.totalRows}
                      </span>
                      {showResultLimit()
                        ? (
                          <span>
                            {', näytetään ' + maxResults + ' ensimmäistä'}
                          </span>
                          )
                        : ''}
                    </p>
                  </div>}
              </div>
            </div>
            <div className='column is-narrow'>
              <button
                className='button is-juha-secondary-button'
                onClick={() => setShowCsvModal(true)}
              >
                <p>Lataa CSV</p>
                <span className='icon'>
                  <i className='fas fa-download' />
                </span>
              </button>
            </div>
          </div>
        </section>
        <section className='section pt-0'>
          <div className='table-container'>
            <table className='table is-fullwidth is-striped mb-0' {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())} key={index}
                      >
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                                ? <span className='icon'><i className='fas fa-sort-down' /></span>
                                : <span className='icon'><i className='fas fa-sort-up' /></span>
                            : <span className='icon'><i className='fas fa-sort' /></span>}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row)

                  const createLink = (idRow, cell) => (
                    <Link
                      to={{ pathname: '/product/' + idRow.value, state: { from: '/search' } }}
                      className='is-color-primary'
                    >{cell.render('Cell')}
                    </Link>)
                  return (
                    <tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, index) => {
                        const idRow = cell.row.cells.find(el => el.column.id === 'productId')
                        return (
                          <td
                            key={index}
                            {...cell.getCellProps([{ className: getVisibilityClass(cell.value) }])}
                          >
                            {cell.column.id === 'productId'
                              ? createLink(idRow, cell)
                              : cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className='pagination'>
              <button
                className='button is-juha-secondary-button' onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {'<<'}
              </button>
              <button
                className='button is-juha-secondary-button' onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {'<'}
              </button>
              <span>
                Sivu{' '}
                <strong>{pageIndex + 1} / {pageOptions.length}</strong>
              </span>
              <button className='button is-juha-secondary-button' onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
              </button>
              <button
                className='button is-juha-secondary-button' onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {'>>'}
              </button>
              <span className='is-flex is-align-items-center'>
                Mene sivulle:{' '}
                <input
                  className='input'
                  type='number'
                  defaultValue={pageIndex + 1}
                  onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(page)
                  }}
                />
              </span>
              <select
                className='select'
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Näytä {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </section>
      </div>
      <div className={showCsvModal ? 'modal is-active' : 'modal'}>
        <div className='modal-background' onClick={() => setShowCsvModal(false)} />
        <div className='modal-card'>
          <header className='modal-card-head'>
            <p className='modal-card-title'>Lataa CSV</p>
            <button className='delete' aria-label='close' onClick={() => setShowCsvModal(false)} />
          </header>
          <section className='modal-card-body'>
            <div className='columns is-multiline'>
              <div className='column is-half'>
                <CSVLink
                  className='button is-juha-secondary-button'
                  data={csvAllData}
                  headers={downloadAllCsv ? csvAllHeaders : csvHeaders}
                  filename={`Juha-hakutulokset ${convertToDisplayDateAndTime(new Date())}.csv`}
                  separator=';'
                  onClick={() => setShowCsvModal(false)}
                >
                  <p>Kaikki hakutulokset</p>
                  <span className='icon'>
                    <i className='fas fa-download' />
                  </span>
                </CSVLink>
              </div>
              <div className='column is-half'>
                <CSVLink
                  className='button is-juha-secondary-button'
                  data={csvPageData}
                  headers={downloadAllCsv ? csvAllHeaders : csvHeaders}
                  filename={`Juha-hakutulokset ${convertToDisplayDateAndTime(new Date())}.csv`}
                  separator=';'
                  onClick={() => setShowCsvModal(false)}
                >
                  <p>Tämä hakutulos sivu</p>
                  <span className='icon'>
                    <i className='fas fa-download' />
                  </span>
                </CSVLink>
              </div>
              <div className='column is-full'>
                <label className='checkbox-container'>Kaikki data
                  <input type='checkbox' checked={downloadAllCsv} onChange={handleDownloadAllCsv} />
                  <span className='checkmark' />
                </label>
              </div>
            </div>
          </section>
          <footer className='modal-card-foot'>
            <button className='button is-juha-secondary-button' onClick={() => setShowCsvModal(false)}><p>Peruuta</p>
            </button>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default SearchResults
