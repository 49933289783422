import React from 'react'
import PredefinedSearchButton from '../components/PredefinedSearchButton'
/*
juha-status and recently viewed --- Not yet in use
import StatusCard from '../components/StatusCard'
import RecentlyViewedProducts from '../components/RecentlyViewedProducts'
*/

function FrontPage () {
  const predefinedSearches = [
    { text: 'RT-kortisto voimassa', destination: '/search?visibility=visible&packageName=1' },
    { text: 'KH-kortisto voimassa', destination: '/search?visibility=visible&packageName=8' },
    { text: 'LVI-kortisto voimassa', destination: '/search?visibility=visible&packageName=2' },
    { text: 'Ratu-kortisto voimassa', destination: '/search?visibility=visible&packageName=9' },
    { text: 'RT-kortisto valmisteilla', destination: '/search?stateId=1&packageName=1' },
    { text: 'KH-kortisto valmisteilla', destination: '/search?stateId=1&packageName=8' },
    { text: 'LVI-kortisto valmisteilla', destination: '/search?stateId=1&packageName=2' },
    { text: 'Ratu-kortisto valmisteilla', destination: '/search?stateId=1&packageName=9' }
  ]

  return (
    <div className='FrontPage'>
      <div className='container'>
        <div className='columns'>
          <div className='column is-two-thirds'>
            <section className='hero'>
              <div className='hero-body is-custom-medium-section'>
                <h1 className='title pb-5 is-size-4-mobile'>
                  Julkaisunhallintaohjelma
                </h1>
                <p className='subtitle is-6'>
                  Juha on Rakennustiedon julkaisutietokanta. Juhasta löytyvät tiedot kaikista Rakennustiedon julkaisemista
                  kortistoista, korteista, kirjoista, sähköisistä julkaisuista, lomakkeista ja verkkokaupassa myytävistä
                  julkaisuista.
                </p>
              </div>
            </section>
          </div>
          {/* Tietoväylä haku -- Disabled because unnecessary and confusing */}
          {/* <div className='column'> */}
          {/*  <section className='section is-custom-medium-section'> */}
          {/*    <SearchInputBox /> */}
          {/*  </section> */}
          {/* </div> */}
        </div>
      </div>
      <div className='has-background-juha-blue-5'>
        <div className='container'>
          <div className='columns'>
            <div className='column'>
              <section className='section'>
                <h2 className='title pb-2 is-4 is-size-5-mobile'>
                  Valmishaut
                </h2>
                <div className='columns is-multiline'>
                  {predefinedSearches.map((el, index) =>
                    <div key={index} className='column is-one-third'>
                      <PredefinedSearchButton text={el.text} destination={el.destination} />
                    </div>
                  )}
                </div>
              </section>
              {/* recently viewed --- Not yet in use
              <section className='section'>
                <RecentlyViewedProducts />
              </section>
              */}
            </div>
            {/* juha-status --- Not yet in use
              <div className='column'>
              <section className='section'>
                <StatusCard />
              </section>
            </div>
            */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FrontPage
