import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { getProductStrapi } from '../services/productService'
import OpenCMSButton from '../components/OpenCMSButton'
import { getPublicationState } from '../services/publicationService'

function ProductEditHeader (props) {
  const { saveProduct, isSavingProduct, productData, productId } = props
  const history = useHistory()
  const location = useLocation()

  const [productStrapi, setProductStrapi] = useState(undefined)
  useEffect(() => {
    getProductStrapi(productId).then(data => {
      setProductStrapi(data)
    })
  }, [productId])

  const [publicationState, setPublicationState] = useState(false)
  useEffect(() => {
    getPublicationState(productId).then(resp => {
      const published = resp.channels?.find(el => el.channel === 'Tietoväylä')
      published && setPublicationState(published)
    })
  }, [productId])

  const withPreviewFlag = (str) => {
    const url = new URL(str)
    url.searchParams.append('preview', 'true')
    return url.toString()
  }

  return (
    <div className='ProductHeader'>
      <nav className='custom-navbar navbar' role='navigation' aria-label='product navigation'>
        <div className='container'>
          <div className='navbar-start is-flex is-justify-content-flex-start'>
            <div className='navbar-item is-marginless'>
              <strong>{productData?.productName}</strong>
            </div>
            <div className='navbar-item is-marginless'>
              <strong>{productData?.productId || 'Uusi tuote'}</strong>
            </div>
            <div className='navbar-item is-marginless'>
              <strong>{productData?.categoryId}</strong>
            </div>
            <div className='navbar-item is-marginless'>
              <strong>{productData?.cardReferenceCode}</strong>
            </div>
          </div>
          <div className='navbar-end is-flex is-justify-content-flex-end' id='productNavMenu'>
            {productData?.cardsPortalURL && (
              <div className='navbar-item is-marginless'>
                <a
                  href={(publicationState && !(productData?.publishType !== 'Rakenteinen' && productStrapi?.id)) ? productData?.cardsPortalURL : withPreviewFlag(productData?.cardsPortalURL)}
                  className='button is-small is-juha-secondary-button'
                  target='_blank' rel='noreferrer'
                >{(publicationState && !(productData?.publishType !== 'Rakenteinen' && productStrapi?.id)) ? 'Avaa kortistossa' : 'Esikatsele kortistossa'}
                </a>
              </div>
            )}
            <OpenCMSButton
              productData={productData}
              productStrapi={productStrapi}
              setProductStrapi={setProductStrapi}
              className='navbar-item is-marginless'
            />
            {productData?.webstoreURL && (
              <div className='navbar-item is-marginless'>
                <a
                  href={productData?.webstoreURL}
                  className='button is-small is-juha-secondary-button'
                  target='_blank' rel='noreferrer'
                >
                  Avaa verkkokaupassa
                </a>
              </div>
            )}
            <div className='navbar-item is-marginless'>
              <button
                className={`title is-6 button is-juha-secondary-button ${isSavingProduct && 'is-loading'}`}
                onClick={() => {
                  let destination
                  if (location.pathname === '/product/new') {
                    destination = '/dashboard'
                  } else if (location.pathname.indexOf('clone') !== -1) {
                    destination = location.pathname.replace('/clone', '')
                  } else {
                    destination = location.pathname.replace('/edit', '')
                  }
                  history.push(destination)
                }}
              >
                <p>
                  Peruuta
                </p>
              </button>
            </div>
            <div className='navbar-item is-marginless pr-4'>
              <button
                className={`title is-6 button is-juha-primary-button ${isSavingProduct && 'is-loading'}`}
                onClick={() => saveProduct()}
              >
                <span className='icon'>
                  <i className='far fa-save' />
                </span>
                <p>
                  Tallenna
                </p>
              </button>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default ProductEditHeader
