import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

const getEditors = async () => {
  const resp = await axios.get(`${API_URL}/editor`)
  return resp.data
}

const editEditor = async (id, data) => {
  return await axios.put(`${API_URL}/editor/${id}`, data)
}

const deleteEditor = async (id) => {
  return await axios.delete(`${API_URL}/editor/${id}`)
}

const newEditor = async (data) => {
  return await axios.post(`${API_URL}/editor`, data)
}

export {
  getEditors,
  editEditor,
  deleteEditor,
  newEditor
}
