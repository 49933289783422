import React, { useEffect, useState } from 'react'
import SearchResults from '../components/SearchResults'
import SearchInputBox from '../components/SearchInputBox'
import SearchFilters from '../components/SearchFilters'
import { useQuery } from '../utils/searchUtils'
import { searchProducts } from '../services/productService'
import { NotificationManager } from 'react-notifications'
import { useHistory, useLocation } from 'react-router-dom'

function SearchPage (props) {
  const query = useQuery()

  // Delete these so they dont trigger search again
  query.delete('page')
  query.delete('pageSize')

  const keyword = query.get('query')
  const searchParams = decodeURIComponent(query.toString())

  const [isLoading, setLoading] = useState(false)
  const [searchResult, setSearchResult] = useState([])
  const [maxResults, setMaxResults] = useState(2000)

  const [selectedTab, setSelectedTab] = useState('tarkka')

  const location = useLocation()
  const history = useHistory()

  const removeFilters = () => {
    if (query.get('query')) {
      history.push(location.pathname + '?query=' + query.get('query').replaceAll(' ', '+'))
    } else {
      history.push(location.pathname)
    }
  }

  const tietovaylaSearch = () => (
    <section className='is-custom-medium-section section pt-0 pb-0'>
      <div className='column is-half is-paddingless'>
        <SearchInputBox initialValue={keyword} />
      </div>
      <div className='wide-separator pb-3'>
        <div className='line' />
      </div>
    </section>
  )

  useEffect(() => {
    searchParams && setLoading(true)
    const timer = () => setTimeout(() => {
      if (searchParams) {
        const queryParams = new URLSearchParams(props.location.search)
        searchProducts(queryParams)
          .then(response => {
            if (!response.error) {
              setMaxResults(queryParams.get('maxResults') ? queryParams.get('maxResults') : 2000)
              response.data.result = response.data.result.map(searchResult => {
                return {
                  ...searchResult,
                  publicationDate: new Date(searchResult.publicationDate),
                  cancelDate: new Date(searchResult.cancelDate)
                }
              })
              setSearchResult(response)
              setLoading(false)
            } else {
              NotificationManager.warning('Virhe', 'Haku epäonnistui', 5000)
            }
          })
      }
    }, 1000)
    const timerId = timer()
    return () => {
      clearTimeout(timerId)
    }
  }, [searchParams])
  return (
    <div className='SearchPage'>
      <div className='container'>
        <div className='level mt-3'>
          <div className='level-left tabs'>
            <ul>
              <li className={selectedTab === 'tarkka' && 'is-active'}>
                <div className='title is-4'><a onClick={() => setSelectedTab('tarkka')}>Tarkka haku</a></div>
              </li>
              <li className={selectedTab === 'tietovayla' && 'is-active'}>
                <div className='title is-4'><a onClick={() => setSelectedTab('tietovayla')}>Haku Tietoväylästä</a></div>
              </li>
            </ul>
          </div>
          <div className='level-right'>
            <div className='level-item'>
              <div className='field has-addons'>
                <button className='button is-juha-warning-button' onClick={removeFilters}>
                  <p>Tyhjennä rajausvalinnat</p>
                  <span className='icon'><i className='far fa-trash-alt' /></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        {selectedTab === 'tarkka' && <SearchFilters />}
        {selectedTab === 'tietovayla' && tietovaylaSearch()}
      </div>
      {searchParams &&
        <SearchResults isLoading={isLoading} searchResult={searchResult} maxResults={maxResults} />}
    </div>
  )
}

export default SearchPage
