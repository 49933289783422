import React from 'react'
import { getColumnClass } from '../utils/styleUtils'

function DataBoxFixedColumns (props) {
  const { title, notificationText, columns, compactView, isWrapped } = props
  const columnClass = getColumnClass(columns)

  return (
    <div id='DataBoxFixedColumns'>
      <div className='card m-4 mb-6'>
        <header className='card-header p-1 has-background-juha-blue-20 has-no-border'>
          <div className='card-header-title'>
            <p className='title is-5 is-marginless'>
              {title}
            </p>
            {notificationText &&
              <div className='custom-notification is-flex pl-6 is-align-items-center'>
                <span className='icon is-color-dark-grey pr-3'>
                  <i className='fas fa-info-circle' />
                </span>
                <p className='is-color-dark-grey'>
                  {notificationText}
                </p>
              </div>}
          </div>
        </header>
        <div className='card-content is-paddingless'>
          <div className='columns data-columns is-marginless is-multiline'>
            {React.Children.map(props.children, child =>
              React.cloneElement(child, { columnClass: `${compactView && 'compact'} ${columnClass}`, isWrapped })
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DataBoxFixedColumns
