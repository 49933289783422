import React, { useRef, useState, useEffect } from 'react'
import ProductEditHeader from '../components/ProductEditHeader'
import EditProductForm from '../components/EditProductForm'
import { Prompt, useHistory } from 'react-router-dom'
import {
  getProductAttachmentsForEdit,
  getProductClasses,
  getProductDataForEditPage,
  getProductEditors,
  getProductHeadings,
  getProductIndexTerms,
  getProductLanguages,
  getProductLinks,
  getProductPackages,
  getProductReferences,
  getProductReplacements,
  getProductTags,
  getProductTeams,
  getProductIPRights,
  getInternalProductAttachmentsForEdit, getProductLiterature
} from '../services/productService'
import { mapIPRights, mapReferences, mapReplacements } from '../utils/productUtils'
import { NotificationManager } from 'react-notifications'

function ProductEditPage (props) {
  const { isClone } = props
  const { productId } = props.match.params
  const history = useHistory()
  const [isEdited, setIsEdited] = useState(false)
  const [productData, setProductData] = useState(undefined)
  const [productReplacingData, setProductReplacingData] = useState([])
  const [productReferences, setProductReferences] = useState([])
  const [productLinks, setProductLinks] = useState([])
  const [productPackages, setProductPackages] = useState([])
  const [productHeadings, setProductHeadings] = useState([])
  const [productClasses, setProductClasses] = useState([])
  const [productContentTeams, setProductContentTeams] = useState([])
  const [productEditors, setProductEditors] = useState([])
  const [productIndexTerms, setProductIndexTerms] = useState([])
  const [productLanguages, setProductLanguages] = useState([])
  const [productTags, setProductTags] = useState([])
  const [productIPRights, setProductIPRights] = useState([])
  const formRef = useRef(null)
  const [isSavingProduct, setIsSavingProduct] = useState(false)
  const [productAttachmentsForEdit, setProductAttachmentsForEdit] = useState([])
  const [internalProductAttachmentsForEdit, setInternalProductAttachmentsForEdit] = useState([])
  const [mappedReferences, setMappedReferences] = useState([])
  const [mappedIPRights, setMappedIPRights] = useState([])
  const [mappedReplacements, setMappedReplacements] = useState([])
  const [productLiterature, setProductLiterature] = useState([])

  const saveProduct = () => {
    formRef.current.dispatchEvent(new window.Event('submit', { cancelable: true, bubbles: true }))
  }

  const handleFlatMap = (hierarchicalArray) => {
    return hierarchicalArray.flatMap((b) => b.items?.map(c => c.sortingCode))
  }

  useEffect(() => {
    if (productId) {
      getProductDataForEditPage(productId).then(data => {
        if (!data.error) {
          if (isClone) {
            const { productId, createdTime, createdUser, updateTime, updateUser, ...cloneData } = data
            data = cloneData
          }
          setProductData({ ...data, priceVat: data.priceVAT })
          getProductReplacements(productId, true).then((resp) => {
            resp.replacingProducts = resp.replacingProducts?.map(item => {
              return {
                value: item.productId,
                label: `${item.cardReferenceCode} - ${item.productName}`
              }
            })
            resp.replacedProducts = resp.replacedProducts?.map(item => {
              return {
                value: item.productId,
                label: `${item.cardReferenceCode} - ${item.productName}`
              }
            })
            setProductReplacingData(resp)
            setMappedReplacements(mapReplacements(resp))
          })
          getProductReferences(productId, true).then((resp) => {
            const references = resp.map(referenceType => {
              return {
                ...referenceType,
                productReferences: referenceType.productReferences.map(reference => {
                  return {
                    value: reference.productId,
                    label: reference.cardReferenceCode + ' - ' + reference.productName
                  }
                })
              }
            })
            setProductReferences(references)
            setMappedReferences(mapReferences(references))
          })

          getProductIPRights(productId, true).then((resp) => {
            const ipRights = resp.map(ipRight => {
              return {
                ...ipRight,
                stakeholders: ipRight.stakeholders.map(stakeholder => {
                  return {
                    value: stakeholder.stakeholderId,
                    label: stakeholder.stakeholder
                  }
                })
              }
            })
            setProductIPRights(ipRights)
            setMappedIPRights(mapIPRights(ipRights))
          })
          getProductLiterature(productId).then(resp => setProductLiterature([...resp]))
          getProductLinks(productId, true).then(setProductLinks)
          getProductPackages(productId).then((resp) => setProductPackages(handleFlatMap(resp)))
          getProductHeadings(productId).then(setProductHeadings)
          getProductClasses(productId).then((resp) => setProductClasses(handleFlatMap(resp)))
          getProductTags(productId).then((resp) => setProductTags(handleFlatMap(resp)))
          getProductTeams(productId).then((resp) => {
            setProductContentTeams(resp.map((contentTeam) => contentTeam.sortingCode))
          })
          getProductEditors(productId).then((resp) => {
            setProductEditors(resp.map((editor) => editor.sortingCode))
          })
          getProductIndexTerms(productId, true).then(setProductIndexTerms)
          getProductLanguages(productId).then((resp) => {
            setProductLanguages(resp.map((language) => language.sortingCode))
          })
          getProductAttachmentsForEdit(productId).then(setProductAttachmentsForEdit)
          getInternalProductAttachmentsForEdit(productId).then(setInternalProductAttachmentsForEdit)
        } else if (data.error === 404) {
          NotificationManager.error('Virhe', 'Tuotetta ei löytynyt', 5000)
          history.push('/dashboard')
        } else {
          NotificationManager.error('Virhe', 'Tapahtui odottamaton virhe', 5000)
          history.push('/dashboard')
        }
      })
    }
  }, [productId, history])

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [isEdited, isSavingProduct])

  const alertUser = e => {
    if (isEdited && !isSavingProduct) {
      e.preventDefault()
      e.returnValue = ''
      return e
    } else {
      return null
    }
  }

  const combinedProductData = {
    ...productData,
    productReferences: mappedReferences,
    productReplacements: mappedReplacements,
    productLiterature: productLiterature,
    productIPRights: mappedIPRights,
    replacements: productReplacingData,
    references: productReferences,
    ipRights: productIPRights,
    links: productLinks,
    packages: productPackages,
    productHeadings: productHeadings,
    classes: productClasses,
    tags: productTags,
    contentTeams: productContentTeams,
    editors: productEditors,
    indexTerms: productIndexTerms,
    languages: productLanguages,
    productAttachments: productAttachmentsForEdit,
    internalAttachments: internalProductAttachmentsForEdit
  }

  return (
    <>
      <Prompt
        when={isEdited && !isSavingProduct}
        message={() => 'Haluatko varmasti poistua sivulta tallentamatta muutoksia?'}
      />
      <div className='ProductEditPage'>
        <ProductEditHeader
          saveProduct={saveProduct}
          isSavingProduct={isSavingProduct}
          productData={productData}
          productId={productId}
        />
        <div className='container pb-1'>
          {productData &&
            <EditProductForm
              productData={combinedProductData}
              formRef={formRef}
              isSavingProduct={isSavingProduct}
              setIsSavingProduct={setIsSavingProduct}
              setIsEdited={setIsEdited}
              setProductAttachmentsForEdit={setProductAttachmentsForEdit}
              setInternalProductAttachmentsForEdit={setInternalProductAttachmentsForEdit}
            />}
          {!productId &&
            <EditProductForm
              formRef={formRef}
              isSavingProduct={isSavingProduct}
              setIsSavingProduct={setIsSavingProduct}
              setIsEdited={setIsEdited}
              setProductAttachmentsForEdit={setProductAttachmentsForEdit}
              setInternalProductAttachmentsForEdit={setInternalProductAttachmentsForEdit}
            />}
        </div>
      </div>
    </>
  )
}

export default ProductEditPage
