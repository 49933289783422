import React, { useEffect, useState } from 'react'
import BasicDataColumn from './BasicDataColumn'
import { inputMaxLength } from '../utils/formUtils'
import { getCardTypeOptions } from '../services/selectOptionsService'
import SingleSelectColumn from './SingleSelectColumn'
import { EditSubSection } from './EditSubSection'
import { defaultCardReferenceCode } from '../utils/productUtils'

function CardOrFormEditFields (props) {
  const { register, errors, control, getValues, watch } = props

  const [cardTypeOptions, setCardTypeOptions] = useState([])
  const [cardReferenceCodePlaceholder, setCardReferencePlaceholder] = useState('')

  useEffect(() => {
    getCardTypeOptions().then(setCardTypeOptions)
  }, [])

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'cardCollection' || name === 'cardNumber') {
        setCardReferencePlaceholder(defaultCardReferenceCode({ cardCollection: getValues('cardCollection'), cardNumber: getValues('cardNumber') }))
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <>
      <EditSubSection title='Luokittelu'>
        <div className='columns is-multiline is-marginless data-columns'>
          <BasicDataColumn label='Kortisto' error={errors.cardCollection}>
            <input
              className='input'
              {...register('cardCollection',
                {
                  maxLength: inputMaxLength(10)
                })}
            />
          </BasicDataColumn>
          <BasicDataColumn label='Kortin numero' error={errors.cardNumber}>
            <input
              className='input'
              {...register('cardNumber',
                {
                  maxLength: inputMaxLength(13)
                })}
            />
          </BasicDataColumn>
          <SingleSelectColumn
            label='Korttityyppi' name='cardTypeId' options={cardTypeOptions}
            error={errors.cardType} control={control} isClearable
          />
          <BasicDataColumn label='Luokka' error={errors.cardClass}>
            <input
              className='input'
              {...register('cardClass',
                {
                  maxLength: inputMaxLength(255)
                })}
            />
          </BasicDataColumn>
          <BasicDataColumn label='Talo80' error={errors.cardSfbCode}>
            <input
              className='input'
              {...register('cardSfbCode',
                {
                  maxLength: inputMaxLength(10)
                })}
            />
          </BasicDataColumn>

          <BasicDataColumn label='SFS-koodi' error={errors.cardSfsCode}>
            <input
              disabled
              className='input'
              {...register('cardSfsCode',
                {
                  maxLength: inputMaxLength(10)
                })}
            />
          </BasicDataColumn>
          <BasicDataColumn label='Rinnakkaiset kortit' error={errors.additionalCardReferenceCodes}>
            <input
              className='input'
              {...register('additionalCardReferenceCodes',
                {
                  maxLength: inputMaxLength(255)
                })}
            />
          </BasicDataColumn>
          <BasicDataColumn label='Pääkortti' error={errors.primaryProductId}>
            <input
              className='input'
              {...register('primaryProductId',
                {
                  maxLength: inputMaxLength(30)
                })}
            />
          </BasicDataColumn>

          <BasicDataColumn label='Sarja' error={errors.series}>
            <input
              className='input'
              {...register('series',
                {
                  maxLength: inputMaxLength(10)
                })}
            />
          </BasicDataColumn>
          <BasicDataColumn label='Korttihuomio' error={errors.cardNotification}>
            <input
              className='input'
              {...register('cardNotification',
                {
                  maxLength: inputMaxLength(2000)
                })}
            />
          </BasicDataColumn>
        </div>
      </EditSubSection>

      <EditSubSection title='Yleiset'>
        <BasicDataColumn label='Valmistajat' error={errors.buildingProductManufacturers}>
          <input
            className='input'
            {...register('buildingProductManufacturer',
              {
                maxLength: inputMaxLength(255)
              })}
          />
        </BasicDataColumn>

        <BasicDataColumn label='Kumppani' error={errors.contentPartner}>
          <input
            className='input'
            {...register('contentPartner',
              {
                maxLength: inputMaxLength(255)
              })}
          />
        </BasicDataColumn>

        <BasicDataColumn label='Korttitunnus' error={errors.cardReferenceCode}>
          <input
            className='input' placeholder={cardReferenceCodePlaceholder}
            {...register('cardReferenceCode',
              {
                maxLength: inputMaxLength(50)
              })}
          />
        </BasicDataColumn>
      </EditSubSection>
      <EditSubSection title='Nimet'>
        <BasicDataColumn label='Nimi_SE' error={errors.productNameSE}>
          <input
            className='input'
            {...register('productNameSE',
              {
                maxLength: inputMaxLength(255)
              })}
          />
        </BasicDataColumn>
        <BasicDataColumn label='Nimi_UK' error={errors.productNameEN}>
          <input
            className='input'
            {...register('productNameEN',
              {
                maxLength: inputMaxLength(255)
              })}
          />
        </BasicDataColumn>
        <BasicDataColumn label='Tarvikenimet' error={errors.buildingProductNames}>
          <input
            className='input'
            {...register('buildingProductName',
              {
                maxLength: inputMaxLength(255)
              })}
          />
        </BasicDataColumn>
      </EditSubSection>
    </>
  )
}

export default CardOrFormEditFields
