import React from 'react'
import { Link } from 'react-router-dom'

function Databox (props) {
  const { title, data, notificationText, columnClass, isWrapped, children, contentComponent } = props

  const renderCellContent = (el) => {
    if (contentComponent !== undefined) {
      return contentComponent(el)
    }
    if (!el.url) {
      return (<span className={el.valueClass}>{el.value}</span>)
    } else {
      return el.url.startsWith('/api/files')
        ? <a className={el.valueClass} href={el.url}>{el.value}</a>
        : <Link className={el.valueClass} to={el.url}>{el.value}</Link>
    }
  }

  return (
    <div className='mb-0'>
      {title && (
        <header className='card-header has-background-juha-blue-10 has-no-border'>
          <div className='card-header-title'>
            <p className='title is-6 has-text-weight-bold'>
              {title}
            </p>
            {notificationText &&
              <div className='custom-notification is-flex pl-6 is-align-items-center'>
                <span className='icon is-color-secondary-gray pr-3'>
                  <i className='fas fa-info-circle' />
                </span>
                <p className='is-color-secondary-gray'>
                  {notificationText}
                </p>
              </div>}
          </div>
        </header>
      )}
      <div className='card-content is-paddingless'>
        <div className='columns data-columns is-marginless is-multiline'>
          {data?.map((el, index) =>
            <div key={index} className={`column data-column ${columnClass}`}>
              <div className={`columns is-mobile ${isWrapped && 'is-multiline'} `}>
                <div className={`column ${isWrapped && 'is-full is-flex'} `}>
                  <div className='label databox-label'>{el.title}</div>
                  {el.notificationText &&
                    <span className='icon pl-3 is-color-blue-60'>
                      <i className='far fa-question-circle' />
                    </span>}
                </div>
                <div className={`column ${isWrapped && 'is-full'} pb-5 data-value`}>
                  {renderCellContent(el)}
                </div>
              </div>
            </div>
          )}
          {React.Children.map(children, child =>
            React.cloneElement(child, { columnClass, isWrapped })
          )}
        </div>
      </div>
    </div>
  )
}

export default Databox
