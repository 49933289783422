import React, { useEffect, useState } from 'react'
import { hiddenFilters, mainFilters } from '../model/searchFilters'
import {
  getCardTypeOptions,
  getContentTeamOptions,
  getDevelopmentStateOptions,
  getEditorOptions,
  getLanguageOptions, getPackageOptions, getPricingTypeOptions,
  getProductTypeOptions, getStateOptions, getVatBaseOptions
} from '../services/selectOptionsService'
import { handleFlatMap, mapHierarchicalItems } from '../utils/productUtils'
import SearchFilterField from './SearchFilterField'

function SearchFilters () {
  const [displayHiddenFilters, setDisplayHiddenFilters] = useState(false)
  const [pricingTypeOptions, setPricingTypeOptions] = useState([])
  const [productTypeOptions, setProductTypeOptions] = useState([])
  const [packageOptions, setPackageOptions] = useState([])
  const [origPackageOptions, setOrigPackageOptions] = useState([])
  const [contentTeamOptions, setContentTeamOptions] = useState([])
  const [developmentStateOptions, setDevelopmentStateOptions] = useState([])
  const [editorOptions, setEditorOptions] = useState([])
  const [languageOptions, setLanguageOptions] = useState([])
  const [vatBaseOptions, setVatBaseOptions] = useState([])
  const [stateOptions, setStateOptions] = useState([])
  const [cardTypeOptions, setCardTypeOptions] = useState([])

  useEffect(() => {
    getPricingTypeOptions().then(setPricingTypeOptions)
    getProductTypeOptions().then(setProductTypeOptions)
    getPackageOptions().then((resp) => {
      setPackageOptions(mapHierarchicalItems(resp, 'packageTypeDescription', 'packages', 'packageId', 'packageName'))
      setOrigPackageOptions(handleFlatMap(resp, 'packages', 'packageId', 'packageName'))
    })
    getContentTeamOptions().then(setContentTeamOptions)
    getDevelopmentStateOptions().then(setDevelopmentStateOptions)
    getEditorOptions().then(setEditorOptions)
    getLanguageOptions().then(setLanguageOptions)
    getVatBaseOptions().then(setVatBaseOptions)
    getStateOptions().then(setStateOptions)
    getCardTypeOptions().then(setCardTypeOptions)
  }, [])

  return (
    <div className='SearchFilters'>
      <section className='section pt-0 '>
        <div className='columns is-multiline'>
          {mainFilters(productTypeOptions, packageOptions, origPackageOptions).map((el, index) =>
            <SearchFilterField key={index} options={el} />
          )}
        </div>
        <div className={`hidden-filters columns is-multiline ${!displayHiddenFilters && 'hidden'}`}>
          {hiddenFilters(editorOptions, pricingTypeOptions, contentTeamOptions, developmentStateOptions,
            languageOptions, vatBaseOptions, stateOptions, cardTypeOptions).map((el, index) =>
              <SearchFilterField key={index} options={el} />
          )}
        </div>
        <div className='separator pb-3' onClick={() => setDisplayHiddenFilters(!displayHiddenFilters)}>
          <div className='line' />
          <button className='button show-filters'>
            <p>{displayHiddenFilters
              ? 'Piilota lisärajauskentät'
              : 'Näytä kaikki rajauskentät'}
            </p>
            <span className='icon'>
              {displayHiddenFilters
                ? <i className='fas fa-angle-up' />
                : <i className='fas fa-angle-down' />}
            </span>
          </button>
          <div className='line' />
        </div>
      </section>
    </div>
  )
}

export default SearchFilters
