import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import debounce from 'lodash.debounce'
import { searchProducts } from '../services/productService'

function LiteratureEditForm (props) {
  const { literatureHeadingOptions, literature, handleLiteratureChange } = props
  const [visibleLiterature, setVisibleLiterature] = useState([])

  useEffect(() => {
    if (literature) {
      setVisibleLiterature([...literature])
    }
  }, [literature])

  const updateVisibileLiterature = (newLiterature) => {
    setVisibleLiterature([...newLiterature])
    handleLiteratureChange(visibleLiterature)
  }

  const loadOptions = (input, callback) => {
    if (!input.startsWith('kortti:')) {
      const response = [{ value: input, label: input }]
      callback(response)
      return
    }

    const [, suffix] = input.split(':')

    const cardReferenceCodeQueryParam = new URLSearchParams(`cardReferenceCode=${suffix}`)
    const productNameQueryParam = new URLSearchParams(`productName=${suffix}`)

    Promise.all([searchProducts(cardReferenceCodeQueryParam), searchProducts(productNameQueryParam)])
      .then(response => {
        // Combine results and remove duplicates
        const products = response
          .flatMap(resp => resp.data.result)
          .filter((product, index, array) => array.findIndex(product2 => (product2.productId === product.productId)) === index)

        const result = products.map(p => ({ value: `kortti:${p.cardReferenceCode}`, label: `kortti:${p.cardReferenceCode} - ${p.productName}` }))
        console.log('Got results', result)
        callback(result)
      })
  }

  const handleChange = (idx) => (e) => {
    if (e.label.startsWith('kortti:')) {
      visibleLiterature[idx].urlDescription = e.label.split(':')[1]
    }
    visibleLiterature[idx].url = e.value
    updateVisibileLiterature(visibleLiterature)
  }

  return (
    <div className='column data-column is-4'>
      <label className='label'>Kirjallisuusviitteet</label>
      {visibleLiterature.map((entry, idx) => {
        return (
          <div key={idx}>
            <div className='control' style={{ marginBottom: '0.5em' }}>
              <label className='label small-label'>Viitetyyppi</label>
              <Select
                options={literatureHeadingOptions}
                onChange={e => {
                  if (e) {
                    visibleLiterature[idx].literatureHeadingId = e.value
                    visibleLiterature[idx].literatureHeading = e.label
                    updateVisibileLiterature(visibleLiterature)
                  }
                }}
                value={{ label: visibleLiterature[idx].literatureHeading, value: visibleLiterature[idx].literatureHeadingId }}
              />
              <label className='label small-label'>Url</label>
              <div className='control'>
                <AsyncSelect
                  noOptionsMessage={() => 'Ei tuloksia'}
                  loadingMessage={() => 'Ladataan...'}
                  defaultOptions={[]}
                  cacheOptions
                  loadOptions={debounce(loadOptions, 500)}
                  onChange={handleChange(idx)}
                  value={{
                    label: visibleLiterature[idx].url?.startsWith('kortti:') ? `kortti:${visibleLiterature[idx].urlDescription}` : visibleLiterature[idx].url,
                    value: visibleLiterature[idx].url
                  }}
                />
              </div>
              <label className='label small-label'>Nimi</label>
              <div className='control'>
                <input
                  className={`input ${visibleLiterature[idx].url?.startsWith('kortti:') && 'disabled-field'}`}
                  readOnly={!!visibleLiterature[idx].url?.startsWith('kortti:')}
                  value={visibleLiterature[idx].urlDescription}
                  onChange={e => {
                    visibleLiterature[idx].urlDescription = e.target.value
                    updateVisibileLiterature(visibleLiterature)
                  }}
                />
              </div>
              <button
                className='title is-6 button is-juha-secondary-button mt-2 mb-2 link-delete-button'
                onClick={(e) => {
                  e.preventDefault()
                  visibleLiterature.splice(idx, 1)
                  updateVisibileLiterature(visibleLiterature)
                }}
              >
                <span className='icon'>
                  <i className='fas fa-trash' />
                </span>
                <p>
                  Poista viite
                </p>
              </button>
            </div>
            <div className='separator pb-2'>
              <div className='line has-background-juha-blue-5' />
            </div>
          </div>
        )
      })}
      <Select
        placeholder='Lisää viite...'
        noOptionsMessage={() => 'Ei vaihtoehtoja'}
        options={literatureHeadingOptions}
        onChange={e => {
          if (e) {
            visibleLiterature.push({ urlDescription: '', url: '', literatureHeading: e.label, literatureHeadingId: e.value })
            updateVisibileLiterature(visibleLiterature)
          }
        }}
        value={null}
        isClearable
      />
    </div>
  )
}

export default LiteratureEditForm
