
const isValidDate = d => {
  return d instanceof Date && !isNaN(d)
}

const parseCalendarParams = (queryParams) => {
  let secondDate
  let queryDateControl = 'exact'
  if (queryParams.indexOf('<') === queryParams.length - 1) {
    queryParams = queryParams.replace('<', '')
    queryDateControl = 'from'
  } else if (queryParams.indexOf('>') === queryParams.length - 1) {
    queryParams = queryParams.replace('>', '')
    queryDateControl = 'to'
  } else if (queryParams.indexOf('-') > -1) {
    const dates = queryParams.split('-')
    queryParams = dates[0]
    secondDate = dates[1]
    queryDateControl = 'range'
  }
  const dateParts = queryParams.split('.')
  const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
  let secondDateObject
  if (queryDateControl === 'range') {
    const secondDateParts = secondDate.split('.')
    secondDateObject = new Date(+secondDateParts[2], secondDateParts[1] - 1, +secondDateParts[0])
  }

  return { firstDate: dateObject, secondDate: secondDateObject, queryDateControl }
}

const validateCalendarParams = (firstDate, secondDate, queryDateControl) => {
  if (queryDateControl === 'range') {
    return isValidDate(firstDate) && isValidDate(secondDate)
  } else {
    return isValidDate(firstDate)
  }
}

const setCalendarParams = (firstDate, secondDate, queryDateControl, setAppliedFilters, setAppliedSecondDate, setDateControl) => {
  setAppliedFilters(firstDate)
  setAppliedSecondDate(secondDate)
  setDateControl(queryDateControl)
}

const deleteCalendarParams = (title, setAppliedFilters, setAppliedSecondDate, setDateControl, query, history, location) => {
  query.delete(title)
  setAppliedFilters(undefined)
  setAppliedSecondDate(undefined)
  history.push(location.pathname + '?' + decodeURIComponent(query.toString()))
}

export {
  isValidDate,
  parseCalendarParams,
  validateCalendarParams,
  setCalendarParams,
  deleteCalendarParams
}
