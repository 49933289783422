import React from 'react'

function FixedColumn (props) {
  const { title, columnClass } = props

  return (
    <div className={'column data-column ' + columnClass}>
      <div className='columns is-multiline'>
        <div className='column is-full label databox-label'>
          {title}
        </div>
        {props.children}
      </div>
    </div>
  )
}

export default FixedColumn
