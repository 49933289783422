const FileUploadModal = (props) => {
  return (
    <div className={`modal ${!!props.modalState && 'is-active'}`}>
      <div className='modal-background' />
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>{props.title}</p>
          <button
            onClick={props.onCloseModal}
            className='delete'
            aria-label='close'
          />
        </header>
        <section className='modal-card-body'>
          {props.children}
        </section>
        <footer className='modal-card-foot'>
          <button onClick={props.onCloseModal} className='button is-juha-secondary-button'>
            Peruuta
          </button>
          <label onClick={props.onSubmitModal} className='button is-juha-primary-button'>
            <span className='icon'>
              <i className='fas fa-file-upload' />
            </span>
            <p>
              {props.submitText}
            </p>
          </label>
        </footer>
      </div>
    </div>
  )
}
export default FileUploadModal
