import React from 'react'
import { Link } from 'react-router-dom'

function PredefinedSearchButton (props) {
  const { text, destination } = props
  return (
    <div className='PredefinedSearchButton'>
      <Link className='button is-juha-secondary-button' to={destination}>
        <div className='columns is-flex'>
          <div className='column has-text-left'>
            <p className='is-color-primary'>{text}</p>
          </div>
          <div className='column is-narrow'>
            <span className='icon is-color-primary'>
              <i className='fas fa-chevron-right' />
            </span>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default PredefinedSearchButton
