import { convertToDisplayDate } from './formUtils'

const location = new URL(window.location.href.toString())
const JUHA_LINK_BASE_URL = `${location.protocol}//${location.host}/product`
const KORTISTOT_LINK_BASE_URL = 'https://kortistot.rakennustieto.fi/kortit'

const productDataModel = (products) => {
  return products.map((p) => {
    return {
      ...p,
      juhaLink: encodeURI(`${JUHA_LINK_BASE_URL}/${p.productId}`),
      kortistotLink: encodeURI(`${KORTISTOT_LINK_BASE_URL}/${p.cardReferenceCode}`),
      publicationDate: convertToDisplayDate(p.publicationDate),
      cancelDate: convertToDisplayDate(p.cancelDate),
      priceNet: String(p.priceNet).replace('.', ','),
      priceVAT: String(p.priceVAT).replace('.', ',')
    }
  })
}

export { productDataModel }
