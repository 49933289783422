import React, { useContext, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import NewProductButton from './NewProductButton'
import { UserContext } from '../utils/userUtils'

function Header () {
  const { userName, isAdmin, isWriter } = useContext(UserContext)
  const [mobileMenuVisible, toggleMobileMenuVisible] = useState(false)

  return (
    <nav className='Header navbar is-fixed-top is-hidden-print' role='navigation' aria-label='main navigation'>
      <div className='container'>
        <div className='navbar-brand'>
          <Link className='navbar-item' to='/dashboard'>
            <div className='title is-3 is-color-primary'>
              Juha
            </div>
          </Link>
          <button
            className='navbar-burger'
            aria-label='menu'
            aria-expanded='false'
            data-target='navMenu'
            onClick={() => toggleMobileMenuVisible(!mobileMenuVisible)}
          >
            <div className='icon'>
              {mobileMenuVisible ? <i className='fas fa-times fa-2x' /> : <i className='fas fa-bars fa-2x' />}
            </div>
          </button>
        </div>
        <div className={mobileMenuVisible ? 'is-active navbar-menu' : 'navbar-menu'} id='navMenu'>
          <div className='navbar-end'>
            <NavLink
              className='navbar-item is-tab'
              activeClassName='is-active'
              exact
              to='/dashboard'
              onClick={() => toggleMobileMenuVisible(false)}
            >
              <div className='title is-6'>
                Etusivu
              </div>
            </NavLink>
            <NavLink
              className='navbar-item is-tab'
              activeClassName='is-active'
              to='/search'
              onClick={() => toggleMobileMenuVisible(false)}
            >
              <div className='title is-6'>
                Haku
              </div>
            </NavLink>
            {isAdmin &&
              <div className='navbar-item has-dropdown is-hoverable'>
                <Link
                  to='/admin'
                  className='navbar-link is-marginless title is-6'
                  onClick={() => toggleMobileMenuVisible(false)}
                >
                  Ylläpito
                </Link>
                <div className='navbar-dropdown'>
                  <Link
                    to='/admin/headings'
                    className='navbar-item'
                    onClick={() => toggleMobileMenuVisible(false)}
                  >
                    Otsikot
                  </Link>
                  <Link
                    to='/admin/editors'
                    className='navbar-item'
                    onClick={() => toggleMobileMenuVisible(false)}
                  >
                    Toimittajat
                  </Link>
                </div>
              </div>}
            {(isWriter || isAdmin) &&
              <div className='navbar-item'>
                <NewProductButton primary />
              </div>}
            <div className='navbar-item has-dropdown is-hoverable'>
              <div
                className='navbar-link is-marginless title is-6 is-flex is-align-items-center'
                onClick={() => toggleMobileMenuVisible(false)}
              >
                <span className='user-icon icon'>
                  <i className='far fa-user fa-lg' />
                </span>
                <p className='user-name'>
                  {userName}
                </p>
              </div>
              <div className='navbar-dropdown'>
                <a
                  href='/logout'
                  className='navbar-item'
                  onClick={() => toggleMobileMenuVisible(false)}
                >
                  Kirjaudu ulos
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Header
