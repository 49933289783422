import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

const getHeadingTreeByClassId = (id, data) => {
  return axios.get(`${API_URL}/heading/headingclass/${id}`, data)
}

const deleteHeading = (id) => {
  return axios.delete(`${API_URL}/heading/${id}`)
}

const updateHeading = (data) => {
  return axios.patch(`${API_URL}/heading`, data)
}

export {
  getHeadingTreeByClassId,
  deleteHeading,
  updateHeading
}
