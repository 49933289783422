import React from 'react'
import { Controller } from 'react-hook-form'
import Select from 'react-select'

function SingleSelectField (props) {
  const { name, options, error, isClearable, control } = props
  return (
    <div>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Select
            placeholder='Valitse...'
            noOptionsMessage={() => 'Ei vaihtoehtoja'}
            ref={field.ref}
            options={options}
            onChange={e => {
              if (e) {
                field.onChange(e.value)
              } else {
                field.onChange(undefined)
              }
            }}
            value={options.find(c => c.value === field.value)}
            isClearable={isClearable}
          />
        )}
      />
      {error && <div className='is-color-accent-red'>{error.message}</div>}
    </div>

  )
}

export default SingleSelectField
