import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

const getUserDetails = async () => {
  return await axios.get(`${API_URL}/user/`).then(resp => {
    let isAdmin = false
    let isWriter = false
    if (resp?.data) {
      const roles = resp.data?.authorities
      isAdmin = roles?.includes('ROLE_JUHA_ADMIN')
      isWriter = roles?.includes('ROLE_JUHA_WRITER')
      return { userName: resp.data.name, isAdmin, isWriter }
    }
  }).catch(err => {
    return { error: { code: err?.response?.status } }
  })
}

export {
  getUserDetails
}
