import React from 'react'
import BasicDataColumn from './BasicDataColumn'
import SingleSelectColumn from './SingleSelectColumn'
import { inputMaxLength } from '../utils/formUtils'

const AttachmentFileUploadFields = (props) => {
  const { register, control, errors, fileContent, fileTypeHeadingOptions, getValues } = props
  return (
    <div>
      <SingleSelectColumn
        width='full' label='Otsikko' name='attachmentFileProps.fileTypeHeadingId' options={fileTypeHeadingOptions}
        error={errors.state} control={control} isClearable
      />
      <BasicDataColumn width='full' label='Tiedoston kuvaus' error={errors.fileDescription}>
        <textarea
          className='input'
          {...register('attachmentFileProps.fileDescription',
            {
              maxLength: inputMaxLength(255)
            })}
        />
      </BasicDataColumn>
      <SingleSelectColumn
        width='full'
        label='Päätiedosto' name='attachmentFileProps.mainContent' options={[
          { value: false, label: 'Ei' },
          { value: true, label: 'Kyllä' }
        ]}
        error={errors.state} control={control} isClearable
      />

      {(!getValues('attachmentFileProps.name')) &&
        <BasicDataColumn width='full' label='Lataa Liitetiedosto' error={errors.fileContent}>
          <div>
            <label htmlFor='files' className='button is-juha-secondary-button'>Lataa tiedosto</label>
            <input
              className='is-hidden'
              id='files'
              type='file'
              {...register('fileContent')}
            />
          </div>
        </BasicDataColumn>}

      {(getValues('attachmentFileProps.name')) &&
        <BasicDataColumn width='full' label='Tiedosto' error={errors.fileContent}>
          <input
            className='input disabled-field'
            readOnly
            {...register('attachmentFileProps.name')}
          />
        </BasicDataColumn>}

      {(fileContent && !getValues('attachmentFileProps.name')) &&
        <BasicDataColumn width='full' label='Tiedosto' error={errors.fileContent}>
          <input
            className='input disabled-field'
            readOnly
            value={fileContent && fileContent[0].name}
          />
        </BasicDataColumn>}

      <input
        className='is-hidden'
        {...register('attachmentFileProps.fileId',
          {
            maxLength: inputMaxLength(255)
          })}
      />
    </div>
  )
}
export default AttachmentFileUploadFields
