import React, { useState, useEffect } from 'react'
import { searchProducts } from '../services/productService'
import AsyncSelect from 'react-select/async'
import CustomMultiValueLabel from './CustomMultiValueLabel'

function ProductSearchAsyncSelect (props) {
  const { selectedProducts, selectedProductsChange } = props
  const [currentSelectedProducts, setCurrentSelectedProducts] = useState([])

  useEffect(() => {
    if (selectedProducts) {
      setCurrentSelectedProducts(selectedProducts)
    }
  }, [selectedProducts])

  const search = (inputValue) => {
    const cardReferenceCodeQueryParam = new URLSearchParams(`cardReferenceCode=${inputValue}`)
    const productNameQueryParam = new URLSearchParams(`productName=${inputValue}`)

    return Promise.all([searchProducts(cardReferenceCodeQueryParam), searchProducts(productNameQueryParam)])
      .then(response => {
        // Combine results and remove duplicates
        const products = response.flatMap(resp => resp.data.result)
          .filter((product, index, array) =>
            array.findIndex(product2 => (product2.productId === product.productId)) === index)

        return mapSearchResultsToOptions(products)
      })
  }

  const mapSearchResultsToOptions = (results) => {
    return results.map((result) => {
      return {
        value: result.productId,
        label: `${result.cardReferenceCode} - ${result.productName}`
      }
    })
  }

  const loadOptions = inputValue =>
    new Promise(resolve => {
      resolve(search(inputValue))
    })

  const handleChange = (products) => {
    setCurrentSelectedProducts([...products])
    selectedProductsChange([...products])
  }

  return (
    <div className='ProductSearchDropdownSelect'>
      <AsyncSelect
        isMulti
        placeholder='Etsi tuotenimellä...'
        noOptionsMessage={() => 'Ei tuloksia'}
        loadingMessage={() => 'Ladataan...'}
        loadOptions={loadOptions}
        onChange={(e) => handleChange(e)}
        value={currentSelectedProducts}
        components={{
          MultiValueLabel: (props) => {
            return <CustomMultiValueLabel {...props} />
          }
        }}
      />
    </div>
  )
}

export default ProductSearchAsyncSelect
