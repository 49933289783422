import React from 'react'
import BasicDataColumn from './BasicDataColumn'
import Select from 'react-select'
import { Controller } from 'react-hook-form'
import chroma from 'chroma-js'

function MultiSelectColumn (props) {
  const { label, name, options, error, control, origOptions, customFilter, useCustomColors } = props

  const handleValue = (field) => {
    if (origOptions) {
      return origOptions.filter(c => field.value?.includes(c.value))
    } else {
      return options.filter(c => field.value?.includes(c.value))
    }
  }

  const getColor = (data) => {
    return data.color || 'black'
  }

  const extraAttributes = {
  }

  if (customFilter) {
    extraAttributes.filterOption = customFilter
  }

  const colorStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(getColor(data))
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? getColor(data)
            : isFocused
              ? chroma.contrast(color, 'white') > 4.5
                  ? color.brighten().alpha(0.1).css()
                  : color.darken().alpha(0.1).css()
              : undefined,
        color: isDisabled
          ? '#ccc'
          : getColor(data),
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
                ? getColor(data)
                : color.alpha(0.3).css()
            : undefined
        }
      }
    },
    multiValue: (styles, { data }) => {
      const color = chroma(getColor(data))
      return {
        ...styles,
        backgroundColor: chroma.contrast(color, 'white') > 4.5
          ? color.brighten().alpha(0.1).css()
          : color.darken().alpha(0.1).css()
      }
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: getColor(data)
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: getColor(data),
      ':hover': {
        backgroundColor: getColor(data),
        color: 'white'
      }
    })
    // groupHeading: (styles, { data }) => ({
    //   ...styles,
    //   color: getColor(data)
    // })
  }

  return (
    <BasicDataColumn label={label} error={error}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Select
            closeMenuOnSelect={false}
            placeholder='Valitse...'
            noOptionsMessage={() => 'Ei vaihtoehtoja'}
            ref={field.ref}
            options={options}
            onChange={e => field.onChange(e.map(c => c.value))}
            value={handleValue(field)}
            isMulti
            {...extraAttributes}
            styles={useCustomColors && colorStyles}
          />
        )}
      />
    </BasicDataColumn>
  )
}

export default MultiSelectColumn
