import React from 'react'
import BasicDataColumn from './BasicDataColumn'
import { inputMaxLength } from '../utils/formUtils'
import SingleSelectColumn from './SingleSelectColumn'
import { selectOptions } from '../model/formSelectOptions'
import { EditSubSection } from './EditSubSection'

function WebServiceEditFields (props) {
  const { register, errors, control } = props

  return (
    <EditSubSection title='Luokittelu'>
      <BasicDataColumn label='Järjestysnumero' error={errors.serviceOrdinal}>
        <input
          className='input'
          type='number'
          min='0'
          {...register('serviceOrdinal',
            {
              maxLength: inputMaxLength(255)
            })}
        />
      </BasicDataColumn>
      <BasicDataColumn label='Emopalvelun Tuote-ID' error={errors.serviceParentProductId}>
        <input
          className='input'
          {...register('serviceParentProductId',
            {
              maxLength: inputMaxLength(30)
            })}
        />
      </BasicDataColumn>
      <SingleSelectColumn
        label='Palveluluettelossa' name='serviceVisibility' options={selectOptions.serviceVisibilityOptions}
        error={errors.serviceVisibility} control={control}
      />
      <BasicDataColumn label='Polkunimi' error={errors.pathname}>
        <input
          className='input'
          {...register('pathname',
            {
              maxLength: inputMaxLength(100)
            })}
        />
      </BasicDataColumn>
      <BasicDataColumn label='Ryhmäotsikko' error={errors.serviceGroupHeading}>
        <input
          className='input'
          {...register('serviceGroupHeading',
            {
              maxLength: inputMaxLength(100)
            })}
        />
      </BasicDataColumn>
    </EditSubSection>
  )
}

export default WebServiceEditFields
