import React, { useEffect, useState } from 'react'
import Select from 'react-select'

function IPRightsEditForm (props) {
  const { handleIPRightsChange, ipRights, ipRightOptions, stakeholderOptions } = props
  const [selectedIPRight, setSelectedIPRight] = useState()
  const [ifIPRightError, setIPRightError] = useState(false)
  const [selectedStakeholders, setSelectedStakeholders] = useState([])
  const [ifStakeholderError, setStakeholderError] = useState(false)
  const [currentIPRights, setCurrentIPRights] = useState([])

  useEffect(() => {
    if (ipRights) {
      setCurrentIPRights([...ipRights])
    }
  }, [ipRights])

  const handleNewIPRight = (e) => {
    e.preventDefault()
    if (selectedIPRight && selectedStakeholders.length > 0) {
      let ipRightExists = false
      currentIPRights.map((ipRight, index) => {
        if (ipRight.ipRightId === selectedIPRight.value) {
          // Combine arrays and remove duplicates
          currentIPRights[index].stakeholders = [
            ...currentIPRights[index].stakeholders,
            ...selectedStakeholders
          ].filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i)
          ipRightExists = true
        }
        return null
      })
      if (!ipRightExists) {
        currentIPRights.push({
          ipRightId: selectedIPRight.value,
          ipRight: selectedIPRight.label,
          stakeholders: selectedStakeholders
        })
      }
      setSelectedIPRight(null)
      setSelectedStakeholders([])
      setCurrentIPRights([...currentIPRights])
      handleIPRightsChange([...currentIPRights])
    } else {
      if (!selectedIPRight) {
        setIPRightError(true)
      }
      if (selectedStakeholders.length === 0) {
        setStakeholderError(true)
      }
    }
  }

  const handleDeleteIPRight = (ipRightIndex, stakeholderIndex) => {
    currentIPRights[ipRightIndex].stakeholders.splice(stakeholderIndex, 1)
    if (currentIPRights[ipRightIndex].stakeholders.length === 0) {
      currentIPRights.splice(ipRightIndex, 1)
    }
    setCurrentIPRights([...currentIPRights])
    handleIPRightsChange([...currentIPRights])
  }

  return (
    <div className='IPRightsEditForm column data-column is-4'>
      <label className='label'>IPR</label>
      <div>
        <label className='label small-label'>Oikeus*</label>
        <Select
          placeholder='Valitse...'
          noOptionsMessage={() => 'Ei vaihtoehtoja'}
          options={ipRightOptions}
          onChange={e => {
            setIPRightError(false)
            if (e) {
              setSelectedIPRight(e)
              setIPRightError(false)
            } else {
              setSelectedIPRight(undefined)
            }
          }}
          value={selectedIPRight}
          isClearable
        />
        {ifIPRightError && <div className='is-color-accent-red'>Oikeus vaaditaan</div>}
        <label className='label small-label mt-2'>Osapuoli*</label>
        <Select
          placeholder='Valitse...'
          noOptionsMessage={() => 'Ei vaihtoehtoja'}
          options={stakeholderOptions}
          onChange={e => {
            setStakeholderError(false)
            if (e) {
              setSelectedStakeholders(e)
              setStakeholderError(false)
            } else {
              setSelectedStakeholders(undefined)
            }
          }}
          value={selectedStakeholders}
          isClearable
          isMulti
          closeMenuOnSelect={false}
        />
        {ifStakeholderError && <div className='is-color-accent-red'>Valitse vähintään yksi osapuoli</div>}
        <button className='title is-6 button is-juha-secondary-button mt-2 mb-2' onClick={handleNewIPRight}>
          <span className='icon'>
            <i className='fas fa-plus' />
          </span>
          <p>
            Lisää uusi oikeus
          </p>
        </button>
      </div>
      <div className='separator pb-2'>
        <div className='line has-background-juha-blue-5' />
      </div>
      <div>
        {currentIPRights.map((ipRight, ipRightIndex) => {
          return (
            <div key={ipRight.ipRight + ' ' + ipRightIndex} className='mb-2'>
              <label className='label small-label'>{ipRight.ipRight}</label>
              {ipRight.stakeholders.map((stakeholder, stakeholderIndex) => {
                return (
                  <div key={stakeholder.value + ' ' + stakeholderIndex} className='single-ipr is-flex mb-1 is-justify-content-space-between'>
                    <p>{stakeholder.label}</p>
                    <div className='is-flex delete-ipr' onClick={() => handleDeleteIPRight(ipRightIndex, stakeholderIndex)}>
                      <span className='icon'>
                        <i className='fas fa-times fa-xs' />
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default IPRightsEditForm
