import React, { useEffect, useState } from 'react'
import AttachmentFilesList from './AttachmentFilesList'
import FileUploadModal from './FileUploadModal'
import AttachmentFileUploadFields from './AttachmentFileUploadFields'
import { getFileTypeHeadings } from '../services/productService'
import { calcOrdinals } from '../utils/productUtils'

const MODAL_STATE_CLOSED = 0
const MODAL_STATE_NEW = 1
const MODAL_STATE_EDIT = 2

function AttachmentFilesForm (props) {
  const {
    productAttachments,
    register,
    control,
    errors,
    setValue,
    getValues,
    fileContent,
    setProductAttachmentsForEdit
  } = props

  const [modalState, setModalState] = useState(0)
  const [modalTitle, setModalTitle] = useState('')
  const [modalSubmitText, setModalSubmitText] = useState('')
  const [fileTypeHeadingOptions, setFileTypeHeadingOptions] = useState([])

  useEffect(() => {
    getFileTypeHeadings().then(setFileTypeHeadingOptions)
  }, [])

  const handleOpenModal = (e) => {
    e.preventDefault()
    setValue('attachmentFileProps', {
      fileId: '',
      fileTypeHeadingId: '',
      fileContent: '',
      mainContent: '',
      fileDescription: '',
      ordinal: ''
    }
    )
    setModalTitle('Lataa tiedosto')
    setModalSubmitText('Lisää tiedosto')
    setModalState(MODAL_STATE_NEW)
  }

  const openModalForEdit = (item) => {
    item.state = 'modified'
    setValue('attachmentFileProps', item)
    setModalTitle('Päivitä tiedostoa')
    setModalSubmitText('Päivitä tiedosto')
    setModalState(MODAL_STATE_EDIT)
  }

  const markForDelete = (item) => {
    const mappedItems = productAttachments.map(file => {
      if (file.fileId === item.fileId) {
        file.deleted = !file.deleted
      }
      return file
    })
    setProductAttachmentsForEdit(mappedItems)
  }

  const closeModal = () => {
    setValue('attachmentFileProps', {
      fileId: '',
      name: '',
      fileType: '',
      fileContent: '',
      mainContent: '',
      fileDescription: '',
      fileTypeHeadingId: '',
      ordinal: '',
      state: '',
      deleted: false
    })
    setModalState(MODAL_STATE_CLOSED)
  }

  const handleCloseModal = (e) => {
    e.preventDefault()
    closeModal()
  }

  const handleSubmitModal = (e) => {
    e.preventDefault()
    const updatedAttachments = productAttachments || []
    const attachment = updatedAttachments?.find(
      item => {
        return item.fileId === getValues('attachmentFileProps,fileId')
      })

    if (attachment) {
      attachment.fileTypeHeadingId = getValues('attachmentFileProps,fileTypeHeadingId')
      attachment.fileDescription = getValues('attachmentFileProps,fileDescription')
      attachment.ordinal = getValues('attachmentFileProps,ordinal')
      attachment.mainContent = getValues('attachmentFileProps,mainContent')
      attachment.state = modalState === MODAL_STATE_NEW ? 'added' : 'modified'
      attachment.deleted = false
    } else {
      const loadedFile = getValues('fileContent')
      updatedAttachments.push(
        {
          fileDescription: getValues('attachmentFileProps,fileDescription'),
          fileTypeHeadingId: getValues('attachmentFileProps,fileTypeHeadingId'),
          mainContent: getValues('attachmentFileProps,mainContent'),
          name: modalState && modalState === MODAL_STATE_NEW ? loadedFile[0].name : getValues('attachmentFileProps,name'),
          ordinal: getValues('attachmentFileProps,ordinal'),
          state: modalState && modalState === MODAL_STATE_NEW ? 'added' : 'modified',
          deleted: false
        })
    }
    setProductAttachmentsForEdit(calcOrdinals(updatedAttachments))
    closeModal()
  }

  return (
    <>
      <AttachmentFilesList
        title='Liitetiedostot'
        className='is-8'
        columnData={productAttachments}
        openModalForEdit={openModalForEdit}
        markForDelete={markForDelete}
        handleOpenModal={handleOpenModal}
        setProductAttachmentsForEdit={setProductAttachmentsForEdit}
      />

      <FileUploadModal
        modalState={modalState}
        onOpenModal={handleOpenModal}
        onCloseModal={handleCloseModal}
        onSubmitModal={handleSubmitModal}
        title={modalTitle}
        submitText={modalSubmitText}
      >
        <AttachmentFileUploadFields
          register={register}
          control={control}
          errors={errors}
          fileContent={fileContent}
          fileTypeHeadingOptions={fileTypeHeadingOptions}
          getValues={getValues}
        />
      </FileUploadModal>
    </>
  )
}
export default AttachmentFilesForm
