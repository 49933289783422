import React, { useState, useEffect } from 'react'

function SingleLinkItem (props) {
  const { linkItem, handleDeleteLink, onLinkChange, index } = props
  const [currentUrlDescription, setCurrentUrlDescription] = useState()
  const [currentUrl, setCurrentUrl] = useState()
  useEffect(() => {
    setCurrentUrlDescription(linkItem.urlDescription || '')
    setCurrentUrl(linkItem.url || '')
  }, [])

  const onUrlDescriptionChange = (e) => {
    setCurrentUrlDescription(e.target.value)
    onLinkChange({ ...linkItem, urlDescription: e.target.value, url: currentUrl }, index)
  }

  const onUrlChange = (e) => {
    setCurrentUrl(e.target.value)
    onLinkChange({ ...linkItem, urlDescription: currentUrlDescription, url: e.target.value }, index)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }
  return (
    <div>
      <label className='label small-label'>Nimi</label>
      <div className='control'>
        <input
          className='input'
          value={currentUrlDescription}
          onChange={e => onUrlDescriptionChange(e)}
          onKeyDown={e => handleKeyDown(e)}
        />
      </div>
      <label className='label small-label pt-2'>Url</label>
      <div className='control'>
        <input
          className='input'
          value={currentUrl}
          onChange={e => onUrlChange(e)}
          onKeyDown={e => handleKeyDown(e)}
        />
      </div>
      <button
        className='title is-6 button is-juha-secondary-button mt-2 mb-2 link-delete-button'
        onClick={(e) => handleDeleteLink(index, e)}
      >
        <span className='icon'>
          <i className='fas fa-trash' />
        </span>
        <p>
          Poista linkki
        </p>
      </button>
    </div>
  )
}

export default SingleLinkItem
