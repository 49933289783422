import axios from 'axios'
import { serialize } from 'object-to-formdata'
import { convertToDateObject } from '../utils/formUtils'
import { mapHeadingClass } from '../utils/headingUtils'
import {
  defaultCardReferenceCode,
  getFileItem,
  getInternalFileItem,
  getGroupedItem,
  getProductBasicDetails,
  getProductClassificationDetailsForCardOrForm,
  getProductDescriptions,
  getProductDetailsForPublication,
  getProductDetailsForWebService,
  getProductGeneralDetails,
  getProductGeneralDetailsForCardOrForm,
  getProductItemDetails,
  getProductNamingDetailsForCardOrForm,
  getProductPriceDetails,
  getProductReferenceItem,
  sortItemsByCode
} from '../utils/productUtils'
import { getVisibilityClass } from '../utils/styleUtils'

const API_URL = process.env.REACT_APP_API_URL

const getProduct = async (productId) => {
  try {
    const resp = await axios.get(`${API_URL}/product/${productId}`)
    let additionalData = []
    const categoryId = resp.data.categoryId
    if (categoryId === 'kortti' || categoryId === 'lomake') {
      additionalData = {
        primaryAdditionalClassificationData: getProductClassificationDetailsForCardOrForm(resp.data),
        primaryAdditionalNamingData: getProductNamingDetailsForCardOrForm(resp.data),
        primaryAdditionalGeneralData: getProductGeneralDetailsForCardOrForm(resp.data)
      }
      if (resp.data.primaryProductId) {
        const primaryProductResp = await axios.get(`${API_URL}/product/${resp.data.primaryProductId}`)
        additionalData.primaryAdditionalClassificationData = [...additionalData.primaryAdditionalClassificationData.filter(it => it.title !== 'Pääkortti'),
          { title: 'Pääkortti', value: primaryProductResp.data.cardReferenceCode, url: primaryProductResp.data.productId, valueClass: getVisibilityClass(primaryProductResp.data.visibility) }
        ]
      }
    } else if (categoryId === 'julkaisu') {
      additionalData = { primaryAdditionalData: getProductDetailsForPublication(resp.data) }
    } else if (categoryId === 'verkkopalvelu') {
      additionalData = { primaryAdditionalData: getProductDetailsForWebService(resp.data) }
    }

    return {
      productId: resp.data.productId,
      productName: resp.data.productName,
      cardReferenceCode: resp.data.cardReferenceCode,
      productMemo: resp.data.productMemo,
      categoryId: categoryId,
      basicData: getProductBasicDetails(resp.data),
      priceData: getProductPriceDetails(resp.data),
      generalData: getProductGeneralDetails(resp.data),
      productDescriptions: getProductDescriptions(resp.data),
      orig: resp.data,
      ...additionalData
    }
  } catch (err) {
    return { error: { code: err?.response?.status } }
  }
}

const getProductReplacements = async (productId, forEdit) => {
  const resp = await axios.get(`${API_URL}/product/${productId}/productReplacements`)
  const replacingProducts = resp.data.replacingProducts?.map(item => getProductItemDetails(item,
    `/product/${item.productId}`, item.cardSortingCode))
  const replacedProducts = resp.data.replacedProducts?.map(item => getProductItemDetails(item,
    `/product/${item.productId}`, item.cardSortingCode))
  return forEdit
    ? resp.data
    : [
        {
          itemLabel: 'Korvaavat tuotteet',
          items: replacingProducts.sort(sortItemsByCode)
        },
        {
          itemLabel: 'Korvatut tuotteet',
          items: replacedProducts.sort(sortItemsByCode)
        }
      ]
}

const getProductDataForEditPage = async (productId) => {
  return await axios.get(`${API_URL}/product/${productId}`)
    .then(resp => {
      return {
        ...resp.data,
        createdTime: convertToDateObject(resp.data.createdTime),
        updateTime: convertToDateObject(resp.data.updateTime),
        publicationDate: convertToDateObject(resp.data.publicationDate),
        cancelDate: convertToDateObject(resp.data.cancelDate)
      }
    })
    .catch(err => { return { error: { code: err?.response?.status } } })
}

const getProductReferences = async (productId, forEdit) => {
  const resp = await axios.get(`${API_URL}/product/${productId}/productReferences`)
  return forEdit ? resp.data : resp.data.map(item => getProductReferenceItem(item))
}

const getProductIPRights = async (productId, forEdit) => {
  const resp = await axios.get(`${API_URL}/product/${productId}/ipRights`)

  const data = resp.data.map(item => {
    return {
      itemLabel: item.ipRight,
      items: item.stakeholders.map(stakeholder => getGroupedItem(stakeholder.stakeholder, stakeholder.stakeholderId))
    }
  })
  return forEdit ? resp.data : data
}

const getProductLinks = async (productId, forEdit) => {
  const resp = await axios.get(`${API_URL}/product/${productId}/productLinks`)
  const data = resp.data.map(item => {
    if (forEdit) {
      return {
        urlDescription: item.urlDescription,
        url: item.url,
        sortingCode: item.productLinkId
      }
    } else {
      return {
        name: item.urlDescription,
        url: item.url,
        sortingCode: item.productLinkId
      }
    }
  })
  return data.sort(sortItemsByCode)
}

const getProductPackagesHeadingClasses = async (productId) => {
  const resp = await axios.get(`${API_URL}/product/${productId}/headingClasses`)
  return resp.data
}

const getProductPackages = async (productId) => {
  const resp = await axios.get(`${API_URL}/product/${productId}/productPackages`)
  const data = resp.data.map(item => {
    return {
      itemLabel: item.packageTypeDescription,
      items: item.packages.map(pack => getGroupedItem(pack.packageName, pack.packageId)).sort(sortItemsByCode)
    }
  })
  return data.sort(sortItemsByCode)
}

const getProductHeadings = async (productId) => {
  const resp = await axios.get(`${API_URL}/product/${productId}/productHeadings`)
  const data = resp.data.map(item => {
    return mapHeadingClass(item, sortItemsByCode)
  })
  return data.sort(sortItemsByCode)
}

const getProductClasses = async (productId) => {
  const resp = await axios.get(`${API_URL}/product/${productId}/productClasses`)
  const data = resp.data.map(item => {
    return {
      itemLabel: item.classification,
      sortingCode: item.classificationId,
      items: item.classes.map(classItem => getGroupedItem(`${classItem.classCode} ${classItem.class}`,
        classItem.classId)).sort(sortItemsByCode)
    }
  })
  return data.sort(sortItemsByCode)
}

const getProductTags = async (productId) => {
  const resp = await axios.get(`${API_URL}/product/${productId}/tags`)
  const data = resp.data.map(item => {
    return {
      itemLabel: item.tagType,
      sortingCode: item.tagTypeId,
      color: item.tagColor,
      items: item.tags.map(tagItem => getGroupedItem(tagItem.tag, tagItem.tagId)).sort(sortItemsByCode)
    }
  })
  return data.sort(sortItemsByCode)
}

const getProductTeams = async (productId) => {
  const resp = await axios.get(`${API_URL}/product/${productId}/contentTeams`)
  return resp.data.map(item => getGroupedItem(item.contentTeam, item.contentTeamId)).sort(sortItemsByCode)
}

const getProductEditors = async (productId) => {
  const resp = await axios.get(`${API_URL}/product/${productId}/editors`)
  return resp.data.map(item => getGroupedItem(item.editor, item.editorId)).sort(sortItemsByCode)
}

const getProductIndexTerms = async (productId, forEdit) => {
  const resp = await axios.get(`${API_URL}/product/${productId}/indexTerms`)
  return forEdit
    ? resp.data
    : resp.data.map(item => getGroupedItem(`${item.indexTerm} ${item.subIndexTerm}`, item.indexTerm)).sort(sortItemsByCode)
}

const getProductLanguages = async (productId) => {
  const resp = await axios.get(`${API_URL}/product/${productId}/languages`)
  return resp.data.map(item => getGroupedItem(item.language, item.languageId)).sort(sortItemsByCode)
}

const getProductAttachments = async (productId) => {
  const resp = await axios.get(`${API_URL}/product/${productId}/attachments`)
  const data = resp.data.map(item => {
    return {
      itemLabel: item.fileType,
      sortingCode: item.fileType,
      items: item.attachments.map(attachment => getFileItem(attachment)).sort(sortItemsByCode)
    }
  })
  return data.sort(sortItemsByCode)
}

const getProductAttachmentsForEdit = async (productId) => {
  const resp = await axios.get(`${API_URL}/product/${productId}/attachments`)
  const data = resp.data.map(item => {
    return {
      itemLabel: item.fileType,
      sortingCode: item.fileType,
      items: item.attachments.map(attachment => getFileItem(attachment)).sort(sortItemsByCode)
    }
  })
  return data.flatMap(item => { return item.items })
}

const getInternalProductAttachments = async (productId) => {
  const resp = await axios.get(`${API_URL}/product/${productId}/internalAttachments`)
  const data = resp.data.map(item => {
    return {
      itemLabel: item.fileType,
      sortingCode: item.fileType,
      items: item.attachments.map(attachment => getInternalFileItem(attachment)).sort(sortItemsByCode)
    }
  })
  return data.sort(sortItemsByCode)
}

const getInternalProductAttachmentsForEdit = async (productId) => {
  const resp = await axios.get(`${API_URL}/product/${productId}/internalAttachments`)
  const data = resp.data.map(item => {
    return {
      itemLabel: item.fileType,
      sortingCode: item.fileType,
      items: item.attachments.map(attachment => getInternalFileItem(attachment)).sort(sortItemsByCode)
    }
  })
  return data.flatMap(item => { return item.items })
}

const getFileTypeHeadings = async () => {
  const resp = await axios.get(`${API_URL}/fileTypeHeadings`)
  const data = resp.data.map(item => {
    return {
      value: parseInt(item.fileTypeHeadingId),
      label: item.fileTypeHeading
    }
  })
  return data
}

const options = {
  indices: true,
  nullsAsUndefineds: false,
  booleansAsIntegers: false,
  allowEmptyArrays: true
}

const updateExistingProduct = async (data) => {
  const formData = serialize(data, options)
  formData.append('data', JSON.stringify(data))
  formData.append('fileContent', data.fileContent)
  formData.append('internalFileContent', data.internalFileContent)
  formData.append('pdfCardFile', data.newCardPdfFile)

  return await axios.put(`${API_URL}/product/${data.productId}`, formData)
    .then(resp => {
      resp.productId = data.productId
      return resp
    })
    .catch(err => {
      return {
        error: {
          code: err?.response?.status,
          reason: {
            reasonCode: err?.response?.data?.reasonCode,
            filename: err?.response?.data?.filename
          }
        }
      }
    })
}

const postNewProduct = async (data) => {
  if ((data.categoryId === 'kortti' || data.categoryId === 'lomake') && (!data.cardReferenceCode || data.cardReferenceCode.trim() === '')) {
    data.cardReferenceCode = defaultCardReferenceCode(data)
  }

  const formData = serialize(data, options)
  formData.append('data', JSON.stringify(data))
  formData.append('fileContent', data.fileContent)
  formData.append('internalFileContent', data.internalFileContent)
  formData.append('pdfCardFile', data.newCardPdfFile)
  return await axios.post(`${API_URL}/product`, formData)
    .then(resp => {
      return resp.data
    })
    .catch(err => {
      return {
        error: {
          code: err?.response?.status,
          reason: {
            reasonCode: err?.response?.data?.reasonCode,
            filename: err?.response?.data?.filename
          }
        }
      }
    })
}

const searchProducts = async (urlParams) => {
  return await axios.get(`${API_URL}/search`, { params: urlParams })
    .catch(err => { return { error: { code: err?.response?.status } } })
}

const getProductStrapi = (productId) => {
  return axios.get(`${API_URL}/product/${productId}/strapi`)
    .then(res => res.data || {})
    .catch(err => { return { error: { code: err?.response?.status } } })
}

const getProductLiterature = (productId) => {
  return axios.get(`${API_URL}/product/${productId}/literature`)
    .then(res => res.data.map(it => ({ ...it, valueClass: getVisibilityClass(it.visibility) })))
    .catch(err => { return { error: { code: err?.response?.status } } })
}

const initStrapi = async (productId, cardReferenceNumber, productName) => {
  return await axios.post(`${API_URL}/product/${productId}/strapi/init`, undefined, {
    params: {
      productId,
      productName,
      referenceCode: cardReferenceNumber
    }
  }).catch(err => { return { error: { code: err?.response?.status } } })
}

export {
  getProduct,
  updateExistingProduct,
  getProductDataForEditPage,
  postNewProduct,
  getProductReplacements,
  getProductReferences,
  getProductIPRights,
  getProductLinks,
  getProductPackages,
  getProductClasses,
  getProductHeadings,
  getProductTeams,
  getProductEditors,
  getProductIndexTerms,
  getProductLanguages,
  getProductAttachments,
  getProductAttachmentsForEdit,
  getFileTypeHeadings,
  searchProducts,
  getProductPackagesHeadingClasses,
  getProductTags,
  getInternalProductAttachments,
  getInternalProductAttachmentsForEdit,
  getProductStrapi,
  initStrapi,
  getProductLiterature
}
