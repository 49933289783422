import { selectOptions } from '../model/formSelectOptions'

export const mainFilters = (productTypes, packages, origPackages) => [
  { title: 'productId', label: 'Tuote ID', type: 'freeText' },
  { title: 'cardReferenceCode', label: 'Kortti/lomake-tunnus', type: 'freeText' },
  { title: 'productName', label: 'Nimi', type: 'freeText' },
  { title: 'visibility', label: 'Näkyvyys Tietoväylässä', type: 'singleSelect', filters: [{ value: 'visible', label: 'Näkyvillä' }, { value: 'hidden', label: 'Ei näkyvillä' }, { value: 'archive', label: 'Arkistoitu' }] },
  { title: 'productType', label: 'Tuotetyyppi', type: 'singleSelect', filters: productTypes },
  { title: 'packageName', label: 'Paketti', type: 'multiSelect', filters: packages, origFilters: origPackages }
]

export const hiddenFilters = (editors, pricingTypes, contentTeams, developmentStates, languages, vatBases, states, productTypes) => [
  { title: 'publicationDate', label: 'Julkaisupäivä', type: 'calendar' },
  { title: 'cancelDate', label: 'Peruutuspäivä', type: 'calendar' },
  { title: 'categoryId', label: 'Laji', type: 'singleSelect', filters: selectOptions.categoryIdOptions },
  { title: 'editor', label: 'Toimittaja', type: 'multiSelect', filters: editors },
  { title: 'rakennustietoPublic', label: 'Ratin julkaisu', type: 'singleSelect', filters: [{ value: '1', label: 'Kyllä' }, { value: '2', label: 'Ei' }] },
  { title: 'productPageCount', label: 'Sivumäärä', type: 'int' },
  { title: 'priceNet', label: 'Hinta netto', type: 'currency' },
  { title: 'priceVat', label: 'Hinta ALV', type: 'currency' },
  { title: 'vatPercent', label: 'ALV-kanta', type: 'singleSelect', filters: vatBases },
  { title: 'priceType', label: 'Hintatyyppi', type: 'singleSelect', filters: pricingTypes },
  { title: 'developmentState', label: 'Kehitystila', type: 'singleSelect', filters: developmentStates },
  { title: 'contentTeam', label: 'Sisältötiimi', type: 'multiSelect', filters: contentTeams },
  { title: 'series', label: 'Sarja', type: 'freeText' },
  { title: 'gtin', label: 'GTIN/ISBN', type: 'freeText' },
  { title: 'language', label: 'Kieli', type: 'multiSelect', filters: languages },
  { title: 'contentPartner', label: 'Kumppani', type: 'freeText' },
  { title: 'authors', label: 'Tekijät', type: 'freeText' },
  { title: 'cardPdfFile', label: 'PDF', type: 'freeText' },
  { title: 'productShortDescription', label: 'Lyhytkuvaus', type: 'freeText' },
  { title: 'stateId', label: 'Tila', type: 'singleSelect', filters: states },
  { title: 'committee', label: 'Toimikunta', type: 'freeText' },
  { title: 'cardTypeId', label: 'Korttityyppi', type: 'singleSelect', filters: productTypes }
]
