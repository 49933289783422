import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { getPublicationChannels, publishProduct } from '../services/publicationService'
import { getProductPackagesHeadingClasses } from '../services/productService'
import { NotificationManager } from 'react-notifications'
import ReactTooltip from 'react-tooltip'
import { UserContext } from '../utils/userUtils'
import { convertToDisplayDate } from '../utils/formUtils'

function ProductHeader (props) {
  const { productId, productData, productAttachments, productHeadings, productPackages, from, productStrapi, publicationState, setPublicationState } = props
  const { isWriter, isAdmin } = useContext(UserContext)
  const isWriterOrAdmin = isWriter || isAdmin
  const [mobileMenuVisible, toggleMobileMenuVisible] = useState(false)
  const [publicationChannels, setPublicationChannels] = useState([])
  const [headingClassesPackages, setHeadingClassesPackages] = useState([])
  const [readyForPublish, setReadyForPublish] = useState()
  const currentLocation = useLocation()
  const history = useHistory()

  const cardContentExists = () => {
    return (
      (productData?.publishType === 'PDF' && productData?.cardPdfFile) ||
      (productData?.publishType === 'Liitetiedosto' && productAttachments?.flatMap(attachment => attachment.items).find(attachment => attachment.mainContent)) ||
      (productData?.publishType === 'Rakenteinen' && productStrapi?.isPublished)
    )
  }

  const handlePublish = () => {
    // Currently only publishing to Tietoväylä is supported
    const targetChannel = publicationChannels.find(channel => channel.channel === 'Tietoväylä')
    publishProduct(productId, targetChannel.channelId).then(resp => {
      if (!resp.error) {
        setPublicationState(targetChannel)
        NotificationManager.success('Tuote julkaistu')
      } else {
        if (resp.error.code === 400) {
          NotificationManager.error('Virhe', 'Tuotteelta puuttuu tarvittavia tietoja', 5000)
        } else {
          NotificationManager.error('Virhe', 'Tapahtui odottamaton virhe', 5000)
        }
      }
    })
  }

  const getPackagesWithoutHeadingClasses = () => {
    return headingClassesPackages
      .filter((it) => it.packageId !== '44') // Ignore RT-kartoteek package
      .filter((it) => !productHeadings?.some((itx) => it.headingClasses.includes(itx.sortingCode)))
      .map((it) =>
        productPackages
          ?.flatMap((itx) => itx.items)
          .find((itx) => itx.sortingCode === it.packageId)?.name
      )
  }

  const handlePrint = () => {
    window.print()
  }

  const handleBackButton = () => {
    if (history.action === 'PUSH' && from === '/search') {
      history.goBack()
    } else {
      history.push('/search')
    }
  }

  useEffect(() => {
    getPublicationChannels().then(setPublicationChannels)
  }, [])

  useEffect(() => {
    if (productId) {
      getProductPackagesHeadingClasses(productId).then(setHeadingClassesPackages)
    }
  }, [productId])

  useEffect(() => {
    if (productData && productHeadings && productPackages && productAttachments) {
      if (productData.productId && productData.productName && productData.stateId && productData.publicationDate && productData.productDescription) {
        if (productData.categoryId.toLowerCase() === 'kortti') {
          if (
            cardContentExists() &&
            productPackages.length > 0 && (productData?.visibility === 'archive' || getPackagesWithoutHeadingClasses().length === 0) && productData.cardReferenceCode && productData.productPageCount &&
            (productData.cardNumber && productData.cardNumber !== '')
          ) {
            setReadyForPublish(true)
          }
        } else {
          setReadyForPublish(true)
        }
      }
    }
  }, [productData, productHeadings, productPackages, productAttachments, productStrapi])

  const MissingFieldsForPublishMessage = () => {
    const packagesWithoutHeadingClasses = getPackagesWithoutHeadingClasses()
    const CheckIcon = (props) => {
      const { condition } = props
      return condition
        ? <span className='icon is-color-accent-green'><i className='fas fa-check' /></span>
        : <span className='icon is-color-accent-red'><i className='fas fa-times' /></span>
    }
    return (
      <div>
        <div className='heading is-size-5'>Tuotteelta puuttuu tarvittavia tietoja</div>
        <div className='heading is-size-6'>Pakollisia tietoja ovat:</div>
        <ul>
          <li><CheckIcon condition={productData?.productName} /><p>Nimi</p></li>
          <li><CheckIcon condition={productData?.productId} /><p>Tuote-ID</p></li>
          <li><CheckIcon condition={productData?.publicationDate} /><p>Julkaisupäivä</p></li>
          <li><CheckIcon condition={productData?.stateId} /><p>Tila</p></li>
          <li><CheckIcon condition={productData?.productDescription} /><p>Kuvaus</p></li>
        </ul>
        {productData?.categoryId.toLowerCase() === 'kortti' &&
          <div>
            <div className='heading is-size-6 mt-2'>Tuotteen ollessa kortti myös seuraavat tiedot ovat pakollisia:</div>
            <ul>
              <li><CheckIcon condition={cardContentExists()} /><p>Julkaistu rakenteinen sisältö, PDF-kortti tai muu pääsisältö-liitetiedosto</p></li>
              <li><CheckIcon condition={productData?.cardReferenceCode} /><p>Korttitunnus</p></li>
              <li><CheckIcon condition={productData?.productPageCount} /><p>Sivumäärä</p></li>
              <li><CheckIcon condition={productData?.cardNumber} /><p>Kortin numero</p></li>
              <li><CheckIcon condition={productPackages?.length > 0} /><p>Vähintään yksi paketti</p></li>
              {productData?.visibility !== 'archive' &&
                <li>
                  <p>
                    <CheckIcon condition={packagesWithoutHeadingClasses.length === 0} />
                    Paketit ei sisällä vaadittua otsikkoa
                    <ul className='ml-6'>{packagesWithoutHeadingClasses.map((packageName, i) => <li key={i}>{packageName}</li>)}</ul>
                  </p>
                </li>}
            </ul>
          </div>}
      </div>
    )
  }

  return (
    <div className='ProductHeader is-hidden-print'>
      <nav className='custom-navbar navbar' role='navigation' aria-label='product navigation'>
        <div className='container'>
          <div className='navbar-brand'>
            <Link className='navbar-item' to='#' onClick={() => handleBackButton()}>
              <span className='icon is-color-primary'>
                <i className='fas fa-arrow-left' />
              </span>
              <div className='title is-6 is-color-primary pl-3'>
                Takaisin hakuun
              </div>
            </Link>
            <button
              className='navbar-burger'
              aria-label='menu'
              aria-expanded='false'
              data-target='productNavMenu'
              onClick={() => toggleMobileMenuVisible(!mobileMenuVisible)}
            >
              <div className='icon'>
                {mobileMenuVisible
                  ? <i className='fas fa-times fa-2x' />
                  : <i className='fas fa-wrench fa-2x' />}
              </div>
            </button>
          </div>
          <div className={mobileMenuVisible ? 'is-active navbar-menu' : 'navbar-menu'} id='productNavMenu'>
            <div className='navbar-end'>
              {publicationState &&
                <div className='navbar-item'>
                  <span className='is-inline-block' data-tip data-for='publicationStatus'>
                    <div className='title is-6 button is-juha-secondary-button is-disabled-button'>
                      <p>
                        {publicationChannels.find(it => it.channelId === publicationState.channelId)?.channel}, {convertToDisplayDate(publicationState.publishedTime)}, {publicationState.publishedUser}
                      </p>
                    </div>
                  </span>
                  <ReactTooltip id='publicationStatus' effect='solid' place='right'>
                    <span>Julkaisustatus</span>
                  </ReactTooltip>
                </div>}
              {isWriterOrAdmin &&
                <div className='navbar-item'>
                  <span className='is-inline-block' data-tip data-for='publication'>
                    <div
                      className={`title is-6 button is-juha-secondary-button ${publicationState ? 'is-disabled-button' : !readyForPublish && 'is-disabled-button'}`}
                      onClick={handlePublish}
                    >
                      <span className='icon'>
                        <i className='far fa-paper-plane' />
                      </span>
                      <p>
                        Julkaise
                      </p>
                    </div>
                  </span>
                  <ReactTooltip id='publication' effect='solid' place='bottom'>
                    <span>{publicationState ? <p>Tuote on jo julkaistu</p> : readyForPublish ? <p>Julkaise tuote Tietoväylään</p> : <MissingFieldsForPublishMessage />}</span>
                  </ReactTooltip>
                </div>}
              {isWriterOrAdmin &&
                <div className='navbar-item'>
                  <Link className='title is-6 button is-juha-secondary-button' to={currentLocation.pathname + '/clone'}>
                    <span className='icon'>
                      <i className='far fa-clone' />
                    </span>
                    <p>
                      Kopioi uudeksi tuotteeksi
                    </p>
                  </Link>
                </div>}
              <div className='navbar-item'>
                <div className='title is-6 button is-juha-secondary-button' onClick={handlePrint}>
                  <span className='icon'>
                    <i className='fas fa-print' />
                  </span>
                  <p>
                    Tulosta
                  </p>
                </div>
              </div>
              {isWriterOrAdmin &&
                <div className='navbar-item'>
                  <Link className='title is-6 button is-juha-secondary-button' to={currentLocation.pathname + '/edit'}>
                    <span className='icon'>
                      <i className='far fa-edit' />
                    </span>
                    <p>
                      Muokkaa
                    </p>
                  </Link>
                </div>}
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default ProductHeader
