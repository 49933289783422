import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { inputMaxLength, requiredText } from '../../utils/formUtils'
import { deleteEditor, editEditor } from '../../services/editorService'
import { NotificationManager } from 'react-notifications'

function EditorRow (props) {
  const { editor, fetchEditors } = props
  const [currentEditorData, setCurrentEditorData] = useState(editor)
  const [isEditing, setEditing] = useState()
  const { register, handleSubmit, formState: { errors }, reset } =
    useForm({ defaultValues: editor })

  const cancelEdit = () => {
    setEditing(false)
    reset(currentEditorData)
  }

  const saveEdit = data => {
    editEditor(currentEditorData.editorId, data).then(resp => {
      if (resp.status === 200) {
        NotificationManager.success('Toimittajan tiedot päivitetty')
        setCurrentEditorData(data)
        setEditing(false)
      } else {
        NotificationManager.error('Virhe', 'Toimittajan tietojen päivittäminen epäonnistui', 5000)
      }
    }).catch(error => {
      if (error?.response?.data?.error?.indexOf('Database query failed') > -1) {
        NotificationManager.error('Virhe', 'Toimittajan tietojen päivittäminen epäonnistui. ID on jo käytössä', 5000)
      } else {
        NotificationManager.error('Virhe', 'Toimittajan tietojen päivittäminen epäonnistui', 5000)
      }
    })
  }

  const removeEditor = () => {
    if (window.confirm(`Haluatko varmasti poistaa toimittajan ${currentEditorData.editor}?`)) {
      deleteEditor(currentEditorData.editorId).then(resp => {
        if (resp.status === 200) {
          NotificationManager.success('Toimittaja poistettu')
          fetchEditors()
        }
      }).catch(error => {
        if (error?.response?.data?.error?.indexOf('Database query failed') > -1) {
          NotificationManager.error('Virhe', 'Toimittajan poisto epäonnistui, toimittaja on mahdollisesti asetettuna toimittajaksi jollakin tuotteella', 5000)
        } else {
          NotificationManager.error('Virhe', 'Toimittajan poisto epäonnistui', 5000)
        }
      })
    }
  }

  return (
    <tr className='EditorRow'>
      {isEditing
        ? (
          <>
            <td>
              <input
                className='input'
                {...register('editor',
                  {
                    required: requiredText,
                    maxLength: inputMaxLength(50)
                  })}
              />
              {errors.editor && <div className='is-color-accent-red'>{errors.editor.message}</div>}
            </td>
            <td>
              <input
                className='input'
                {...register('editorId',
                  {
                    required: requiredText,
                    maxLength: inputMaxLength(4)
                  })}
              />
              {errors.editorId && <div className='is-color-accent-red'>{errors.editorId.message}</div>}
            </td>
            <td className='editButtons'>
              <button className='confirm-edit-item' onClick={handleSubmit(saveEdit)}><div className='icon'><i className='fas fa-check fa-lg' /></div></button>
              <button className='cancel-edit-item' onClick={() => cancelEdit()}><div className='icon'><i className='fas fa-times fa-lg' /></div></button>
            </td>
          </>
          )
        : (
          <>
            <td>
              <input
                readOnly
                className='input disabled-field'
                {...register('editor')}
              />
            </td>
            <td>
              <input
                readOnly
                className='input disabled-field'
                {...register('editorId')}
              />
            </td>
            <td className='editButtons'>
              <button className='edit-item' onClick={() => setEditing(!isEditing)}><div className='icon'><i className='fas fa-pen fa-lg' /></div></button>
              <button className='delete-item' onClick={() => removeEditor()}><div className='icon'><i className='fas fa-trash fa-lg' /></div></button>
            </td>
          </>
          )}
    </tr>
  )
}

export default EditorRow
