import React from 'react'

function CustomMultiValueLabel (props) {
  const { data } = props
  return (
    <div className='CustomMultiValueLabel'>
      <p>{data.label}</p>
    </div>
  )
}

export default CustomMultiValueLabel
