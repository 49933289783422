import React, { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import DatePicker, { registerLocale } from 'react-datepicker'
import fi from 'date-fns/locale/fi'
import { useHistory } from 'react-router-dom'
import BasicDataColumn from './BasicDataColumn'
import CardOrFormEditFields from './CardOrFormEditFields'
import SingleSelectColumn from './SingleSelectColumn'
import { inputMaxLength, parseFormData, requiredText, roundToTwoDecimals } from '../utils/formUtils'
import { postNewProduct, updateExistingProduct } from '../services/productService'
import PublicationEditFields from './PublicationEditFields'
import SingleSelectField from './SingleSelectField'
import {
  getAvailabilityOptions,
  getClassOptions,
  getContentTeamOptions,
  getDevelopmentStateOptions,
  getEditorOptions,
  getIndexTermOptions,
  getLanguageOptions,
  getPackageOptions,
  getPricingTypeOptions,
  getProductTypeOptions,
  getReferenceTypeOptions,
  getStateOptions,
  getTagOptions,
  getVatBaseOptions,
  getIPRightOptions,
  getStakeholderOptions, getPublishTypeOptions, getLiteratureHeadingOptions
} from '../services/selectOptionsService'
import { getPricingTables } from '../services/pricingTableService'
import { selectOptions } from '../model/formSelectOptions'
import MultiSelectColumn from './MultiSelectColumn'
import LinksEditForm from './LinksEditForm'
import AttachmentFilesForm from './AttachmentFilesForm'
import ReplacementsEditForm from './ReplacementsEditForm'
import { NotificationManager } from 'react-notifications'
import HeadingEditTree from './HeadingEditTree'
import IndexTermsEditForm from './IndexTermsEditForm'
import ReferencesEditForm from './ReferencesEditForm'
import {
  displayVisibility,
  handleFlatMap,
  mapHierarchicalItems, mapIPRights,
  mapReferences,
  mapReplacements, parseVatFromLabelText, searchPriorityOptions
} from '../utils/productUtils'
import WebServiceEditFields from './WebServiceEditFields'
import { EditSection } from './EditSection'
import { EditSubSection } from './EditSubSection'
import IPRightsEditForm from './IPRightsEditForm'
import InternalAttachmentFilesForm from './InternalAttachmentFilesForm'
import { RichTextEditor } from './RichTextEditor'
import LiteratureEditForm from './LiteratureEditForm'

registerLocale('fi', fi)

function EditProductForm (props) {
  const {
    productData,
    formRef,
    isSavingProduct,
    setIsSavingProduct,
    setIsEdited,
    setProductAttachmentsForEdit,
    setInternalProductAttachmentsForEdit
  } = props
  const history = useHistory()

  const attachmentFileProps = {}
  const internalAttachmentFileProps = {}

  const initialData = {
    priceNet: '',
    priceVat: '',
    stateOptions: [],
    attachmentFileProps: attachmentFileProps,
    internalAttachmentFileProps: internalAttachmentFileProps,
    createdTime: new Date(),
    updateTime: new Date(),
    // Multiselects have to be initialized as empty arrays
    packages: [],
    classes: [],
    contentTeams: [],
    editors: [],
    languages: [],
    ...productData
  }

  const {
    register,
    resetField,
    setValue,
    setError,
    clearErrors,
    control,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { errors }
  } =
    useForm({ defaultValues: initialData })

  const [selectedVatDecimal, setSelectedVatDecimal] = useState(1)
  const [priceNet, setPriceNet] = useState(initialData.priceNet)
  const [priceVat, setPriceVat] = useState(initialData.priceVAT)
  const [previousProductData, setPreviousProductData] = useState()
  const [stateOptions, setStateOptions] = useState([])
  const [availabilityOptions, setAvailabilityOptions] = useState([])
  const [developmentStateOptions, setDevelopmentStateOptions] = useState([])
  const [vatBaseOptions, setVatBaseOptions] = useState([])
  const [pricingTypeOptions, setPricingTypeOptions] = useState([])
  const [productTypeOptions, setProductTypeOptions] = useState([])
  const [publishTypeOptions, setPublishTypeOptions] = useState([])
  const [contentTeamOptions, setContentTeamOptions] = useState([])
  const [referenceTypeOptions, setReferenceTypeOptions] = useState([])
  const [literatureHeadingOptions, setLiteratureHeadingOptions] = useState([])
  const [packageOptions, setPackageOptions] = useState([])
  const [origPackageOptions, setOrigPackageOptions] = useState([])
  const [classOptions, setClassOptions] = useState([])
  const [origClassOptions, setOrigClassOptions] = useState([])
  const [editorOptions, setEditorOptions] = useState([])
  const [indexTermOptions, setIndexTermOptions] = useState([])
  const [languageOptions, setLanguageOptions] = useState([])
  const [tagOptions, setTagOptions] = useState([])
  const [origTagOptions, setOrigTagOptions] = useState([])
  const [oldCardPdfFile, setOldCardPdfFile] = useState()
  const [pricingTables, setPricingTables] = useState([])
  const [ipRightOptions, setIPRightOptions] = useState([])
  const [stakeholderOptions, setStakeholderOptions] = useState([])

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name) {
        setIsEdited(true)
      }
      if (name === 'vatBaseId') {
        const currentVat = vatBaseOptions.find(el => el.value === value.vatBaseId)
        const currentVatDecimal = parseVatFromLabelText(currentVat.label)
        setSelectedVatDecimal(currentVatDecimal)
        handlePriceChange(value.priceNet, 'net', currentVatDecimal)
      }
      if (name === 'productPageCount' || name === 'pricingTypeId') {
        if (value.pricingTypeId !== '0') {
          handlePricingChange(value)
        } else {
          clearErrors('priceNet')
          clearErrors('priceVat')
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, vatBaseOptions, pricingTables])

  useEffect(() => {
    if (JSON.stringify(productData) !== JSON.stringify(previousProductData)) {
      reset(initialData)
    }
    setPreviousProductData(productData)
    const currentVat = vatBaseOptions.find(el => el.value === productData.vatBaseId)
    if (currentVat) {
      const currentVatDecimal = parseVatFromLabelText(currentVat.label)
      setSelectedVatDecimal(currentVatDecimal)
    }
  }, [productData])

  const saveProduct = data => {
    setIsSavingProduct(true)
    if (productData?.productId) {
      updateExistingProduct(parseFormData(data)).then(resp => {
        handleProductSaveResponse(resp)
      })
    } else {
      // Make sure headings are set when cloning a product
      if (data.productHeadings && !data.headings) {
        data.headings = data.productHeadings.flatMap(heading => heading.items.map(item => ({ headingId: item.headingId })))
      }
      postNewProduct(parseFormData(data)).then(resp => {
        handleProductSaveResponse(resp)
      })
    }
  }

  const currentCategoryId = useWatch({
    control,
    name: 'categoryId',
    defaultValue: initialData.categoryId
  })

  const currentPublicationDate = useWatch({
    control,
    name: 'publicationDate',
    defaultValue: initialData.publicationDate
  })

  const currentCancelDate = useWatch({
    control,
    name: 'cancelDate',
    defaultValue: initialData.cancelDate
  })

  const fileContent = useWatch({
    control,
    name: 'fileContent',
    defaultValue: initialData.fileContent
  })

  const newPdfFileContent = useWatch({
    control,
    name: 'newPdfFileContent',
    defaultValue: null
  })

  const internalFileContent = useWatch({
    control,
    name: 'internalFileContent',
    defaultValue: initialData.internalFileContent
  })

  const pricingTypeId = useWatch({
    control,
    name: 'pricingTypeId',
    defaultValue: initialData.pricingTypeId
  })

  const handleProductSaveResponse = response => {
    if (!response.error) {
      history.push(`/product/${response.productId}`)
    } else {
      setIsSavingProduct(false)
      const reason = response?.error?.reason

      if (reason?.reasonCode === 'FILE_ALREADY_EXISTS') {
        NotificationManager.error(
          'Virhe',
          'Tuotteen tallennus epäonnistui, ' +
          (reason?.filename ? 'tiedosto "' + reason.filename + '" on jo olemassa.' : 'tiedosto on jo olemassa.'))
      } else {
        NotificationManager.error('Virhe', 'Tuotteen tallennus epäonnistui', 5000)
      }
    }
  }

  const handlePriceChange = (value, type, tempVatDecimal) => {
    if (isNaN(value) || value === '') {
      setValue('priceNet', '')
      setPriceNet('')
      setValue('priceVat', '')
      setPriceVat('')
    } else {
      let netValue, vatValue
      if (type === 'net') {
        netValue = value
        vatValue = netValue * (tempVatDecimal || selectedVatDecimal)
        vatValue = roundToTwoDecimals(vatValue)
      } else if (type === 'vat') {
        vatValue = value
        netValue = vatValue / (tempVatDecimal || selectedVatDecimal)
        netValue = roundToTwoDecimals(netValue)
      }
      setValue('priceNet', netValue)
      setPriceNet(netValue)
      setValue('priceVat', vatValue)
      setPriceVat(vatValue)
    }
  }

  const handleLinksChange = (updatedLinks) => {
    setValue('links', updatedLinks)
  }

  const handleProductAttachmentChange = (attachmentsForEdit) => {
    setValue('productAttachments', attachmentsForEdit)
    setProductAttachmentsForEdit(attachmentsForEdit)
  }

  const handleInternalProductAttachmentChange = (internalAttachmentsForEdit) => {
    setValue('internalAttachments', internalAttachmentsForEdit)
    setInternalProductAttachmentsForEdit(internalAttachmentsForEdit)
  }

  const handleReplacementsChange = (updatedReplacements) => {
    setValue('productReplacements', mapReplacements(updatedReplacements))
  }

  const handleHeadingCheck = (headings) => {
    setValue('headings', headings)
  }

  const handleIndexTermsChange = (updatedIndexTerms) => {
    setValue('indexTerms', updatedIndexTerms)
  }

  const handleReferencesChange = (updatedReferences) => {
    setValue('productReferences', mapReferences(updatedReferences))
  }

  const handleLiteratureChange = (updatedLiterature) => {
    setValue('productLiterature', updatedLiterature)
  }

  const handleIPRightsChange = (updatedIPRights) => {
    setValue('productIPRights', mapIPRights(updatedIPRights))
  }

  const handlePricingChange = (productData) => {
    const pricing = pricingTables.find((it) =>
      it.pricingTypeId === productData.pricingTypeId &&
      it.productPageCount === Number(productData.productPageCount)
    )
    if (pricing) {
      setValue('priceNet', pricing.priceNet)
      setPriceNet(pricing.priceNet)
      setValue('priceVat', pricing.priceVat)
      setPriceVat(pricing.priceVat)
      clearErrors('priceNet')
      clearErrors('priceVat')
    } else {
      setError('priceNet', {
        type: 'manual',
        message: 'Hinnottelu taulukko ei sisällä hinnoittelua sivumäärälle ja hintatyypille'
      })
      setError('priceVat', {
        type: 'manual',
        message: 'Hinnottelu taulukko ei sisällä hinnoittelua sivumäärälle ja hintatyypille'
      })
    }
  }

  const tagIncludedInTypeOfInputValue = (option, inputValue) => {
    const mathcingTypes = tagOptions?.filter(tagType => tagType.label?.toLowerCase().includes(inputValue.toLowerCase()))
    for (const type of mathcingTypes) {
      if (type.options.map(tag => tag.value).includes(option.value)) {
        return true
      }
    }
    return false
  }

  const tagsCustomFilter = (option, inputValue) => {
    return inputValue == null ||
      inputValue.length === 0 ||
      option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
      tagIncludedInTypeOfInputValue(option, inputValue)
  }
  const classesCustomFilter = (option, inputValue) => {
    return inputValue == null ||
      inputValue.length === 0 ||
      option.label.toLowerCase().includes(inputValue.toLowerCase())
  }

  useEffect(() => {
    register('priceNet')
    register('priceVat')
    // To make react-select components required you need to register them here and add option {required: requiredText}
    register('categoryId', { required: requiredText })
  })

  useEffect(() => {
    getStateOptions().then(setStateOptions)
    getAvailabilityOptions().then(setAvailabilityOptions)
    getDevelopmentStateOptions().then(setDevelopmentStateOptions)
    getVatBaseOptions().then(setVatBaseOptions)
    getPricingTypeOptions().then(setPricingTypeOptions)
    getProductTypeOptions().then(setProductTypeOptions)
    getPublishTypeOptions().then(setPublishTypeOptions)
    getContentTeamOptions().then(setContentTeamOptions)
    getReferenceTypeOptions().then(setReferenceTypeOptions)
    getLiteratureHeadingOptions().then(setLiteratureHeadingOptions)
    getIPRightOptions().then(setIPRightOptions)
    getStakeholderOptions().then(setStakeholderOptions)
    getPackageOptions().then((resp) => {
      setPackageOptions(mapHierarchicalItems(resp, 'packageTypeDescription', 'packages', 'packageId', 'packageName'))
      setOrigPackageOptions(handleFlatMap(resp, 'packages', 'packageId', 'packageName'))
    })
    getClassOptions().then((resp) => {
      setClassOptions(mapHierarchicalItems(resp, 'classification', 'classes', 'classId', 'class', 'classCode'))
      setOrigClassOptions(handleFlatMap(resp, 'classes', 'classId', 'class', 'classCode'))
    })
    getTagOptions().then((resp) => {
      setTagOptions(mapHierarchicalItems(resp, 'tagType', 'tags', 'tagId', 'tag', null, 'tagColor'))
      setOrigTagOptions(handleFlatMap(resp, 'tags', 'tagId', 'tag', null, 'tagColor'))
    })
    getEditorOptions().then((resp) => {
      const options = resp.sort((a, b) => a.label.localeCompare(b.label))
      setEditorOptions(options)
    })
    getIndexTermOptions().then(setIndexTermOptions)
    getLanguageOptions().then(setLanguageOptions)
    getPricingTables().then(setPricingTables)
  }, [])

  return (
    <div className='EditProductForm'>
      <form onSubmit={handleSubmit(saveProduct)} ref={formRef}>
        <section className='section product-main-details'>
          <div className='title is-4'>
            <input
              className='input title-input'
              title='Tuotteen nimi'
              placeholder='Tuotteen nimi'
              {...register('productName',
                {
                  maxLength: inputMaxLength(272)
                })}
            />
            <div className='is-color-accent-red'>{errors.productName?.message}</div>
          </div>
          <div className='columns'>
            <div className='column is-one-third'>
              <div className='columns is-mobile mb-0 is-align-items-center'>
                <div className='column is-4'>
                  <p className='label'>
                    Laji
                  </p>
                </div>
                <div className='column'>
                  <SingleSelectField
                    name='categoryId' options={selectOptions.categoryIdOptions}
                    error={errors.categoryId} control={control}
                  />
                </div>
              </div>
              <div className='columns is-mobile mb-0 is-align-items-center'>
                <div className='column is-4'>
                  <p className='label'>
                    Tuote-ID
                  </p>
                </div>
                <div className='column'>
                  <input
                    readOnly
                    className='input disabled-field'
                    {...register('productId')}
                  />
                  <div className='is-color-accent-red'>{errors.productId?.message}</div>
                </div>
              </div>
            </div>
            <div className='column is-1'>
              <p className='label is-inline-block'>Muistio</p>
            </div>
            <div className='column'>
              <textarea
                rows={3}
                className='textarea is-inline-block'
                {...register('productMemo',
                  {
                    maxLength: inputMaxLength(4000)
                  })}
              />
            </div>
          </div>
        </section>

        <EditSection isSavingProduct={isSavingProduct} title='Perustiedot'>
          <EditSubSection title='Julkaisutiedot'>
            <BasicDataColumn label='Julkaisupäivä' error={errors.publicationDate}>
              <Controller
                control={control}
                name='publicationDate'
                rules={{
                  validate: value => {
                    if (value > currentCancelDate) {
                      setError('cancelDate', {
                        type: 'manual',
                        message: 'Viimeinen voimassaolopäivä ei voi olla ennen julkaisupäivää'
                      })
                    } else {
                      clearErrors('cancelDate')
                    }
                  }
                }}
                render={({ field }) => (
                  <DatePicker
                    dateFormat='dd.MM.yyyy'
                    locale='fi'
                    className='input'
                    placeholderText='Valitse päivämäärä'
                    onChange={(e) => field.onChange(e || 0)}
                    selected={field.value}
                  />
                )}
              />
            </BasicDataColumn>
            <BasicDataColumn label='Viim. voimassaolopäivä' error={errors.cancelDate}>
              <Controller
                control={control}
                name='cancelDate'
                rules={{
                  validate: value => {
                    if (value && value < currentPublicationDate) {
                      return ('Viimeinen voimassaolopäivä ei voi olla ennen julkaisupäivää')
                    }
                  }
                }}
                render={({ field }) => (
                  <DatePicker
                    dateFormat='dd.MM.yyyy'
                    locale='fi'
                    className='input'
                    placeholderText='Valitse päivämäärä'
                    onChange={(e) => field.onChange(e || 0)}
                    selected={field.value}
                  />
                )}
              />
            </BasicDataColumn>
            <SingleSelectColumn
              label='Julkaisumuoto' name='publishTypeId' options={publishTypeOptions}
              error={errors.publishTypeId} control={control} isClearable
            />
            <BasicDataColumn label='Näkyvyys Tietoväylässä' error={errors.visibility}>
              <div className='mt-3'>{displayVisibility(getValues('visibility'))}</div>
            </BasicDataColumn>
            <SingleSelectColumn
              label='Tila' name='stateId' options={stateOptions}
              error={errors.stateId} control={control} isClearable
            />

            <BasicDataColumn label='Arkistotila'>
              <div className='mt-3'>{productData?.archiveState}</div>
            </BasicDataColumn>
            <SingleSelectColumn
              label='Saatavuus' name='availabilityId' options={availabilityOptions}
              error={errors.availabilityId} control={control}
            />
            <SingleSelectColumn
              label='Kehitystila' name='developmentStateId' options={developmentStateOptions}
              error={errors.developmentStateId} control={control}
            />
            <BasicDataColumn label='Toimikunta' error={errors.committee}>
              <input
                className='input'
                {...register('committee',
                  {
                    maxLength: inputMaxLength(255)
                  })}
              />
            </BasicDataColumn>
            <MultiSelectColumn
              label='Toimittajat' name='editors' options={editorOptions}
              error={errors.editors} control={control}
            />
            <MultiSelectColumn
              label='Paketit' name='packages' options={packageOptions}
              error={errors.packages} control={control} origOptions={origPackageOptions}
            />
            <HeadingEditTree
              headings={productData?.productHeadings}
              handleHeadingCheck={handleHeadingCheck}
            />
            <MultiSelectColumn
              label='Sisältötiimit' name='contentTeams' options={contentTeamOptions}
              error={errors.contentTeams} control={control}
            />
          </EditSubSection>
          <EditSubSection title='Hintatiedot'>
            <BasicDataColumn label='Sivumäärä' error={errors.productPageCount}>
              <input
                className='input'
                type='number'
                min='0'
                {...register('productPageCount')}
              />
            </BasicDataColumn>
            <SingleSelectColumn
              label='Hintatyyppi' name='pricingTypeId' options={pricingTypeOptions}
              error={errors.pricingTypeId} control={control} isClearable
            />
            <SingleSelectColumn
              label='Veroprosentti' name='vatBaseId' options={vatBaseOptions}
              error={errors.vatBaseId} control={control}
            />
            <BasicDataColumn label='Hinta (netto)' error={errors.priceNet}>
              <input
                className={`input ${pricingTypeId !== '0' && 'disabled-field'}`}
                readOnly={pricingTypeId !== '0'}
                type='number'
                step='.01'
                name='priceNet'
                value={priceNet}
                onChange={e => handlePriceChange(e.target.value, 'net')}
              />
            </BasicDataColumn>
            <BasicDataColumn label='Hinta (sis.alv)' error={errors.priceVat}>
              <input
                className={`input ${pricingTypeId !== '0' && 'disabled-field'}`}
                readOnly={pricingTypeId !== '0'}
                type='number'
                step='.01'
                name='priceVat'
                value={priceVat}
                onChange={e => handlePriceChange(e.target.value, 'vat')}
              />
            </BasicDataColumn>

            <BasicDataColumn label='Laskutus tuotekoodi' error={errors.erpProductCode}>
              <input
                className='input'
                {...register('erpProductCode',
                  {
                    maxLength: inputMaxLength(50)
                  })}
              />
            </BasicDataColumn>
            <BasicDataColumn label='GTIN / ISBN' error={errors.gtin}>
              <input
                className='input'
                {...register('gtin',
                  {
                    maxLength: inputMaxLength(40)
                  })}
              />
            </BasicDataColumn>
          </EditSubSection>

          <EditSubSection title='Yleiset'>
            <BasicDataColumn label='PDF-kortti' error={errors.cardPdfFile}>
              {(productData?.cardPdfFile && !oldCardPdfFile) &&
                <div>
                  <input
                    className='input'
                    readOnly
                    {...register('cardPdfFile',
                      {
                        maxLength: inputMaxLength(255)
                      })}
                  />
                  <input
                    type='button' value='poista' onClick={(e) => {
                      setValue('oldCardPdfFile', getValues('cardPdfFile'))
                      setOldCardPdfFile(getValues('cardPdfFile'))
                    }}
                  />
                  <input
                    className='is-hidden'
                    {...register('oldCardPdfFile',
                      {
                        maxLength: inputMaxLength(255)
                      })}
                  />
                </div>}

              {((!productData?.cardPdfFile || oldCardPdfFile) && !newPdfFileContent) &&

                <div>
                  <label htmlFor='newPdfFileContent' className='button is-juha-secondary-button'>Lataa tiedosto</label>
                  <input
                    className='is-hidden'
                    id='newPdfFileContent'
                    type='file'
                    {...register('newPdfFileContent')}
                  />
                </div>}

              {newPdfFileContent &&
                <div>
                  <input
                    className='input disabled-field'
                    readOnly
                    value={newPdfFileContent[0].name}
                    {...register('newCardPdfFile')}
                  />
                  <input
                    type='button' value='poista' onClick={(e) => setValue('newPdfFileContent', undefined)}
                  />
                </div>}
            </BasicDataColumn>
            <BasicDataColumn label='Luoja' error={errors.createdUserID}>
              <input
                className='input disabled-field'
                readOnly
                {...register('createdUserID')}
              />
            </BasicDataColumn>
            <BasicDataColumn label='Luontiaika' error={errors.createdTime}>
              <Controller
                control={control}
                name='createdTime'
                render={({ field }) => (
                  <DatePicker
                    dateFormat='dd.MM.yyyy'
                    readOnly
                    className='input disabled-field'
                    selected={field.value}
                  />
                )}
              />
            </BasicDataColumn>
            <BasicDataColumn label='Muuttaja' error={errors.updateUser}>
              <input
                className='input disabled-field'
                readOnly
                {...register('updateUser')}
              />
            </BasicDataColumn>
            <BasicDataColumn label='Muutosaika' error={errors.updateTime}>
              <Controller
                control={control}
                name='updateTime'
                render={({ field }) => (
                  <DatePicker
                    dateFormat='dd.MM.yyyy'
                    readOnly
                    className='input disabled-field'
                    selected={field.value}
                  />
                )}
              />
            </BasicDataColumn>
            <SingleSelectColumn
              label='Tuotetyyppi' name='productTypeId' options={productTypeOptions}
              error={errors.productTypeId} control={control} isClearable
            />
            <SingleSelectColumn
              label='Näkyvyys Rakennustieto GPT:ssä' name='searchPriority' options={searchPriorityOptions}
              error={errors.searchPriority} control={control} isClearable
            />
          </EditSubSection>
        </EditSection>

        <EditSection isSavingProduct={isSavingProduct} title='Lisätiedot'>
          {currentCategoryId &&
            <>
              {(currentCategoryId === 'kortti' || currentCategoryId === 'lomake') &&
                <CardOrFormEditFields register={register} errors={errors} control={control} watch={watch} />}
              {currentCategoryId === 'julkaisu' &&
                <PublicationEditFields register={register} errors={errors} control={control} />}
              {currentCategoryId === 'verkkopalvelu' &&
                <WebServiceEditFields register={register} errors={errors} control={control} />}
            </>}
        </EditSection>

        <EditSection title='Tuotekuvaukset'>
          <EditSubSection>
            <BasicDataColumn label='Kuvaus' error={errors.productDescription}>
              <RichTextEditor name='productDescription' control={control} />
            </BasicDataColumn>
            <BasicDataColumn label='Lyhytkuvaus' error={errors.productShortDescription}>
              <textarea
                className='textarea'
                {...register('productShortDescription',
                  {
                    maxLength: inputMaxLength(4000)
                  })}
              />
            </BasicDataColumn>
            <BasicDataColumn label='Metadata' error={errors.productMetadata}>
              <textarea
                className='textarea'
                {...register('productMetadata',
                  {
                    maxLength: inputMaxLength(4000)
                  })}
              />
            </BasicDataColumn>
          </EditSubSection>
        </EditSection>

        <EditSection title='Liitokset' isSavingProduct={isSavingProduct}>
          <EditSubSection>
            <ReplacementsEditForm
              handleReplacementsChange={handleReplacementsChange}
              replacements={productData?.replacements}
            />
            <ReferencesEditForm
              handleReferencesChange={handleReferencesChange} references={productData?.references}
              referenceTypeOptions={referenceTypeOptions}
            />
            <LiteratureEditForm
              literatureHeadingOptions={literatureHeadingOptions} literature={productData?.productLiterature}
              handleLiteratureChange={handleLiteratureChange}
            />
            <IndexTermsEditForm
              indexTerms={productData?.indexTerms} indexTermOptions={indexTermOptions}
              handleIndexTermsChange={handleIndexTermsChange}
            />
            <MultiSelectColumn
              label='Luokitukset' name='tags' options={tagOptions}
              error={errors.tags} control={control} origOptions={origTagOptions}
              customFilter={tagsCustomFilter} useCustomColors
            />
            <IPRightsEditForm
              handleIPRightsChange={handleIPRightsChange} ipRights={productData?.ipRights}
              ipRightOptions={ipRightOptions} stakeholderOptions={stakeholderOptions}
            />
            <MultiSelectColumn
              label='Nimikkeistöt' name='classes' options={classOptions}
              error={errors.classes} control={control} origOptions={origClassOptions}
              customFilter={classesCustomFilter}
            />
            <LinksEditForm links={productData?.links} handleLinksChange={handleLinksChange} />
            <MultiSelectColumn
              label='Kielet' name='languages' options={languageOptions}
              error={errors.languages} control={control}
            />

            <AttachmentFilesForm
              productAttachments={getValues('productAttachments') || productData?.productAttachments}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              fileContent={fileContent}
              setProductAttachmentsForEdit={handleProductAttachmentChange}
              resetField={resetField}
            />
            <InternalAttachmentFilesForm
              internalAttachments={getValues('internalAttachments') || productData?.internalAttachments}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              internalFileContent={internalFileContent}
              setInternalProductAttachmentsForEdit={handleInternalProductAttachmentChange}
              resetField={resetField}
            />
          </EditSubSection>
        </EditSection>
      </form>
    </div>
  )
}

export default EditProductForm
