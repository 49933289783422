import React from 'react'
import FixedColumn from './FixedColumn'

function FixedColumnItems (props) {
  const { title, columnClass, columnData } = props
  return (
    <FixedColumn title={title} columnClass={columnClass}>
      <div>
        {columnData?.map((el, index) =>
          <div key={index} className='column is-full data-value'>
            {el.url
              ? <a href={el.url}>{el.name}</a>
              : <div>{el.name}</div>}
          </div>
        )}
      </div>
    </FixedColumn>
  )
}

export default FixedColumnItems
