import Tree, { mutateTree } from '@atlaskit/tree'
import React, { createRef, useEffect, useRef, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import Select from 'react-select'
import { v4 } from 'uuid'
import FixedColumnWithGroupedItems from '../components/FixedColumnWithGroupedItems'
import { getHeadingClasses } from '../services/headingClassService'
import { getHeadingTreeByClassId } from '../services/headingService'
import { mapHeadingClass } from '../utils/headingUtils'
import { ClickOutside } from '../utils/searchUtils'

// FIXME: better check
const isHeadingClass = (item) => {
  return !item.headingId && !!item.headingClassId && !!item.headingClassTypeId
}

const treeObject = (item, id, rest) => {
  return {
    id,
    children: [],
    hasChildren: true,
    isExpanded: true,
    isChildrenLoading: false,
    data: item,
    ...rest
  }
}

const createHeadingTree = (data) => {
  const objectTree = {
    rootId: 'root',
    items: {
      root: {
        id: 'root',
        children: [],
        hasChildren: false,
        isExpanded: true,
        isChildrenLoading: false,
        data: { title: 'root' }
      },
      fake: {
        id: 'fake',
        children: [],
        hasChildren: false,
        isExpanded: true,
        isChildrenLoading: false,
        data: { title: 'fake' }
      }
    }
  }

  /* create tree from HeadingClasses */
  for (const item of data) {
    /* add HeadingClasses to root head in first level */
    const id = v4()
    objectTree.items[id] = treeObject(item, id, { children: ['fake'], hasChildren: true, isExpanded: false })
    /* first level of headings to root */
    objectTree.items.root.children.push(id)
  }
  return objectTree
}

const flatHeadings = (headings) => headings.flatMap(heading => heading.items.map(item => ({ ...item, headingClassId: heading.sortingCode })))

const HeadingEditTree = (props) => {
  const { headings = [], handleHeadingCheck } = props
  const [tree, setTree] = useState({ rootId: 'root', items: {} })
  const [currentlyOpenCheckedHeads, setCurrentlyOpenCheckedHeads] = useState([])
  const [selectedHeadings, setSelectedHeadings] = useState([])
  const [headingClasses, setHeadingClasses] = useState([])
  const [open, setOpen] = useState(false)
  const [headingFocus, setHeadingFocus] = useState(null)
  const headingClassRefs = []

  const wrapperRef = useRef('menu')
  ClickOutside(wrapperRef, () => { setOpen(false) })

  useEffect(() => {
    const flattedHeadings = flatHeadings(headings)
    setSelectedHeadings(flattedHeadings)
    setCurrentlyOpenCheckedHeads(flattedHeadings)
  }, [headings])

  useEffect(() => {
    getHeadingClasses().then(data => {
      setHeadingClasses(data)
      setTree(createHeadingTree(data))
    })
  }, [])

  useEffect(() => {
    for (const iterator of headingClassRefs) {
      if (headingFocus === iterator.current?.id) {
        iterator.current.focus()
        setHeadingFocus(null)
      }
    }
  }, [headingClassRefs])

  const onExpand = async (item) => {
    const mutatedTree = mutateTree(tree, item.id, { isChildrenLoading: true })
    setTree({ ...tree, ...mutatedTree })

    const currentItem = { ...mutatedTree.items[item.id] }

    try {
      const childRes = await getHeadingTreeByClassId(currentItem.data.headingClassId)
      const collectedObjects = {}
      const input = { id: v4(), ...childRes.data[0], childIds: [] }
      const processChild = (current) => {
        current.childIds = []
        for (const child of current.childHeadings) {
          child.id = v4()
          current.childIds.push(child.id)
          processChild(child)
        }
        collectedObjects[current.id] = { headingId: current.headingId, childIds: current.childIds, input: current }
      }

      for (const child of input.childHeadings) {
        child.id = v4()
        input.childIds.push(child.id)
        collectedObjects[input.id] = { headingId: input.headingId, childIds: input.childIds, input: input }
        processChild(child)
      }

      if (input.childHeadings === null || input.childHeadings.length === 0) {
        collectedObjects[input.id] = { headingId: input.headingId, childIds: [], input: input }
      }

      const newTreeItems = {}
      for (const [id, it] of Object.entries(collectedObjects)) {
        const item = treeObject(it.input, id)
        item.children.push(...it.childIds)
        newTreeItems[id] = item
      }

      if (isHeadingClass(currentItem.data)) {
        /* first parent is from headingClass so we add everything lastly at it. */
        newTreeItems[item.id] = treeObject(item.data, item.id)
        newTreeItems[item.id].children.push(input.id)
      }

      if (currentItem.isChildrenLoading) {
        const newTree = mutateTree(
          { ...mutatedTree, items: { ...mutatedTree.items, ...newTreeItems } },
          item.id,
          { isExpanded: true, isChildrenLoading: false }
        )

        // Use currentlyOpenCheckedHeads where local selected heads live. Else use initial headings from api.
        setCurrentlyOpenCheckedHeads(currentlyOpenCheckedHeads.length > 0 ? currentlyOpenCheckedHeads : flatHeadings(headings))
        setTree({ ...tree, ...newTree })
      }
    } catch (error) {
      setTree(mutateTree(tree, item.id, { isExpanded: false, isChildrenLoading: false, hasChildren: false, children: [] }))
      NotificationManager.error('Virhe', 'Ei otsikoita.')
    }
  }

  const onCollapse = (itemId) => {
    const updatedTree = mutateTree(tree, itemId, { isExpanded: false, isChildrenLoading: false })
    setTree({ ...tree, ...updatedTree })
  }

  const renderItem = ({ item, depth, onExpand, onCollapse, provided }) => {
    const hasChildren = item.children && item.children.length > 0

    const expand = (event) => {
      event.preventDefault()
      if (hasChildren) {
        if (item.isExpanded) onCollapse(item.id)
        else onExpand(item, depth)
      }
    }
    const handleCheckbox = () => {
      const unselectIfExists = selectedHeadings.find(it => it.headingId === item.data.headingId)
        ? selectedHeadings.filter(it => it.headingId !== item.data.headingId) // Remove selected heading from heading array.
        : [...selectedHeadings, { ...item.data, name: item.data.headingDescription }] // Add selected heading to heading array.

      setSelectedHeadings(unselectIfExists)

      const unselectVisibleIfExists = currentlyOpenCheckedHeads.find(it => it.headingId === item.data.headingId)
        ? currentlyOpenCheckedHeads.filter(it => it.headingId !== item.data.headingId) // Remove selected heading from heading array.
        : [...currentlyOpenCheckedHeads, { ...item.data, name: item.data.headingDescription }] // Add selected heading to heading array.

      // Only visual for user.
      setCurrentlyOpenCheckedHeads(unselectVisibleIfExists)

      handleHeadingCheck(unselectIfExists.map(it => ({ headingId: it.headingId })))
    }

    const headingClassOrHeadingRef = createRef()
    headingClassRefs.push(headingClassOrHeadingRef)
    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
      >
        <div className={`subtitle is-6 m-0 p-1 ${depth === 0 && 'has-background-juha-paler-blue'} is-flex is-justify-content-space-between`}>
          <div>
            {depth !== 0 && (
              <input
                className='mx-1'
                type='checkbox'
                checked={currentlyOpenCheckedHeads.some(it => it.headingId === item.data.headingId)}
                onClick={handleCheckbox}
                id={item.data?.headingDescription}
                ref={headingClassOrHeadingRef}
                isExpanded={item.isExpanded}
              />
            )}
            {hasChildren
              ? <span>{depth === 0 ? <strong>{item.data?.headingClassDescription}</strong> : `${item.data?.headingDescription}`}</span>
              : <span>{depth === 0 ? item.data?.headingClassDescription : `${item.data?.headingDescription}`}</span>}
          </div>
          <div>
            {hasChildren
              ? item.isExpanded
                  ? <i className='fa-fw fas fa-chevron-up is-clickable mx-1' onClick={expand} id={item.data?.headingClassDescription} ref={headingClassOrHeadingRef} data-isExpanded={item.isExpanded} />
                  : <i className='fa-fw fas fa-chevron-down is-clickable mx-1' onClick={expand} id={item.data?.headingClassDescription} ref={headingClassOrHeadingRef} data-isExpanded={item.isExpanded} />
              : null}
          </div>
        </div>
        {item.isChildrenLoading && <progress className='progress is-small' max='100'>5%</progress>}
      </div>
    )
  }

  const headingClassesWithCurrentlySelectedHeads = headingClasses.reduce((acc, cur) => {
    const matchedChildHeadings = currentlyOpenCheckedHeads
      .filter(it => it.headingClassId === cur.headingClassId)
      .map(it => ({ ...it, menuDescription: it.name }))

    if (cur.headingClassId && matchedChildHeadings.length > 0) {
      return {
        ...acc,
        [cur.headingClassId]: {
          ...mapHeadingClass({
            ...cur,
            headings: matchedChildHeadings
          })
        }
      }
    }
    return acc
  }, {})

  const handleFocus = (headingClass, heading) => {
    for (const iterator of headingClassRefs) {
      if (iterator.current?.id === headingClass.itemLabel) {
        iterator.current.dataset.isexpanded === 'false' && iterator.current.click() // click headingclass button that lazyLoads heading childs.
        setHeadingFocus(heading.name) // set heading. useEffect checks heading and flys to heading input to focus
      }
    }
  }

  return (
    <div className='HeadingEditTree column data-column is-4'>
      <div className='field'>
        <label className='label'>Otsikot</label>
        <div ref={wrapperRef} className={`dropdown ${open && 'is-active'} is-block `}>
          <div className='dropdown-trigger' onClick={() => setOpen(!open)}>
            <Select
              placeholder='Valitse otsikoita...'
              menuIsOpen={false}
              isSearchable={false}
            />
          </div>
          <div className='dropdown-menu is-relative' role='menu'>
            <div className='dropdown-content pb-0'>
              <div className='dropdown-item p-0 m-0'>
                <div className='responsive-grouped-items'>
                  <FixedColumnWithGroupedItems
                    columnData={Object.entries(headingClassesWithCurrentlySelectedHeads).map(e => e[1])}
                    handleFocus={handleFocus}
                  />
                </div>
                <Tree
                  tree={tree}
                  renderItem={renderItem}
                  onExpand={onExpand}
                  onCollapse={onCollapse}
                  offsetPerLevel={17}
                  isNestingEnabled={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeadingEditTree
