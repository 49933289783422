import React from 'react'

export const EditSection = ({ children, title, isSavingProduct }) => {
  return (
    <section className={`card m-4 mb-6 ${isSavingProduct && 'is-loading'}`}>
      <header className='card-header'>
        <div className='card-header-title title is-5'>
          <p>{title}</p>
        </div>
      </header>
      {children}
    </section>
  )
}

export default EditSection
