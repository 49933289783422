import React from 'react'

export const EditSubSection = ({ children, title }) => {
  return (
    <div>
      {title && (
        <header className='card-header has-no-border'>
          <div className='card-header-title title is-6'>
            <p>{title}</p>
          </div>
        </header>
      )}
      <div className='card-content is-paddingless'>
        <div className='columns is-multiline is-marginless data-columns'>
          {children}
        </div>
      </div>
    </div>
  )
}
