export const getColumnClass = (columns) => {
  let columnClass = 'is-one-'
  switch (columns) {
    case 1:
      columnClass = 'is-full'
      break
    case 2:
      columnClass = 'is-half'
      break
    case 3:
      columnClass += 'third'
      break
    case 4:
      columnClass += 'quarter'
      break
    case 5:
      columnClass += 'fifth'
      break
    case 6:
      columnClass = 'is-2'
      break
    default:
      columnClass = 'is-half'
  }
  return columnClass
}

export const getVisibilityClass = (value) => {
  switch (value) {
    case 'hidden':
      return 'is-color-accent-red'
    case 'archive':
      return 'is-color-accent-yellow'
    case 'visible':
    default:
  }
}
