import React, { useState, useEffect } from 'react'
import ProductSearchAsyncSelect from './ProductSearchAsyncSelect'

function ReplacementsEditForm (props) {
  const { handleReplacementsChange, replacements } = props
  const [replacementProducts, setReplacementProducts] = useState()
  const [origReplacementProducts, setOrigReplacementProducts] = useState()

  useEffect(() => {
    if (replacements !== origReplacementProducts) {
      setOrigReplacementProducts(replacements)
      setReplacementProducts(replacements)
    }
  }, [replacements])

  const changeReplacingProducts = (replacingProducts) => {
    const newReplacementProducts = {
      ...replacementProducts,
      replacingProducts: replacingProducts
    }
    setReplacementProducts(newReplacementProducts)
    handleReplacementsChange(newReplacementProducts)
  }

  const changeReplacedProducts = (replacedProducts) => {
    const newReplacementProducts = {
      ...replacementProducts,
      replacedProducts: replacedProducts
    }
    setReplacementProducts(newReplacementProducts)
    handleReplacementsChange(newReplacementProducts)
  }

  return (
    <div className='ReplacementsEditForm column data-column is-4'>
      <label className='label'>Korvaavuudet</label>
      <div>
        <label className='label small-label'>Korvaavat tuotteet</label>
        <ProductSearchAsyncSelect selectedProducts={replacementProducts?.replacingProducts} selectedProductsChange={changeReplacingProducts} />
        <label className='label small-label mt-2'>Korvatut tuotteet</label>
        <ProductSearchAsyncSelect selectedProducts={replacementProducts?.replacedProducts} selectedProductsChange={changeReplacedProducts} />
      </div>
    </div>
  )
}

export default ReplacementsEditForm
