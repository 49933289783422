import { convertToDisplayDate } from './formUtils'
import { getVisibilityClass } from './styleUtils'

const getProductBasicDetails = (productData) => {
  return [
    { title: 'Julkaisupäivä', value: convertToDisplayDate(productData.publicationDate) },
    { title: 'Viim. voimassaolopäivä', value: convertToDisplayDate(productData.cancelDate) },
    { title: 'Julkaisumuoto', value: productData.publishType },
    { title: 'Näkyvyys Tietoväylässä', value: displayVisibility(productData.visibility) },
    { title: 'Tila', value: productData.state },
    { title: 'Arkistotila', value: productData.archiveState },
    { title: 'Saatavuus', value: productData.availability },
    { title: 'Kehitystila', value: productData.developmentState },
    { title: 'Toimikunta', value: productData.committee }
  ]
}

const getProductPriceDetails = (productData) => {
  return [
    { title: 'Sivumäärä', value: productData.productPageCount },
    { title: 'Hintatyyppi', value: productData.pricingType },
    { title: 'Veroprosentti', value: `${productData.vatBase} (${productData.vatPercent}%)` },
    { title: 'Hinta (netto)', value: productData.priceNet },
    { title: 'Hinta (sis. alv)', value: productData.priceVAT },
    { title: 'Laskutus tuotekoodi', value: productData.erpProductCode },
    { title: 'GTIN / ISBN', value: productData.gtin }
  ]
}

const getProductGeneralDetails = (productData) => {
  return [
    { title: 'PDF-kortti', value: productData.cardPdfFile, url: `/api/files/${productData.cardPdfFile}` },
    { title: 'Luoja', value: productData.createdUser },
    { title: 'Luontiaika', value: convertToDisplayDate(productData.createdTime) },
    { title: 'Muuttaja', value: productData.updateUser },
    { title: 'Muutosaika', value: convertToDisplayDate(productData.updateTime) },
    { title: 'Tuotetyyppi', value: productData.productType },
    { title: 'Näkyvyys Rakennustieto GPT:ssä', value: localizeSearchPriority(productData.searchPriority) }
  ]
}

const getProductClassificationDetailsForCardOrForm = (productData) => {
  return [
    { title: 'Kortisto', value: productData.cardCollection },
    { title: 'Kortin numero', value: productData.cardNumber },
    { title: 'Korttityyppi', value: productData.cardType },
    { title: 'Luokka', value: productData.cardClass },
    { title: 'Talo80', value: productData.cardSfbCode },
    { title: 'SFS-koodi', value: productData.cardSfsCode },
    { title: 'Rinnakkaiset kortit', value: productData.additionalCardReferenceCodes },
    { title: 'Pääkortti', value: productData.primaryProductId, url: productData.primaryProductId },
    { title: 'Sarja', value: productData.series },
    { title: 'Korttihuomio', value: productData.cardNotification }
  ]
}

const getProductNamingDetailsForCardOrForm = (productData) => {
  return [
    { title: 'Nimi_SE', value: productData.productNameSE },
    { title: 'Nimi_UK', value: productData.productNameEN },
    { title: 'Tarvikenimet', value: productData.buildingProductNames }
  ]
}

const getProductGeneralDetailsForCardOrForm = (productData) => {
  return [
    { title: 'Valmistajat', value: productData.buildingProductManufacturers },
    { title: 'Kumppani', value: productData.contentPartner }
  ]
}

const getProductDetailsForPublication = (productData) => {
  return [
    { title: 'ISSN', value: productData.issn },
    { title: 'Tekijät', value: productData.authors },
    { title: 'Julkaisija', value: productData.publisher },
    { title: 'Kustantaja', value: productData.publishingCompany },
    { title: 'Julkaisuvuosi', value: productData.publishingYear },
    { title: 'Aakkosnimi', value: productData.sortingName },
    { title: 'Lisätietoja', value: productData.notes },
    { title: 'Painos', value: productData.edition },
    { title: 'Ratin oma julkaisu', value: productData.rakennustietoPublication },
    { title: 'Sarja', value: productData.series }
  ]
}

const getProductDetailsForWebService = (productData) => {
  return [
    /* Not yet implemented
    { title: 'Linkki 1 otsikko', value: productData.link1Heading },
    { title: 'Linkki 1 teksti', value: productData.link1Text },
    { title: 'Linkki 1 URL', value: productData.link1URL },
    { title: 'Linkki 2 otsikko', value: productData.link2Heading },
    { title: 'Linkki 2 teksti', value: productData.link2Text },
    { title: 'Linkki 2 URL', value: productData.link2URL },
    { title: 'Linkki 3 otsikko', value: productData.link3Heading },
    { title: 'Linkki 3 teksti', value: productData.link3Text },
    { title: 'Linkki 3 URL', value: productData.link3URL },
    { title: 'Videon upotuskoodi', value: productData.videoEmbedCode },
     */
    { title: 'Järjestysnumero', value: productData.serviceOrdinal },
    { title: 'Emopalvelun tuote-ID', value: productData.serviceParentProductId },
    { title: 'Palveluluettelossa', value: productData.serviceVisibility },
    { title: 'Polkunimi', value: productData.pathname },
    { title: 'Ryhmäotsikko', value: productData.serviceGroupHeading }
  ]
}

const getProductDescriptions = (productData) => {
  // TODO add field notificationText to instruct about the field?
  return [
    { title: 'Kuvaus', value: productData.productDescription },
    { title: 'Lyhytkuvaus', value: productData.productShortDescription },
    { title: 'Metadata', value: productData.productMetadata }
  ]
}

const getProductItemDetails = (item, url, sortingCode) => {
  return {
    name: `${item.cardReferenceCode ? item.cardReferenceCode : ''} ${item.productName}`,
    url: url,
    sortingCode: sortingCode,
    valueClass: getVisibilityClass(item.visibility)
  }
}

const getProductReferenceItem = (productData) => {
  const items = productData.productReferences.map(reference =>
    getProductItemDetails(reference, `/product/${reference.productId}`, reference.cardSortingCode))
  return {
    itemLabel: productData.referenceTypePlural,
    items: items.sort(sortItemsByCode)
  }
}

const getGroupedItem = (itemName, itemSorting) => {
  return {
    name: itemName,
    sortingCode: itemSorting
  }
}

const getFileItem = (item) => {
  return {
    fileId: item.fileId,
    name: item.fileDescription, // name field is used in ProductPage and description is more informative to user than filename
    filename: item.filename,
    ordinal: item.ordinal,
    fileDescription: item.fileDescription,
    fileTypeHeadingId: parseInt(item.fileTypeHeadingId),
    mainContent: item.mainContent,
    // TODO what is the format of file paths?
    url: `/api/files/${item.filename}`,
    state: '',
    deleted: false
  }
}
const getInternalFileItem = (item) => {
  return {
    fileId: item.fileId,
    name: item.fileDescription, // name field is used in ProductPage and description is more informative to user than filename
    filename: item.filename,
    ordinal: item.ordinal,
    fileDescription: item.fileDescription,
    fileTypeHeadingId: parseInt(item.fileTypeHeadingId),
    url: `/api/files/internal/${item.filename}`,
    state: '',
    deleted: false
  }
}

const sortItemsByCode = (a, b) => {
  return (a.sortingCode > b.sortingCode) ? 1 : -1
}

const mapReplacements = (replacements) => {
  const { replacedProducts, replacingProducts } = replacements
  return [
    ...replacedProducts.map(el => ({ replacedProductId: el.value })),
    ...replacingProducts.map(el => ({ replacingProductId: el.value }))
  ]
}

const mapReferences = (references) => {
  return references.flatMap(referenceType => {
    return referenceType.productReferences.map(reference => {
      return {
        targetProductId: reference.value,
        referenceTypeId: referenceType.referenceTypeId
      }
    })
  })
}

const mapIPRights = (ipRights) => {
  return ipRights.flatMap(ipRight => {
    return ipRight.stakeholders.map(stakeholder => {
      return {
        stakeholderId: stakeholder.value,
        ipRightId: ipRight.ipRightId
      }
    })
  })
}

const handleFlatMap = (hierarchicalArray, childrenIdentifier, childValue, childLabel, childLabelExtra, color) => {
  return hierarchicalArray.flatMap((b) => b[childrenIdentifier]?.map(c => {
    return {
      value: c[childValue],
      label: childLabelExtra ? `${c[childLabelExtra]} ${c[childLabel]}` : c[childLabel],
      color: color ? b[color] : undefined
    }
  }))
}

const mapHierarchicalItems = (items, parentLabel, childrenIdentifier, childValue, childLabel, childLabelExtra, color) => {
  return items.map((item) => {
    return {
      label: item[parentLabel],
      color: color ? item[color] : undefined,
      options: item[childrenIdentifier].map((child) => {
        return {
          value: child[childValue],
          label: childLabelExtra ? `${child[childLabelExtra]} ${child[childLabel]}` : child[childLabel],
          color: color ? item[color] : undefined
        }
      })
    }
  })
}

const sortByOrdinal = (items) => items.sort((a, b) => a.ordinal - b.ordinal)

const removeMarkedForDeletion = (items) => items.filter(item => item.deleted !== true)

const calcOrdinals = (items) => {
  const withOrdinal = sortByOrdinal(items.filter(item => item.ordinal !== null))
  const withoutOrdinal = items.filter(item => item.ordinal === null)
  const combinedItems = [...withOrdinal, ...withoutOrdinal]
  return combinedItems.map((item, index) => {
    return {
      ...item,
      ordinal: index + 1
    }
  })
}

const defaultCardReferenceCode = (product) => {
  return (product.cardCollection ? product.cardCollection + ' ' : '') + (product.cardNumber ? product.cardNumber : '')
}

const displayVisibility = (visibility) => {
  const className = getVisibilityClass(visibility)
  const localizedName = localizeVisibility(visibility)

  return (<span className={className}>{localizedName}</span>)
}

const localizeVisibility = (visibility) => {
  switch (visibility) {
    case 'archive':
      return 'Arkistoitu'
    case 'hidden':
      return 'Ei näkyvillä'
    case 'visible':
      return 'Näkyvillä'
    default:
      return visibility
  }
}

const searchPriorityOptions = [
  { value: 'priority', label: 'Nostettu näkyvyys' },
  { value: 'prevent', label: 'Ei haettava' }
]
const localizeSearchPriority = (searchPriority) => {
  return searchPriorityOptions
    .find(it => it.value === searchPriority?.toLowerCase())?.label ?? ''
}

const parseVatFromLabelText = (vatLabelText) => {
  // Label text should be in format "Muut tuotteet (25.5%)"
  const regex = /(\d+\.?\d?%)/
  const currentVatPercent = vatLabelText.match(regex)[0].replace('%', '')
  return currentVatPercent / 100 + 1
}

export {
  getProductReferenceItem,
  getProductDetailsForWebService,
  getProductDetailsForPublication,
  getProductNamingDetailsForCardOrForm,
  getProductGeneralDetailsForCardOrForm,
  getProductClassificationDetailsForCardOrForm,
  getProductBasicDetails,
  getProductPriceDetails,
  getProductGeneralDetails,
  getProductDescriptions,
  getProductItemDetails,
  getGroupedItem,
  sortItemsByCode,
  getFileItem,
  getInternalFileItem,
  mapReplacements,
  mapReferences,
  mapIPRights,
  handleFlatMap,
  mapHierarchicalItems,
  defaultCardReferenceCode,
  sortByOrdinal,
  removeMarkedForDeletion,
  calcOrdinals,
  displayVisibility,
  searchPriorityOptions,
  parseVatFromLabelText
}
