import React from 'react'

const AttachmentFileItem = (props) => {
  const { item, openModalForEdit, markForDelete, dnd } = props

  const openModal = () => {
    openModalForEdit(item)
  }

  const markForDeletion = () => {
    markForDelete(item)
  }

  return (
    <div className='is-flex is-justify-content-space-between'>
      <div>
        <span {...dnd} className='icon'>
          <i className='fas fa-grip-vertical' />
        </span>
        {item.mainContent &&
          <span className='icon'><i className='fas fa-angle-right' /></span>}
        <span>{item.fileDescription}</span>
      </div>
      <div className='is-inline-flex'>
        <span className='icon'>
          <i className='fas fa-times' onClick={markForDeletion} />
        </span>|
        <span className='icon'>
          <i className='fas fa-pen' onClick={openModal} />
        </span>
      </div>
    </div>
  )
}
export default AttachmentFileItem
