import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export function useQuery () {
  return new URLSearchParams(useLocation().search)
}

export function formatSearchResult (searchResult) {
  if (!searchResult?.data) {
    return []
  }

  return searchResult.data.result.map(product => {
    return {
      ...product,
      productId: product.productId,
      cardReferenceCode: product.cardReferenceCode,
      name: product.productName,
      visibility: product.visibility,
      productTypes: product.productType,
      packages: product.packageNames,
      publicationDate: product.publicationDate,
      cancelDate: product.cancelDate
    }
  })
}

/**
 * This Hook can be used for detecting clicks outside the Opened Menu
 */
export function ClickOutside (ref, onClickOutside) {
  useEffect(() => {
    /**
     * Invoke Function onClick outside of element
     */
    function handleClickOutside (event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, onClickOutside])
}

export function sortDateTime (rowA, rowB, columnId) {
  const dateTimeA = rowA.original[columnId]
  const dateTimeB = rowB.original[columnId]
  if (dateTimeA && dateTimeA instanceof Date && dateTimeB && dateTimeB instanceof Date) {
    const timeA = dateTimeA.getTime()
    const timeB = dateTimeB.getTime()
    return timeA === timeB ? 0 : timeA > timeB ? 1 : -1
  } else {
    return -1
  }
}
