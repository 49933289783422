
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

const getPricingTables = async () => {
  const resp = await axios.get(`${API_URL}/pricingTables`)
  return resp.data
}

export {
  getPricingTables
}
