const searchResultsLoadingData = [
  { productId: '', cardReferenceCode: '', name: '', visibility: '', productTypes: '', packages: '' },
  { productId: '', cardReferenceCode: '', name: '', visibility: '', productTypes: '', packages: '' },
  { productId: '', cardReferenceCode: '', name: '', visibility: '', productTypes: '', packages: '' },
  { productId: '', cardReferenceCode: '', name: '', visibility: '', productTypes: '', packages: '' },
  { productId: '', cardReferenceCode: '', name: '', visibility: '', productTypes: '', packages: '' },
  { productId: '', cardReferenceCode: '', name: '', visibility: '', productTypes: '', packages: '' }
]

export default searchResultsLoadingData
