import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useLocation, useHistory } from 'react-router-dom'
import DatePicker, { registerLocale } from 'react-datepicker'
import fi from 'date-fns/locale/fi'
import { useQuery } from '../utils/searchUtils'
import {
  deleteCalendarParams,
  parseCalendarParams,
  setCalendarParams,
  validateCalendarParams
} from '../utils/calendarUtils'
registerLocale('fi', fi)

function SearchFilterField (props) {
  const { label, title, type, filters, origFilters } = props.options
  const location = useLocation()
  const history = useHistory()
  const query = useQuery()
  const [appliedFilters, setAppliedFilters] = useState({
    calendar: undefined,
    multiSelect: [],
    singleSelect: '',
    freeText: '',
    int: '',
    currency: ''
  }[type])

  const [currentUrlQueryFilters, setCurrentUrlQueryFilters] = useState()
  const [dateControl, setDateControl] = useState('exact')
  const [appliedSecondDate, setAppliedSecondDate] = useState(undefined)

  const handleDateControlChange = (value) => {
    setDateControl(value)
    if (appliedFilters || (appliedSecondDate && value === 'range')) {
      const firstDate = appliedFilters?.toLocaleDateString('en-GB').replaceAll('/', '.')
      const secondDate = appliedSecondDate?.toLocaleDateString('en-GB').replaceAll('/', '.')
      switch (value) {
        case 'from':
          query.set(title, firstDate + '<')
          break
        case 'to':
          query.set(title, firstDate + '>')
          break
        case 'range':
          if (firstDate && secondDate) {
            query.set(title, firstDate + '-' + secondDate)
          } else {
            query.delete(title)
          }
          break
        default:
          query.set(title, firstDate)
      }
    } else {
      query.delete(title)
    }
    setCurrentUrlQueryFilters(query.get(title))
    history.push(location.pathname + '?' + decodeURIComponent(query.toString()))
  }

  const parseAndSetMultiSelectParams = (queryParams) => {
    if (queryParams && queryParams !== currentUrlQueryFilters) {
      setCurrentUrlQueryFilters(queryParams)
      const filterArray = queryParams.split(',')
      setAppliedFilters(filterArray)
    } else if (!queryParams && appliedFilters.length > 0) {
      setCurrentUrlQueryFilters(queryParams)
      setAppliedFilters([])
    }
  }

  const parseAndSetCalendarParams = (queryParams) => {
    if (queryParams && queryParams !== currentUrlQueryFilters) {
      setCurrentUrlQueryFilters(queryParams)
      const calendarParams = parseCalendarParams(queryParams)
      const { firstDate, secondDate, queryDateControl } = calendarParams
      const isValid = validateCalendarParams(firstDate, secondDate, queryDateControl)
      if (isValid) {
        setCalendarParams(firstDate, secondDate, queryDateControl, setAppliedFilters, setAppliedSecondDate, setDateControl)
      } else {
        deleteCalendarParams(title, setAppliedFilters, setAppliedSecondDate, setDateControl, query, history, location)
      }
    } else if (!queryParams && currentUrlQueryFilters) {
      setCurrentUrlQueryFilters(queryParams)
      setAppliedFilters(undefined)
      setAppliedSecondDate(undefined)
    }
  }

  const parseAndSetNumericParams = (queryParams) => {
    if (queryParams && queryParams !== currentUrlQueryFilters) {
      setCurrentUrlQueryFilters(queryParams)
      if (!isNaN(queryParams)) {
        setAppliedFilters(queryParams)
      } else {
        query.delete(title)
        setAppliedFilters('')
        history.push(location.pathname + '?' + decodeURIComponent(query.toString()))
      }
    } else if (!queryParams && appliedFilters !== '') {
      setCurrentUrlQueryFilters(queryParams)
      setAppliedFilters('')
    }
  }

  const parseAndSetStringParams = (queryParams) => {
    if (queryParams && queryParams !== currentUrlQueryFilters) {
      setCurrentUrlQueryFilters(queryParams)
      setAppliedFilters(queryParams)
    } else if (!queryParams && appliedFilters !== '') {
      setCurrentUrlQueryFilters(queryParams)
      setAppliedFilters('')
    }
  }

  /*
   * Get filter value from url and set it to correct filter-field
   * Used when page is refreshed/when a direct link including filters to search page is used
   * Also used to clear all filters
   */
  const parseAndSetQueryParams = () => {
    const queryParams = query.get(title)

    switch (type) {
      case 'multiSelect':
        parseAndSetMultiSelectParams(queryParams)
        break

      case 'calendar':
        parseAndSetCalendarParams(queryParams)
        break

      case 'int':
      case 'currency':
        parseAndSetNumericParams(queryParams)
        break

      default:
        parseAndSetStringParams(queryParams)
    }
  }

  const onChange = (e, dateType) => {
    query.delete('query')
    let params = ''

    switch (type) {
      case 'multiSelect':
        if (e.length) {
          params = e.join()
        }
        break

      case 'calendar':
        if (e && dateType === 'firstDate') {
          const date = e.toLocaleDateString('en-GB').replaceAll('/', '.')
          switch (dateControl) {
            case 'from':
              params = date + '<'
              break
            case 'to':
              params = date + '>'
              break
            case 'range':
              if (appliedSecondDate) {
                params = date + '-' + appliedSecondDate.toLocaleDateString('en-GB').replaceAll('/', '.')
              }
              break
            default:
              params = date
          }
        } else if (e && dateType === 'secondDate' && appliedFilters) {
          const date = e.toLocaleDateString('en-GB').replaceAll('/', '.')
          params = appliedFilters.toLocaleDateString('en-GB').replaceAll('/', '.') + '-' + date
        }
        break

      default:
        if (e) {
          params = e
        }
    }

    if (params) {
      query.set(title, params)
      setCurrentUrlQueryFilters(params)
    } else {
      query.delete(title)
      setCurrentUrlQueryFilters('')
    }
    if (dateType === 'secondDate') {
      setAppliedSecondDate(e)
    } else {
      setAppliedFilters(e)
    }
    history.push(location.pathname + '?' + decodeURIComponent(query.toString()))
  }

  const handleMultiValue = () => {
    if (origFilters) {
      return origFilters.filter(c => appliedFilters.includes(c.value))
    } else {
      return filters.filter(c => appliedFilters.includes(c.value))
    }
  }

  // Update filters to match url query params everytime query params (=location.search) changes
  useEffect(() => {
    parseAndSetQueryParams()
    // eslint-disable-next-line
  }, [location.search])

  return (
    <div className='SearchFilterField column is-4-widescreen is-6-tablet is-relative'>
      <div className='pb-1 is-flex is-justify-content-space-between'>
        <p>{label}</p>
        {type === 'calendar' &&
          <select
            className='select date-control' value={dateControl}
            onChange={e => handleDateControlChange(e.target.value)}
          >
            <option value='exact'>Täsmä</option>
            <option value='from'>Alkaen</option>
            <option value='to'>Päättyen</option>
            <option value='range'>Väliltä</option>
          </select>}
      </div>
      <div className=''>
        {type === 'multiSelect' &&
          <Select
            className='react-select'
            placeholder={<div className='react-select-placeholder'>Valitse...</div>}
            noOptionsMessage={() => 'Ei vaihtoehtoja'}
            options={filters}
            onChange={e => onChange(e.map(c => c.value))}
            value={handleMultiValue()}
            isMulti
            isClearable
          />}

        {type === 'singleSelect' &&
          <Select
            className='react-select'
            placeholder={<div className='react-select-placeholder'>Valitse...</div>}
            noOptionsMessage={() => 'Ei vaihtoehtoja'}
            options={filters}
            onChange={e => {
              if (e) {
                onChange(e.value)
              } else {
                onChange('')
              }
            }}
            value={filters.filter(c => c.value === appliedFilters)}
            isClearable
          />}

        {type === 'freeText' &&
          <input
            className='input'
            value={appliedFilters}
            onChange={e => onChange(e.target.value)}
          />}

        {type === 'calendar' &&
          <div className='is-flex'>
            <DatePicker
              dateFormat='dd.MM.yyyy'
              locale='fi'
              className='input'
              placeholderText='Valitse päivämäärä'
              onChange={(e) => onChange(e, 'firstDate')}
              selected={appliedFilters}
            />
            {dateControl === 'range' &&
              <DatePicker
                dateFormat='dd.MM.yyyy'
                locale='fi'
                className='input ml-1'
                placeholderText='Valitse päivämäärä'
                onChange={(e) => onChange(e, 'secondDate')}
                selected={appliedSecondDate}
              />}
          </div>}
        {type === 'currency' &&
          <input
            type='number'
            step='0.1'
            min='0'
            className='input'
            value={appliedFilters}
            onChange={e => onChange(e.target.value)}
          />}

        {type === 'int' &&
          <input
            type='number'
            step='1'
            min='0'
            className='input'
            value={appliedFilters}
            onChange={e => onChange(e.target.value)}
          />}
      </div>
    </div>
  )
}

export default SearchFilterField
