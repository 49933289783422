import React from 'react'
import { initStrapi } from '../services/productService'
import { NotificationManager } from 'react-notifications'

function OpenCMSButton (props) {
  const { productStrapi, productData, setProductStrapi, className } = props

  const handleInitStrapi = () => {
    initStrapi(productData?.productId, productData?.cardReferenceCode, productData?.productName).then(res => {
      if (!res.error) {
        setProductStrapi(res.data)
      } else {
        if (res.error.code === 400) {
          NotificationManager.error('Virhe', 'Tuotteelta puuttuu tarvittavia tietoja', 5000)
        } else {
          NotificationManager.error('Virhe', 'Tapahtui odottamaton virhe', 5000)
        }
      }
    })
  }

  return (
    <div className={className}>
      {productStrapi && !productStrapi.id && (
        <button
          className='button is-small is-juha-secondary-button'
          onClick={() => handleInitStrapi()}
        >
          Alusta CMS
        </button>
      )}
      {productStrapi && productStrapi.adminUrl && (
        <a
          href={productStrapi.adminUrl}
          className='button is-small is-juha-secondary-button'
          target='_blank' rel='noreferrer'
        ><span className='icon' style={{ 'margin-right': '0.15em' }}><i className={'fas fa-circle ' + (productStrapi.isPublished ? 'is-color-accent-green' : 'is-color-accent-red')} /></span>Avaa CMS
        </a>
      )}
    </div>
  )
}

export default OpenCMSButton
