import React from 'react'
import BasicDataColumn from './BasicDataColumn'
import SingleSelectColumn from './SingleSelectColumn'
import { inputMaxLength } from '../utils/formUtils'

const InternalAttachmentFileUploadFields = (props) => {
  const { register, control, errors, internalFileContent, fileTypeHeadingOptions, getValues } = props
  return (
    <div>
      <SingleSelectColumn
        width='full' label='Otsikko' name='internalAttachmentFileProps.fileTypeHeadingId' options={fileTypeHeadingOptions}
        error={errors.state} control={control} isClearable
      />
      <BasicDataColumn width='full' label='Tiedoston kuvaus' error={errors.fileDescription}>
        <textarea
          className='input'
          {...register('internalAttachmentFileProps.fileDescription',
            {
              maxLength: inputMaxLength(255)
            })}
        />
      </BasicDataColumn>

      {(!getValues('internalAttachmentFileProps.name')) &&
        <BasicDataColumn width='full' label='Lataa Liitetiedosto' error={errors.internalFileContent}>
          <div>
            <label htmlFor='internal-files' className='button is-juha-secondary-button'>Lataa tiedosto</label>
            <input
              className='is-hidden'
              id='internal-files'
              type='file'
              {...register('internalFileContent')}
            />
          </div>
        </BasicDataColumn>}

      {(getValues('internalAttachmentFileProps.name')) &&
        <BasicDataColumn width='full' label='Tiedosto' error={errors.internalFileContent}>
          <input
            className='input disabled-field'
            readOnly
            {...register('internalAttachmentFileProps.name')}
          />
        </BasicDataColumn>}

      {(internalFileContent && !getValues('internalAttachmentFileProps.name')) &&
        <BasicDataColumn width='full' label='Tiedosto' error={errors.internalFileContent}>
          <input
            className='input disabled-field'
            readOnly
            value={internalFileContent && internalFileContent[0].name}
          />
        </BasicDataColumn>}

      <input
        className='is-hidden'
        {...register('internalAttachmentFileProps.fileId',
          {
            maxLength: inputMaxLength(255)
          })}
      />
    </div>
  )
}
export default InternalAttachmentFileUploadFields
