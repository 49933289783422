import React, { useState, useEffect } from 'react'
import SingleLinkItem from './SingleLinkItem'

function LinksEditForm (props) {
  const { links, handleLinksChange } = props
  const [visibleLinks, setVisibleLinks] = useState([])
  const [uniqSortingCode, setUniqSortingCode] = useState()

  useEffect(() => {
    if (links) {
      setVisibleLinks([...links])
      setUniqSortingCode(parseInt(links[links.length - 1]?.sortingCode) + 1000)
    }
  }, [links])

  const updateLinks = (updatedLinks) => {
    setVisibleLinks([...updatedLinks])
    handleLinksChange(updatedLinks)
  }

  const handleAddNewLink = (e) => {
    e.preventDefault()
    visibleLinks.push({ urlDescription: '', url: '', sortingCode: uniqSortingCode })
    setUniqSortingCode(uniqSortingCode + 1000)
    updateLinks(visibleLinks)
  }

  const handleDeleteLink = (index, e) => {
    e.preventDefault()
    visibleLinks.splice(index, 1)
    updateLinks(visibleLinks)
  }

  const onLinkChange = (linkItem, index) => {
    visibleLinks[index] = linkItem
    updateLinks(visibleLinks)
  }

  return (
    <div className='LinksEditForm column data-column is-4'>
      <label className='label'>Linkit</label>
      {visibleLinks?.map((linkItem, index) => {
        return (
          <div key={linkItem.sortingCode}>
            <SingleLinkItem index={index} linkItem={linkItem} handleDeleteLink={handleDeleteLink} onLinkChange={onLinkChange} />
            <div className='separator pb-2'>
              <div className='line has-background-juha-blue-5' />
            </div>
          </div>
        )
      })}
      <button className='title is-6 button is-juha-secondary-button' onClick={handleAddNewLink}>
        <span className='icon'>
          <i className='fas fa-plus' />
        </span>
        <p>
          Lisää uusi linkki
        </p>
      </button>
    </div>
  )
}

export default LinksEditForm
