/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ProductHeader from '../components/ProductHeader'
import DataBoxBoxes from '../components/DataBoxBoxes'
import DataBox from '../components/DataBox'
import DataBoxFixedColumns from '../components/DataBoxFixedColumns'
import FixedColumnItems from '../components/FixedColumnItems'
import OpenCMSButton from '../components/OpenCMSButton'
import {
  getProduct,
  getProductAttachments,
  getProductClasses,
  getProductEditors,
  getProductHeadings,
  getProductIndexTerms,
  getProductLanguages,
  getProductLinks,
  getProductPackages,
  getProductReferences,
  getProductReplacements,
  getProductTeams,
  getProductTags,
  getProductIPRights,
  getInternalProductAttachments,
  getProductStrapi, initStrapi, getProductLiterature
} from '../services/productService'
import FixedColumnWithGroupedItems from '../components/FixedColumnWithGroupedItems'
import { NotificationManager } from 'react-notifications'
import { getPublicationState } from '../services/publicationService'

function ProductPage (props) {
  const { productId } = props.match.params
  const history = useHistory()
  const [productData, setProductData] = useState({})
  const [productReplacingData, setProductReplacingData] = useState([])
  const [productStrapi, setProductStrapi] = useState(undefined)
  const [productReferences, setProductReferences] = useState([])
  const [productLinks, setProductLinks] = useState([])
  const [productPackages, setProductPackages] = useState([])
  const [productHeadings, setProductHeadings] = useState([])
  const [productClasses, setProductClasses] = useState([])
  const [contentTeams, setContentTeams] = useState([])
  const [editors, setEditors] = useState([])
  const [indexTerms, setIndexTerms] = useState([])
  const [languages, setLanguages] = useState([])
  const [attachments, setAttachments] = useState([])
  const [internalAttachments, setInternalAttachments] = useState([])
  const [tags, setTags] = useState([])
  const [ipRights, setIPRights] = useState([])
  const [compactView, setCompactView] = useState(false)
  const [publicationState, setPublicationState] = useState(false)
  const [productLiterature, setProductLiterature] = useState([])

  const [displayCompactHeader, setDisplayCompactHeader] = useState(false)
  const productDetailsRef = useRef('')

  useEffect(() => {
    getProduct(productId).then(data => {
      if (!data.error) {
        setProductData(data)
      } else if (data.error === 404) {
        NotificationManager.error('Virhe', 'Tuotetta ei löytynyt', 5000)
        history.push('/dashboard')
      } else {
        NotificationManager.error('Virhe', 'Tapahtui odottamaton virhe', 5000)
        history.push('/dashboard')
      }
    })
    getPublicationState(productId).then(resp => {
      const published = resp.channels?.find(el => el.channel === 'Tietoväylä')
      published && setPublicationState(published)
    })
    getProductStrapi(productId).then(data => {
      setProductStrapi(data)
    })
    getProductReplacements(productId).then(data => {
      setProductReplacingData(data)
    })
    getProductReferences(productId).then(data => {
      setProductReferences(data)
    })
    getProductLinks(productId).then(data => {
      setProductLinks([...data])
    })
    getProductLiterature(productId).then(data => {
      const headings = []
      data.forEach(i => {
        if (!headings.find(h => h === i.literatureHeading)) {
          headings.push(i.literatureHeading)
        }
      })
      const elems = headings.map(h => {
        const items = data.filter(i => i.literatureHeading === h)
        return { itemLabel: h, items: items.map(i => ({ ...i, name: i.urlDescription })) }
      })
      setProductLiterature(elems)
    })
    getProductPackages(productId).then(data => {
      setProductPackages(data)
    })
    getProductClasses(productId).then(data => {
      setProductClasses(data)
    })
    getProductHeadings(productId).then(data => {
      setProductHeadings(data)
    })
    getProductTeams(productId).then(data => {
      setContentTeams(data)
    })
    getProductEditors(productId).then(data => {
      setEditors(data)
    })
    getProductIndexTerms(productId).then(data => {
      setIndexTerms(data)
    })
    getProductLanguages(productId).then(data => {
      setLanguages(data)
    })
    getProductAttachments(productId).then(data => {
      setAttachments(data)
    })
    getInternalProductAttachments(productId).then(data => {
      setInternalAttachments(data)
    })
    getProductTags(productId).then(data => {
      setTags(data)
    })
    getProductIPRights(productId).then(data => {
      setIPRights(data)
    })
  }, [productId])

  useEffect(() => {
    const handleScroll = event => {
      setDisplayCompactHeader(window.scrollY > productDetailsRef.current?.offsetTop)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const withPreviewFlag = (str) => {
    const url = new URL(str)
    url.searchParams.append('preview', 'true')
    return url.toString()
  }

  return (
    <div className='ProductPage'>
      <ProductHeader
        productId={productId} productData={productData?.orig} productAttachments={attachments}
        productPackages={productPackages} productHeadings={productHeadings} productStrapi={productStrapi}
        publicationState={publicationState} setPublicationState={setPublicationState}
        from={props?.location?.state?.from}
      />

      <div className={'level product-info-compact has-background-juha-blue-20 p-1' + (displayCompactHeader ? '' : ' is-hidden')}>
        <div className='container level pl-4 pr-4'>
          <div className='level-left'>
            <div className='level-item mx-3 has-text-weight-bold'>{productData.productName}</div>
            <div className='level-item mx-3'>{productData.productId}</div>
            <div className='level-item mx-3'>{productData.categoryId}</div>
            <div className='level-item mx-3'>{productData.cardReferenceCode}</div>
          </div>
          <div className='level-right'>
            <button className='button is-small is-juha-secondary-button' onClick={() => setCompactView(!compactView)}>
              <p>{compactView ? 'Täysi näkymä' : 'Kompakti näkymä'}</p>
            </button>
          </div>
        </div>
      </div>

      <div>
        <div className='container is-block product-main-details' ref={productDetailsRef}>
          <div className='level'>
            <div className='level-left'>
              <div className='level-item'>
                <p className='product-name title is-4 level-item is-justify-content-flex-start'>
                  {productData?.productName}
                </p>
              </div>
              {productData?.orig?.cardsPortalURL && (
                <div className='level-item'>
                  <a
                    href={(publicationState && !(productData?.publishType !== 'Rakenteinen' && productStrapi?.id)) ? productData?.orig?.cardsPortalURL : withPreviewFlag(productData?.orig?.cardsPortalURL)}
                    className='button is-small is-juha-secondary-button'
                    target='_blank' rel='noreferrer'
                  >{(publicationState && !(productData?.publishType !== 'Rakenteinen' && productStrapi?.id)) ? 'Avaa kortistossa' : 'Esikatsele kortistossa'}
                  </a>
                </div>
              )}
              <OpenCMSButton
                productStrapi={productStrapi}
                productData={productData}
                setProductStrapi={setProductStrapi}
                className='level-item'
              />
              {productData?.orig?.webstoreURL && (
                <div className='level-item'>
                  <a
                    href={productData?.orig?.webstoreURL}
                    className='button is-small is-juha-secondary-button'
                    target='_blank' rel='noreferrer'
                  >
                    Avaa verkkokaupassa
                  </a>
                </div>
              )}
            </div>
            <div className='level-right'>
              <button
                className='title is-7 button is-juha-secondary-button level-item'
                onClick={() => setCompactView(!compactView)}
              >
                <p>{compactView ? 'Täysi näkymä' : 'Kompakti näkymä'}</p>
              </button>
            </div>
          </div>

          <div className='container is-flex'>
            <div className='column is-one-third product-info is-paddingless'>
              <div className='columns is-mobile mb-0'>
                <div className='column is-4'>
                  <p className='label'>
                    Laji
                  </p>
                </div>
                <div className='column'>
                  <p>
                    {productData?.categoryId}
                  </p>
                </div>
              </div>
              <div className='columns is-mobile mb-0'>
                <div className='column is-4'>
                  <p className='label'>
                    Tuote-ID
                  </p>
                </div>
                <div className='column'>
                  <p>
                    {productData?.productId}
                  </p>
                </div>
              </div>
            </div>
            <div className='column is-one-third product-info is-paddingless'>
              <div className='columns is-mobile mb-0'>
                <div className='column is-4'>
                  <p className='label'>
                    Korttitunnus
                  </p>
                </div>
                <div className='column'>
                  <p>
                    {productData?.cardReferenceCode}
                  </p>
                </div>
              </div>
            </div>
            {productData.productMemo && (
              <div className='column is-one-third'>
                <div className='columns is-mobile mb-0'>
                  <p className='label mr-3'>Muistio</p>
                  <p>{productData.productMemo}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='container pb-6'>
        <DataBoxBoxes
          columns={3}
          title='Perustiedot'
          compactView={compactView}
          isWrapped={!compactView}
        >
          <DataBox title='Julkaisutiedot' data={productData?.basicData}>
            <FixedColumnItems title='Toimittajat' columnData={editors} />
            <FixedColumnWithGroupedItems title='Paketit' columnData={productPackages} />
            <FixedColumnWithGroupedItems title='Otsikot' columnData={productHeadings} />
            <FixedColumnItems title='Sisältötiimit' columnData={contentTeams} />
          </DataBox>
          <DataBox title='Hintatiedot' data={productData?.priceData}>
            <div className='column data-column is-marginless is-paddingless is-invisible' />
          </DataBox>
          <DataBox title='Yleiset' data={productData?.generalData} />
        </DataBoxBoxes>
        <DataBoxBoxes
          columns={3}
          title='Lisätiedot'
          compactView={compactView}
          isWrapped={!compactView}
        >
          {productData?.primaryAdditionalData ? <DataBox data={productData?.primaryAdditionalData} /> : <div />}
          {productData?.primaryAdditionalClassificationData
            ? <DataBox title='Luokittelu' data={productData?.primaryAdditionalClassificationData} />
            : <div />}
          {productData?.primaryAdditionalGeneralData
            ? <DataBox title='Yleiset' data={productData?.primaryAdditionalGeneralData} />
            : <div />}
          {productData?.primaryAdditionalNamingData
            ? <DataBox title='Nimet' data={productData?.primaryAdditionalNamingData} />
            : <div />}
        </DataBoxBoxes>
        <DataBoxBoxes
          columns={3}
          title='Tuotekuvaukset'
          compactView={compactView}
          isWrapped
        >
          <DataBox data={productData?.productDescriptions} contentComponent={(el) => (<span className='rich-text' dangerouslySetInnerHTML={{ __html: el.value }} />)} />
        </DataBoxBoxes>
        <DataBoxFixedColumns
          columns={3}
          title='Liitokset'
          notificationText='Nämä tiedot näkyvät verkkosivulla tuotteen tiedoissa'
          compactView={compactView}
          isWrapped={!compactView}
        >
          <FixedColumnWithGroupedItems title='Korvaavuudet' columnData={productReplacingData} />
          <FixedColumnWithGroupedItems title='Viittaustuotteet' columnData={productReferences} />
          <FixedColumnWithGroupedItems title='Kirjallisuusviitteet' columnData={productLiterature} isProductLiterature />
          <FixedColumnItems title='Asiasanat' columnData={indexTerms} />
          <FixedColumnWithGroupedItems title='Luokitukset' columnData={tags} useCustomColors />
          <FixedColumnWithGroupedItems title='IPR' columnData={ipRights} />
          <FixedColumnWithGroupedItems title='Nimikkeistöt' columnData={productClasses} />
          <FixedColumnItems title='Linkit' columnData={productLinks} />
          <FixedColumnItems title='Kielet' columnData={languages} />
          <FixedColumnWithGroupedItems title='Liitetiedostot' columnData={attachments} attachments />
          <FixedColumnWithGroupedItems title='Sisäiset Liitetiedostot' columnData={internalAttachments} />
        </DataBoxFixedColumns>
      </div>
    </div>
  )
}

const optionalUrl = (url) => {
  if (!url) return (<></>)
  return <a className='is-block' href={url}>{url}</a>
}

export default ProductPage
