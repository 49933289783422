import React from 'react'
import { Link } from 'react-router-dom'

function AdminHeader () {
  return (
    <div className='ProductHeader'>
      <nav className='custom-navbar navbar' role='navigation' aria-label='product navigation'>
        <div className='container'>
          <div className='navbar-brand'>
            <Link className='navbar-item' to='/admin'>
              <div className='title is-6 is-color-primary pl-3'>
                Ylläpito
              </div>
            </Link>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default AdminHeader
