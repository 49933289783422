import { getGroupedItem } from './productUtils'

const mapHeadingClass = (item, sortItemsByCode) => ({
  itemLabel: item.headingClassDescription,
  sortingCode: item.headingClassId,
  items: item.headings
    .map((heading) => ({
      headingId: heading.headingId,
      ...getGroupedItem(heading.menuDescription, heading.ordinal)
    }))
    .sort(sortItemsByCode)
})

export {
  mapHeadingClass
}
