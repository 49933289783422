import React, { useEffect, useState } from 'react'
import { getEditors, newEditor } from '../../services/editorService'
import EditorRow from './EditorRow'
import { useForm } from 'react-hook-form'
import { inputMaxLength, requiredText } from '../../utils/formUtils'
import { NotificationManager } from 'react-notifications'
import BasicDataColumn from '../BasicDataColumn'

function EditorsEdit () {
  const [editors, setEditors] = useState()
  const [displayedEditors, setDisplayedEditors] = useState()
  const [showNewEditorModal, setShowNewEditorModal] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [isFetchingEditors, setIsFetchingEditors] = useState(true)
  const { register, handleSubmit, formState: { errors }, reset } =
    useForm({ })

  useEffect(() => {
    fetchEditors()
  }, [])

  useEffect(() => {
    if (editors) {
      filterEditors()
    }
  }, [editors])

  const postNewEditor = data => {
    newEditor(data).then(resp => {
      if (resp.status === 201) {
        NotificationManager.success('Toimittaja lisätty')
        cancelAddNewEditor()
        fetchEditors()
      } else {
        NotificationManager.error('Virhe', 'Toimittajan lisäys epäonnistui', 5000)
      }
    }).catch(error => {
      if (error?.response?.data?.error?.indexOf('Database query failed') > -1) {
        NotificationManager.error('Virhe', 'Toimittajan lisäys epäonnistui. ID on jo käytössä', 5000)
      } else {
        NotificationManager.error('Virhe', 'Toimittajan lisäys epäonnistui', 5000)
      }
    })
  }

  const cancelAddNewEditor = () => {
    setShowNewEditorModal(false)
    reset()
  }

  const fetchEditors = () => {
    setIsFetchingEditors(true)
    getEditors().then(resp => {
      setEditors(resp)
    }).catch(error => {
      if (error) {
        NotificationManager.warning('Virhe', 'Toimittajien haku epäonnistui', 5000)
      }
    })
    setIsFetchingEditors(false)
  }

  const clearSearch = () => {
    setSearchInput('')
    setDisplayedEditors(editors)
  }

  const handleSearch = (e) => {
    filterEditors()
  }

  const filterEditors = () => {
    const filteredEditors = editors.filter(editor => editor?.editor?.toLowerCase().indexOf(searchInput.toLowerCase()) > -1)
    setDisplayedEditors(filteredEditors)
  }

  const onInputKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  return (
    <div className={(isFetchingEditors && 'is-loading') + ' EditorsEdit'}>
      <section className='section'>
        <div className='title is-4'>Toimittajat</div>
        <div className='columns'>
          <div className='column is-half'>
            <div className='SearchInputBox field has-addons is-relative'>
              {searchInput &&
                <button className='button clear-search' onClick={clearSearch}>
                  <span className='icon'><i className='fas fa-times' /></span>
                </button>}

              <div className='control input-control'>
                <input
                  value={searchInput}
                  onInput={e => setSearchInput(e.target.value)}
                  className='input'
                  type='text'
                  title='Hae toimittajan nimellä'
                  placeholder='Hae toimittajan nimellä'
                  onKeyUp={onInputKeyUp}
                />
              </div>
              <div className='control'>
                <button className='button is-juha-primary-button' onClick={handleSearch}>
                  <p>Hae</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {displayedEditors &&
        <section className='section pt-0'>
          <p className='title is-5 is-size-6-mobile has-text-weight-bold'>
            <span className='is-color-primary pr-2'>
              {displayedEditors.length}
            </span>
            <span>
              toimittajaa
            </span>
          </p>
          <div className='table-container'>
            <table className='table is-fullwidth is-striped'>
              <thead>
                <tr>
                  <th>Nimi</th>
                  <th>ID</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {displayedEditors.map((editor) => {
                  return <EditorRow editor={editor} key={editor.editorId} fetchEditors={fetchEditors} />
                })}
              </tbody>
            </table>
          </div>
          <button className='title is-6 button is-juha-secondary-button' onClick={() => setShowNewEditorModal(true)}>
            <span className='icon'>
              <i className='fas fa-plus' />
            </span>
            <p>
              Lisää uusi toimittaja
            </p>
          </button>
        </section>}
      <div className={showNewEditorModal ? 'modal is-active' : 'modal'}>
        <div className='modal-background' onClick={() => cancelAddNewEditor()} />
        <div className='modal-card'>
          <header className='modal-card-head'>
            <p className='modal-card-title'>Uusi toimittaja</p>
            <button className='delete' aria-label='close' onClick={() => cancelAddNewEditor()} />
          </header>
          <section className='modal-card-body'>
            <form onSubmit={handleSubmit(postNewEditor)}>
              <div className='columns'>
                <BasicDataColumn label='Nimi' error={errors.editor} width={6}>
                  <input
                    className='input'
                    {...register('editor',
                      {
                        required: requiredText,
                        maxLength: inputMaxLength(50)
                      })}
                  />
                </BasicDataColumn>
                <BasicDataColumn label='ID' error={errors.editorId} width={6}>
                  <input
                    className='input'
                    {...register('editorId',
                      {
                        required: requiredText,
                        maxLength: inputMaxLength(4)
                      })}
                  />
                </BasicDataColumn>
              </div>
            </form>
          </section>
          <footer className='modal-card-foot'>
            <button className='button is-juha-primary-button' onClick={handleSubmit(postNewEditor)}><p>Lisää uusi toimittaja</p></button>
            <button className='button is-juha-secondary-button' onClick={() => cancelAddNewEditor()}><p>Peruuta</p></button>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default EditorsEdit
