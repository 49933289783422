import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

const getPublicationChannels = async () => {
  return await axios.get(`${API_URL}/channel`)
    .then(resp => { return resp.data })
    .catch(err => { return err })
}

const publishProduct = async (productId, channelId) => {
  return await axios.post(`${API_URL}/product/${productId}/publish/${channelId}`)
    .then(resp => { return resp })
    .catch(err => { return { error: { code: err?.response?.status } } })
}

const getPublicationState = async (productId) => {
  return await axios.get(`${API_URL}/product/${productId}/channels`)
    .then(resp => { return resp.data })
    .catch(err => { return err })
}

export {
  getPublicationChannels,
  publishProduct,
  getPublicationState
}
