import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import FrontPage from './pages/FrontPage'
import Header from './components/Header'
import ProductPage from './pages/ProductPage'
import SearchPage from './pages/SearchPage'
import LandingPage from './pages/LandingPage'
import ProductEditPage from './pages/ProductEditPage'
import AdminPage from './pages/AdminPage'
import { NotificationContainer } from 'react-notifications'
import { getUserDetails } from './services/userService'
import ScrollToTop from './components/ScrollToTop'
import { UserContext } from './utils/userUtils'

export default function Routes () {
  const [userData, setUserData] = useState({})
  const [userDataIsFetched, setUserDataIsFetched] = useState(false)

  useEffect(() => {
    getUserDetails().then(response => {
      if (!response.error) {
        setUserData(response)
      }
      setUserDataIsFetched(true)
    })
  }, [])

  const isWriterOrAdmin = () => {
    return userData?.isAdmin || userData?.isWriter
  }

  return (
    <Router>
      <UserContext.Provider value={userData}>
        <ScrollToTop />
        <div>
          <Switch>
            <Route exact path='/' component={LandingPage} />
            <Route path='/error/:code?' component={LandingPage} />
            {userDataIsFetched &&
              <>
                <Header />
                <Switch>
                  <Route path='/dashboard' component={FrontPage} />
                  {isWriterOrAdmin() && <Route exact path='/product/new' render={(props) => <div key='new'><ProductEditPage {...props} /></div>} />}
                  {isWriterOrAdmin() && <Route path='/product/:productId/edit' component={ProductEditPage} />}
                  {isWriterOrAdmin() && <Route path='/product/:productId/clone' render={(props) => <div key='clone'><ProductEditPage {...props} isClone /></div>} />}
                  <Route exact path='/product/:productId' render={(props) => <ProductPage {...props} />} />
                  <Route path='/search' component={SearchPage} />
                  {userData?.isAdmin && <Route path='/admin/:path?' component={AdminPage} />}
                  <Redirect from='*' to='/dashboard' />
                </Switch>
              </>}
          </Switch>
          <NotificationContainer />
        </div>
      </UserContext.Provider>
    </Router>
  )
}
