import React from 'react'
import { Link } from 'react-router-dom'
import HeadingsEdit from '../components/admin/HeadingsEdit'
import EditorsEdit from '../components/admin/EditorsEdit'
import AdminHeader from '../components/admin/AdminHeader'

function AdminPage (props) {
  const { path } = props.match.params

  const MainView = () => {
    return (
      <section className='section'>
        <div className='columns'>
          <div className='column'>
            <div className='title is-4'>Otsikot</div>
            <Link className='button is-juha-primary-button' to='/admin/headings'>
              <p>Muokkaa otsikoita</p>
            </Link>
          </div>
          <div className='column'>
            <div className='title is-4'>Toimittajat</div>
            <Link className='button is-juha-primary-button' to='/admin/editors'>
              <p>Muokkaa toimittajia</p>
            </Link>
          </div>
        </div>
      </section>
    )
  }

  const CurrentView = () => {
    let component
    switch (path) {
      case 'headings':
        component = <HeadingsEdit />
        break
      case 'editors':
        component = <EditorsEdit />
        break
      default:
        component = <MainView />
    }
    return component
  }
  return (
    <div className='AdminPage'>
      <AdminHeader />
      <div className='container'>
        <CurrentView />
      </div>
    </div>
  )
}

export default AdminPage
