import React from 'react'

function BasicDataColumn (props) {
  const { label, error, children, width } = props
  return (
    <div className={`column data-column is-${width || 4}`}>
      <div className='field'>
        <label className='label'>{label}</label>
        <div className='control'>
          {children}
        </div>
        {error && <div className='is-color-accent-red'>{error.message}</div>}
      </div>
    </div>
  )
}

export default BasicDataColumn
