import React from 'react'
import { Link, useLocation } from 'react-router-dom'

function NewProductButton (props) {
  const { primary } = props
  const location = useLocation()

  return (
    <div className='NewProductButton'>
      <Link className={`title button is-6 ${primary ? 'is-juha-primary-button' : 'is-juha-secondary-button'} ${location.pathname === '/product/new' && 'is-disabled-button'}`} to='/product/new'>
        <span className='icon'>
          <i className='fas fa-plus' />
        </span>
        <p>
          Lisää uusi tuote
        </p>
      </Link>
    </div>
  )
}

export default NewProductButton
