import { inputMaxLength } from '../utils/formUtils'
import React from 'react'
import { Controller } from 'react-hook-form'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const modules = {
  toolbar: [
    [{ header: [3, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'bullet' }],
    // ['link'],
    ['clean']
  ]
}

export const RichTextEditor = (props) => {
  const { name, control } = props
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const handleChange = field.onChange
        return (
          <ReactQuill
            theme='snow'
            modules={modules}
            value={field.value}
            onChange={handleChange}
          />
        )
      }}
      rules={{
        maxLength: inputMaxLength(4000)
      }}
    />
  )
}
