import React, { useState, useEffect } from 'react'
import Select from 'react-select'

function IndexTermsEditForm (props) {
  const { indexTerms, indexTermOptions, handleIndexTermsChange } = props
  const [primaryIndexTermId, setPrimaryIndexTermId] = useState()
  const [primaryIndexTermLabel, setPrimaryIndexTermLabel] = useState()
  const [subIndexTermId, setSubIndexTermId] = useState()
  const [subIndexTermLabel, setSubIndexTermLabel] = useState()
  const [selectedIndexTerms, setSelectedIndexTerms] = useState([])
  const [ifError, setIfError] = useState(false)

  useEffect(() => {
    if (indexTerms) {
      setSelectedIndexTerms([...indexTerms])
    }
  }, [indexTerms])

  const CustomSelect = (props) => {
    const { options, value, setValue, setLabel } = props
    return (
      <Select
        placeholder='Valitse...'
        noOptionsMessage={() => 'Ei vaihtoehtoja'}
        options={options}
        onChange={e => {
          if (e) {
            setValue(e.value)
            setLabel(e.label)
            setIfError(false)
          } else {
            setValue(undefined)
          }
        }}
        value={options.find(c => c.value === value)}
        isClearable
      />
    )
  }

  const addNewIndexTermPair = (e) => {
    e.preventDefault()
    if (primaryIndexTermId) {
      let newIndexTerm = {
        indexTerm: primaryIndexTermLabel,
        indexTermId: primaryIndexTermId
      }
      if (subIndexTermId) {
        newIndexTerm = {
          subIndexTerm: subIndexTermLabel,
          subIndexTermId: subIndexTermId,
          ...newIndexTerm
        }
      } else {
        newIndexTerm = {
          subIndexTerm: 'Tyhjä',
          subIndexTermId: '0',
          ...newIndexTerm
        }
      }
      selectedIndexTerms.push(newIndexTerm)
      setSelectedIndexTerms([...selectedIndexTerms])
      setPrimaryIndexTermId('')
      setPrimaryIndexTermLabel('')
      setSubIndexTermId('')
      setSubIndexTermLabel('')
      setIfError(false)
      handleIndexTermsChange(selectedIndexTerms)
    } else {
      setIfError(true)
    }
  }

  const deleteIndexTermPair = (index) => {
    selectedIndexTerms.splice(index, 1)
    setSelectedIndexTerms([...selectedIndexTerms])
    handleIndexTermsChange(selectedIndexTerms)
  }

  return (
    <div className='IndexTermsEditForm column data-column is-4'>
      <label className='label'>Asiasanat</label>
      <div>
        <label className='label small-label'>Asiasana</label>
        <div className='control'>
          <CustomSelect options={indexTermOptions} value={primaryIndexTermId} setValue={setPrimaryIndexTermId} setLabel={setPrimaryIndexTermLabel} />
        </div>
        {ifError && <div className='is-color-accent-red'>Pääasiasana vaaditaan</div>}
        <label className='label small-label pt-2'>Alatason asiasana</label>
        <div className='control'>
          <CustomSelect options={indexTermOptions} value={subIndexTermId} setValue={setSubIndexTermId} setLabel={setSubIndexTermLabel} />
        </div>
        <button className='title is-6 button is-juha-secondary-button mt-3 mb-3' onClick={e => addNewIndexTermPair(e)}>
          <span className='icon'>
            <i className='fas fa-plus' />
          </span>
          <p>
            Lisää asiasana
          </p>
        </button>
      </div>
      {selectedIndexTerms.map((indexTermPair, index) => {
        return (
          <div key={`${indexTermPair.indexTermId} ${subIndexTermId} ${index}`} className='index-term-pair columns is-mobile mb-1 is-marginless'>
            <div className={`column ${indexTermPair.subIndexTerm ? 'is-5' : 'is-10'}`}>
              <p>{indexTermPair.indexTerm}</p>
            </div>
            {indexTermPair.subIndexTerm &&
              <div className='column is-5 sub-index-term'>
                <p>{indexTermPair.subIndexTerm}</p>
              </div>}
            <div className='column is-2 is-align-self-center'>
              <span className='icon' onClick={() => deleteIndexTermPair(index)}>
                <i className='fas fa-times' />
              </span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default IndexTermsEditForm
