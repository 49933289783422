import React, { useEffect, useState } from 'react'
import ProductSearchAsyncSelect from './ProductSearchAsyncSelect'
import Select from 'react-select'

function ReferencesEditForm (props) {
  const { handleReferencesChange, references, referenceTypeOptions } = props
  const [selectedReferenceType, setSelectedReferenceType] = useState()
  const [ifTypeError, setTypeError] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [ifProductError, setProductError] = useState(false)
  const [currentReferences, setCurrentReferences] = useState([])

  useEffect(() => {
    if (references) {
      setCurrentReferences([...references])
    }
  }, [references])

  const handleNewReference = (e) => {
    e.preventDefault()
    if (selectedReferenceType && selectedProducts.length > 0) {
      let referenceTypeExists = false
      currentReferences.map((referenceType, index) => {
        if (referenceType.referenceTypeId === selectedReferenceType.value) {
          // Combine arrays and remove duplicates
          currentReferences[index].productReferences = [
            ...currentReferences[index].productReferences,
            ...selectedProducts
          ].filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i)
          referenceTypeExists = true
        }
        return null
      })
      if (!referenceTypeExists) {
        currentReferences.push({
          referenceTypeId: selectedReferenceType.value,
          referenceTypePlural: selectedReferenceType.label,
          productReferences: selectedProducts
        })
      }
      setSelectedReferenceType(null)
      setSelectedProducts([])
      setCurrentReferences([...currentReferences])
      handleReferencesChange([...currentReferences])
    } else {
      if (!selectedReferenceType) {
        setTypeError(true)
      }
      if (selectedProducts.length === 0) {
        setProductError(true)
      }
    }
  }

  const handleProductChange = (updatedProducts) => {
    setSelectedProducts(updatedProducts)
    setProductError(false)
  }

  const handleDeleteReference = (typeIndex, referenceIndex) => {
    currentReferences[typeIndex].productReferences.splice(referenceIndex, 1)
    if (currentReferences[typeIndex].productReferences.length === 0) {
      currentReferences.splice(typeIndex, 1)
    }
    setCurrentReferences([...currentReferences])
    handleReferencesChange([...currentReferences])
  }

  return (
    <div className='ReferencesEditForm column data-column is-4'>
      <label className='label'>Viittaustuotteet</label>
      <div>
        <label className='label small-label'>Viittaustyyppi*</label>
        <Select
          placeholder='Valitse...'
          noOptionsMessage={() => 'Ei vaihtoehtoja'}
          options={referenceTypeOptions}
          onChange={e => {
            setTypeError(false)
            if (e) {
              setSelectedReferenceType(e)
              setTypeError(false)
            } else {
              setSelectedReferenceType(undefined)
            }
          }}
          value={selectedReferenceType}
          isClearable
        />
        {ifTypeError && <div className='is-color-accent-red'>Viittaustyyppi vaaditaan</div>}
        <label className='label small-label mt-2'>Viittaustuotteet*</label>
        <ProductSearchAsyncSelect selectedProducts={selectedProducts} selectedProductsChange={handleProductChange} />
        {ifProductError && <div className='is-color-accent-red'>Valitse vähintään yksi tuote</div>}
        <button className='title is-6 button is-juha-secondary-button mt-2 mb-2' onClick={handleNewReference}>
          <span className='icon'>
            <i className='fas fa-plus' />
          </span>
          <p>
            Lisää uusi viittaus
          </p>
        </button>
      </div>
      <div className='separator pb-2'>
        <div className='line has-background-juha-blue-5' />
      </div>
      <div>
        {currentReferences.map((referenceType, typeIndex) => {
          return (
            <div key={referenceType.referenceTypePlural + ' ' + typeIndex} className='mb-2'>
              <label className='label small-label'>{referenceType.referenceTypePlural}</label>
              {referenceType.productReferences.map((reference, referenceIndex) => {
                return (
                  <div key={reference.value + ' ' + referenceIndex} className='single-reference is-flex mb-1 is-justify-content-space-between'>
                    <p>{reference.label}</p>
                    <div className='is-flex delete-reference' onClick={() => handleDeleteReference(typeIndex, referenceIndex)}>
                      <span className='icon'>
                        <i className='fas fa-times fa-xs' />
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ReferencesEditForm
