import React from 'react'
import BasicDataColumn from './BasicDataColumn'
import { Controller } from 'react-hook-form'
import Select from 'react-select'

function SingleSelectColumn (props) {
  const { label, name, options, error, isClearable, control, width } = props

  return (
    <BasicDataColumn label={label} error={error} width={width}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Select
            placeholder='Valitse...'
            noOptionsMessage={() => 'Ei vaihtoehtoja'}
            ref={field.ref}
            options={options}
            onChange={e => {
              if (e) {
                field.onChange(e.value)
              } else {
                field.onChange(undefined)
              }
            }}
            value={options.find(c => c.value === field.value) || ''}
            isClearable={isClearable}
          />
        )}
      />
    </BasicDataColumn>
  )
}

export default SingleSelectColumn
