import { isValidDate } from './calendarUtils'

export const inputMaxLength = (length) => {
  return { value: length, message: `Arvo saa olla max ${length} merkkiä pitkä` }
}

export const requiredText = 'Tämä kenttä on pakollinen'

export const roundToTwoDecimals = (value) => {
  return Math.round((value + Number.EPSILON) * 100) / 100
}

export const datetimeObjectToString = (date) => {
  try {
    return date && date.toISOString()
  } catch (e) {
    return undefined
  }
}

export const dateObjectToString = (date) => {
  date && date.setHours(0, 0, 0, 0)
  const utcDate = date && new Date(date.getTime() - date.getTimezoneOffset() * 60000)
  utcDate && utcDate.setUTCHours(0, 0, 0, 0)

  try {
    return utcDate && utcDate.toISOString()
  } catch (e) {
    return undefined
  }
}

export const convertToDateObject = (date) => {
  const convertedDate = date && new Date(date)
  return isValidDate(convertedDate) ? convertedDate : undefined
}

export const convertToDisplayDate = (date) => {
  try {
    if (date instanceof Date) {
      return date && date?.getTime() > 0 ? new Date(date).toLocaleDateString('fi-FI') : ''
    } else {
      return date ? new Date(date).toLocaleDateString('fi-FI') : ''
    }
  } catch (e) {
    return ''
  }
}

export const convertToDisplayDateAndTime = (date) => {
  return new Date(date).toLocaleString('fi-FI')
}

export const parseFormData = (data) => {
  delete data.visibility

  return {
    ...data,
    createdTime: datetimeObjectToString(data.createdTime),
    updateTime: datetimeObjectToString(data.updateTime),
    publicationDate: dateObjectToString(data.publicationDate),
    cancelDate: dateObjectToString(data.cancelDate)
  }
}
