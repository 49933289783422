import Tree, {
  moveItemOnTree,
  mutateTree
} from '@atlaskit/tree'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { getHeadingTreeByClassId, updateHeading } from '../../services/headingService'
import { getHeadingClasses } from '../../services/headingClassService'
import { NotificationManager } from 'react-notifications'
import { v4 } from 'uuid'

// FIXME: better check
const isHeadingClass = (item) => {
  return !item.headingId && !!item.headingClassId && !!item.headingClassTypeId
}

const HeadingModal = (props) => {
  const { register, handleSubmit } = useForm()

  const heading = () => {
    const item = props.type === 'add' ? {} : props.item?.data
    if (isHeadingClass(item)) {
      return (
        <>
          <div className='control my-5'>
            <label className='label'>HEADING CLASS DESCRIPTION </label>
            <input className='input' {...register('headingClassDescription')} defaultValue={item.headingClassDescription} />
          </div>
          <div className='control my-5'>
            <label className='label'>SORTING TYPE</label>
            <input className='input' {...register('sortingType')} defaultValue={item.sortingType} />
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className='control my-5'>
            <label className='label'>HEADING DESCRIPTION </label>
            <input className='input' {...register('headingDescription')} defaultValue={item.headingDescription} />
          </div>
          <div className='control my-5'>
            <label className='label'>MENU DESCRIPTION</label>
            <input className='input' {...register('menuDescription')} defaultValue={item.menuDescription} />
          </div>
          <div className='control my-5'>
            <label className='label'>PATH NAME</label>
            <input className='input' {...register('pathname')} defaultValue={item.pathname} />
          </div>
          <div className='control my-5'>
            <label className='label'>DESCRIPTION</label>
            <textarea className='textarea' rows={5} {...register('description')} defaultValue={item.description} />
          </div>
        </>
      )
    }
  }

  return (
    <div className='modal is-flex'>
      <div className='modal-background' />
      <div className='modal-card'>
        <section className='modal-card-body'>
          <form onSubmit={handleSubmit(props.onSubmit)}>
            <button type='button' className='delete is-pulled-right is-juha-primary' onClick={props.handleClose} />
            <div className='modal-input field'>
              <h3 className='title is-5'><strong>{props.type === 'add' ? 'Lisää uusi otsikko' : 'Muokkaa otsikkotasoa'}</strong></h3>
              {heading()}
            </div>
            <div className='is-flex is-justify-content-space-between'>
              <button type='button' className='button is-juha-secondary-button' onClick={props.handleClose}>
                <span>Peruuta</span>
              </button>
              <button type='submit' className='button is-juha-primary-button'>
                <span>{props.type === 'add' ? 'Lisää' : 'Muokkaa'}</span>
              </button>
            </div>
          </form>
        </section>
      </div>
    </div>
  )
}

const treeObject = (item, id, rest) => {
  return {
    id,
    children: [],
    hasChildren: true,
    isExpanded: true,
    isChildrenLoading: false,
    data: {
      childHeadings: [],
      childIds: [],
      ...item
    },
    ...rest
  }
}

const createHeadingTree = (data) => {
  const objectTree = {
    rootId: 'root',
    items: {
      root: {
        id: 'root',
        children: [],
        hasChildren: false,
        isExpanded: true,
        isChildrenLoading: false,
        data: { title: 'root' }
      },
      fake: {
        id: 'fake',
        children: [],
        hasChildren: false,
        isExpanded: true,
        isChildrenLoading: false,
        data: { title: 'fake' }
      }
    }
  }

  /* create tree from HeadingClasses */
  for (const item of data) {
    /* add HeadingClasses to root head in first level */
    const id = v4()
    objectTree.items[id] = treeObject(item, id, { children: ['fake'], hasChildren: true, isExpanded: false })
    /* first level of headings to root */
    objectTree.items.root.children.push(id)
  }
  return objectTree
}

function HeadingsEdit () {
  const [tree, setTree] = useState({ rootId: 'root', items: {}, parentHeadCount: 0 })
  const [toggleModal, setToggleModal] = useState({ id: null, type: null, heading: null })
  const [dragEnabled, setDragEnabled] = useState(false)
  const [headingClasses, setHeadingClasses] = useState()
  const { register, handleSubmit } = useForm()

  useEffect(() => {
    getHeadingClasses().then(data => {
      setHeadingClasses(data)
      setTree({ ...createHeadingTree(data), parentHeadCount: data.length })
    })
  }, [])

  const onExpand = async (item) => {
    const mutatedTree = mutateTree(tree, item.id, { isChildrenLoading: true })
    setTree({ ...tree, ...mutatedTree })

    const currentItem = { ...mutatedTree.items[item.id] }

    try {
      const childRes = await getHeadingTreeByClassId(currentItem.data.headingClassId)
      const collectedObjects = {}
      const input = { id: v4(), ...childRes.data[0], childIds: [] }
      const processChild = (current) => {
        current.childIds = []
        for (const child of current.childHeadings) {
          child.id = v4()
          current.childIds.push(child.id)
          processChild(child)
        }
        collectedObjects[current.id] = { headingId: current.headingId, childIds: current.childIds, input: current }
      }

      for (const child of input.childHeadings) {
        child.id = v4()
        input.childIds.push(child.id)
        collectedObjects[input.id] = { headingId: input.headingId, childIds: input.childIds, input: input }
        processChild(child)
      }

      if (input.childHeadings === null || input.childHeadings.length === 0) {
        collectedObjects[input.id] = { headingId: input.headingId, childIds: [], input: input }
      }

      const newTreeItems = {}
      for (const [id, it] of Object.entries(collectedObjects)) {
        const item = treeObject(it.input, id)
        item.children.push(...it.childIds)
        newTreeItems[id] = item
      }

      if (isHeadingClass(currentItem.data)) {
        /* first parent is from headingClass so we add everything lastly at it. */
        newTreeItems[item.id] = treeObject(item.data, item.id)
        newTreeItems[item.id].children.push(input.id)
      }

      if (currentItem.isChildrenLoading) {
        const newTree = mutateTree(
          { ...mutatedTree, items: { ...mutatedTree.items, ...newTreeItems } },
          item.id,
          { isExpanded: true, isChildrenLoading: false }
        )
        setTree({ ...tree, ...newTree })
      }
    } catch (error) {
      setTree({ ...tree, ...mutateTree(tree, item.id, { isExpanded: false, isChildrenLoading: false, hasChildren: false, children: [] }) })
      NotificationManager.error('Virhe', 'Ei otsikoita.')
    }
  }

  const onCollapse = (itemId) => {
    const updatedTree = mutateTree(tree, itemId, { isExpanded: false, isChildrenLoading: false })
    setTree({ ...tree, ...updatedTree })
  }

  const onDragEnd = (source, destination) => {
    let dragSuccess = true
    if (!destination) {
      dragSuccess = false
    }

    /* Allow drag only in same parent. */
    const destinationChildren = tree.items[destination.parentId].children
    if (!destinationChildren.includes(tree.items[source.parentId].children[source.index])) {
      dragSuccess = false
      /* Some reason collapsable closes when dragging item in parent that only one child exits. Prevent that. */
    } else if (destinationChildren.length <= 1) {
      return
    }

    if (dragSuccess) {
      let payload
      if (source.parentId === 'root') {
        // const draggedItem = tree.items[tree.items[source.parentId]?.children[source.index]]?.data
        NotificationManager.warning('Huomio', 'Päätason otsikoiden raahaus toteuttamatta')
        return
      } else {
        let headings = tree.items[source.parentId]?.data?.childHeadings
        const draggedItemIndex = headings.findIndex(el => el.id === tree.items[source.parentId]?.children[source.index])
        headings.splice(destination.index, 0, headings.splice(draggedItemIndex, 1)[0])
        headings = headings.map((heading, index) => {
          return {
            headingId: heading.headingId,
            ordinal: index + 1
          }
        })
        payload = { headings }
      }
      if (payload) {
        updateHeading(payload).catch(() => {
          dragSuccess = false
        })
      }
    }
    if (dragSuccess) {
      const updatedTree = moveItemOnTree(tree, source, destination)
      setTree({ ...tree, ...updatedTree })
    } else {
      NotificationManager.error('Virhe', 'Raahaus epäonnistui')
    }
  }

  const renderItem = ({ item, depth, onExpand, onCollapse, provided }) => {
    const hasChildren = item.children && item.children.length > 0

    const expand = (event) => {
      event.preventDefault()
      if (hasChildren) {
        if (item.isExpanded) onCollapse(item.id)
        else onExpand(item, depth)
      }
    }

    const treeLine = () => {
      let toggle = ''
      if (hasChildren) {
        if (item.isExpanded) {
          toggle = <i className='fa-fw fas fa-chevron-up is is-clickable mx-1' onClick={expand} />
        } else {
          toggle = <i className='fa-fw fas fa-chevron-down is-clickable mx-1' onClick={expand} />
        }
      }

      return (
        <>
          <div className='fa'>
            <i {...provided.dragHandleProps} className='fa-fw fas fa-grip-vertical mx-1' />
            <span className='is-juha-separator mx-1 p-0' />
            {toggle}
          </div>
          {hasChildren
            ? <strong>{depth === 0 ? item.data?.headingClassDescription : item.data?.headingDescription}</strong>
            : <span>{depth === 0 ? item.data?.headingClassDescription : item.data?.headingDescription}</span>}
          <div className='is-pulled-right dropdown is-hoverable'>
            <div className='dropdown-trigger'>
              <i className='fas fa-ellipsis-h mx-1' />
            </div>
            <div className='dropdown-menu' role='menu'>
              <div className='dropdown-content'>
                <a className='dropdown-item' onClick={() => setToggleModal({ id: item.id, type: 'edit', heading: null })}> Muokkaa otsikkoa</a>
                {!isHeadingClass(item.data) && depth > 1 && <a className='dropdown-item' onClick={() => setToggleModal({ id: item.id, type: 'add', heading: 'parent' })}> Lisää saman tason otsikko</a>}
                {depth > 0 && <a className='dropdown-item' onClick={() => setToggleModal({ id: item.id, type: 'add', heading: 'child' })}> Lisää alatason otsikko</a>}
              </div>
            </div>
          </div>
        </>
      )
    }

    return (
      <div
        className='tree-item'
        ref={provided.innerRef}
        {...provided.draggableProps}
      >
        <div className={`subtitle is-6 m-0 p-3 ${depth === 0 && 'has-background-juha-paler-blue'}`}>
          {treeLine()}
        </div>
        {item.isChildrenLoading && <progress className='progress is-small' max='100'>5%</progress>}
        {provided.placeholder}
      </div>
    )
  }

  const handleModalSubmit = (itemData) => {
    const item = { ...tree.items[toggleModal.id] }

    const payload = (data) => {
      const getOrdinal = () => {
        let parentEl
        if (toggleModal.heading === 'child') {
          parentEl = item
        } else {
          parentEl = Object.entries(tree.items).find(el => el[1]?.data?.headingId === item?.data?.parentHeadingId)[1]
        }
        return (parentEl?.data?.childHeadings[parentEl?.children?.length && parentEl?.children?.length - 1]?.ordinal || parentEl?.children?.length) + 1
      }

      const getEditPayload = {
        headingClassId: item.data.headingClassId,
        ...!isHeadingClass(item.data) && { headingId: item.data.headingId }
      }

      const parentId = toggleModal.heading === 'parent' ? item.data.parentHeadingId : item.data.headingId
      const getAddPayload = {
        headingClassId: item.data.headingClassId,
        parentHeadingId: isHeadingClass(item.data)
          ? {} // HeadingClass adding is not visible in ui.
          : parentId,
        ordinal: toggleModal.type === 'add' && getOrdinal()
      }

      /*  No adding allowed for headingClasses. Only editing. */
      return isHeadingClass(item.data) && toggleModal.type === 'edit'
        ? { headingClasses: [{ ...data, ...getEditPayload }] }
        : { headings: [{ ...data, ...(toggleModal.type === 'add' ? getAddPayload : getEditPayload) }] }
    }

    if (toggleModal.type === 'add') {
      updateHeading(payload(itemData))
        .then(({ data }) => {
          const createItems = (headingItem) => {
            let parentAndChild = {}
            if (toggleModal.heading === 'parent') {
              const parentItem = Object.entries(tree.items).find(([, { children }]) => children?.includes(item.id))
              const id = v4()
              parentItem[1].children.push(id)
              parentItem[1].data.childHeadings.push({ ...headingItem, id, childHeadings: [], childIds: [] })
              const newItem = { [id]: treeObject(headingItem, id) }
              parentAndChild = { ...parentItem, ...newItem }
            } else {
              const id = v4()
              const parentItem = { [item.id]: { ...item, children: [...item.children, id] } }
              parentItem[item.id].data.childHeadings.push({ ...headingItem, id, childHeadings: [], childIds: [] })
              const newItem = { [id]: treeObject(headingItem, id) }
              parentAndChild = { ...parentItem, ...newItem }
            }
            return parentAndChild
          }

          let updatedAndNewItems = {}
          for (const headingClassItem of [...data?.headingClasses || [], ...data?.headings || []]) {
            updatedAndNewItems = { ...updatedAndNewItems, ...createItems(headingClassItem) }
          }

          setTree({ ...tree, items: { ...tree.items, ...updatedAndNewItems } })
          setToggleModal({ id: null, type: null, heading: null })
          NotificationManager.success('Otsikko lisätty')
        })
        .catch(() => {
          NotificationManager.error('Virhe', 'Uuden otsikon lisäys epäonnistui')
        })
    } else if (toggleModal.type === 'edit') {
      updateHeading(payload(itemData))
        .then(() => {
          const updatedItem = { [item.id]: { ...tree.items[item.id], data: { ...item.data, ...itemData } } }
          const updatedTree = { ...tree, items: { ...tree.items, ...updatedItem } }
          setTree(updatedTree)
          setToggleModal({ id: null, type: null, heading: null })
          NotificationManager.success('Otsikon tiedot päivitetty')
        })
        .catch(() => {
          NotificationManager.error('Virhe', 'Otsikon muokkauksessa')
        })
    }
  }

  const handleSearch = (data) => {
    if (data.search === '') {
      try {
        setTree({ ...createHeadingTree(headingClasses), parentHeadCount: headingClasses.length })
      } catch (err) {
        NotificationManager.error('Virhe', 'Pääotsikkoja ei löydy')
      }
    } else {
      try {
        const filteredHeadingClasses = headingClasses.filter(
          headingClass => headingClass.headingClassDescription?.toLowerCase().indexOf(data.search.toLowerCase()) > -1
        )
        setTree({ ...createHeadingTree(filteredHeadingClasses), parentHeadCount: filteredHeadingClasses.length })
      } catch (err) {
        NotificationManager.error('Virhe', 'Pääotsikkoja ei löydy')
      }
    }
  }

  const handleDragEnable = (e) => {
    setDragEnabled(e.target.checked)
  }

  return (
    <div className='HeadingsEdit'>
      <div className={`container ${!dragEnabled && 'drag-disabled'}`}>
        <section className='section'>
          <h1 className='title is-3'> Otsikot</h1>
          <form onSubmit={handleSubmit(handleSearch)}>
            <div className='head-input field has-addons'>
              <div className='control input-control'>
                <input className='input' {...register('search')} placeholder='Etsi päätason otsikkoja' />
              </div>
              <div className='control search'>
                <button type='submit' className='button is-juha-primary-button'>
                  <div className='icon search'>
                    <i className='fas fa-search' />
                  </div>
                </button>
              </div>
            </div>
          </form>
        </section>

        <section className='section'>
          <div className='columns mb-0'>
            <div className='column is-three-quarters'>
              <h1 className='title is-4'>
                <span className='is-color-primary'>{tree.parentHeadCount} </span>
                päätason otsikkoa
              </h1>
            </div>
            <div className='column is-one-quarter'>
              <label className='checkbox-container'>Muokkaa järjestystä
                <input type='checkbox' checked={dragEnabled} onChange={handleDragEnable} />
                <span className='checkmark' />
              </label>
            </div>
          </div>
          <div className='tree-box m-0 p-0 '>
            {tree?.items && (
              <Tree
                tree={tree}
                renderItem={renderItem}
                onExpand={onExpand}
                onCollapse={onCollapse}
                onDragEnd={onDragEnd}
                offsetPerLevel={17}
                isNestingEnabled={false}
                isDragEnabled
              />
            )}
          </div>
        </section>
        {toggleModal?.id && (
          <HeadingModal
            onSubmit={handleModalSubmit}
            handleClose={() => setToggleModal({ id: null, type: null, heading: null })}
            item={tree.items[toggleModal.id]}
            type={toggleModal.type}
          />
        )}
      </div>
    </div>
  )
}

export default HeadingsEdit
